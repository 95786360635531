/* eslint-disable */ 
import replyListStore from '@/store/reply/reply-list-store';
import replyFormStore from '@/store/reply/reply-form-store';
import replyDestroyStore from '@/store/reply/reply-destroy-store';

export default {
  namespaced: true,

  modules: {
    list: replyListStore,
    form: replyFormStore,
    destroy: replyDestroyStore
  },
};
