<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        <app-i18n code="settings.title"></app-i18n>
      </h2>
    </div>
    <!-- BEGIN: Settings Tabs -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="nav nav-tabs flex-col sm:flex-row flex-wrap justify-center"
        role="tablist"
      >
        <a
          id="general-tab"
          data-toggle="tab"
          data-target="#general"
          href="javascript:;"
          class="py-4 sm:mr-8"
          :class="tab == 'general' ? 'active' : ''"
          role="tab"
          aria-controls="general"
          aria-selected="true"
          @click="tab = 'general'"
          v-if="hasPermissionToReadGeneral"
        >
          {{ i18n('settings.tabs.general') }}
        </a>
        <div v-for="user in users" :key="user" class="flex items-center">
          <a
            id="privacyPolicy-tab"
            data-toggle="tab"
            data-target="#privacyPolicy"
            href="javascript:;"
            class="py-4 sm:mr-8"
            :class="tab == `${user}PrivacyPolicy` ? 'active' : ''"
            role="tab"
            aria-selected="false"
            @click="tab = `${user}PrivacyPolicy`"
            v-if="hasPermissionToReadPolicy"
          >
            {{ i18n(`settings.tabs.${user}PrivacyPolicy`) }}
          </a>
          <a
            id="termsAndConditions-tab"
            data-toggle="tab"
            data-target="#termsAndConditions"
            href="javascript:;"
            class="py-4 sm:mr-8"
            :class="tab == `${user}TermsAndConditions` ? 'active' : ''"
            role="tab"
            aria-selected="false"
            @click="tab = `${user}TermsAndConditions`"
            v-if="hasPermissionToReadPolicy"
          >
            {{ i18n(`settings.tabs.${user}TermsAndConditions`) }}
          </a>
        </div>
      </div>
    </div>
    <!-- END:  Settings Tabs -->
    <div class="intro-y tab-content mt-5">
      <settings-general-tab v-if="tab == 'general'" :savePermission="hasPermissionToCreateGeneral"/>
      <div v-for="user in users" :key="user">
        <div
          id="privacyPolicy"
          class="tab-pane active"
          role="tabpanel"
          aria-labelledby="privacyPolicy-tab"
          v-if="tab == `${user}PrivacyPolicy`"
        >
          <settings-policies-tab :id="`${user}PrivacyPolicy`" :savePermission="hasPermissionToCreatePolicy"/>
        </div>
        <div
          id="termsAndConditions"
          class="tab-pane active"
          role="tabpanel"
          aria-labelledby="termsAndConditions-tab"
          v-if="tab == `${user}TermsAndConditions`"
        >
          <settings-policies-tab :id="`${user}TermsAndConditions`" :savePermission="hasPermissionToCreatePolicy"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsTabGeneral from '@/views/settings/settings-tab-general.vue'
import SettingsTabPolicies from '@/views/settings/settings-policies.vue'
import { mapGetters } from 'vuex'
import { SettingsPermissions } from './settings-permissions'

export default {
  components: {
    [SettingsTabGeneral.name]: SettingsTabGeneral,
    [SettingsTabPolicies.name]: SettingsTabPolicies
  },
  data() {
    return {
      tab: 'general',
      users: ['client', 'chef', 'captain']
    }
  },

  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser'
    }),
    hasPermissionToReadGeneral() {
      return new SettingsPermissions(this.currentUser).readGeneral
    },
    hasPermissionToReadPolicy() {
      return new SettingsPermissions(this.currentUser).readPolicy
    },
    hasPermissionToCreateGeneral() {
      return new SettingsPermissions(this.currentUser).createGeneral
    },
    hasPermissionToCreatePolicy() {
      return new SettingsPermissions(this.currentUser).createPolicy
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.settings')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.settings')
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
