/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlReport)

export class ReportService {
  /**
   * - getSalesReport(startDate: DateTime!, endDate: DateTime!): SalesReport!
   * @param {DateTime} startDate
   * @param {DateTime} endDate
   * @returns {Promise<SalesReport>}
   */
  static async getSalesReport(startDate, endDate) {
    const response = await graphqlClient.query({
      query: gql`
        query GET_SALES_REPORT($startDate: DateTime!, $endDate: DateTime!) {
          getSalesReport(startDate: $startDate, endDate: $endDate) {
            total
            profit
            captainsPayment
            chefsPayment
            taxes
          }
        }
      `,

      variables: {
        startDate,
        endDate
      }
    })

    return response.data.getSalesReport
  }

  /**
   *
   * - getCitiesReport(startDate: DateTime!, endDate: DateTime!): [CityReport]!
   * @param {DateTime} startDate
   * @param {DateTime} endDate
   * @returns {Promise<CityReport[]>}
   */
  static async getCitiesReport(startDate, endDate) {
    const response = await graphqlClient.query({
      query: gql`
        query GET_CITIES_REPORT($startDate: DateTime!, $endDate: DateTime!) {
          getCitiesReport(startDate: $startDate, endDate: $endDate) {
            city {
              en
              ar
            }
            appProfit
            captainsProfit
            chefsProfit
            cityId
            ordersCount
          }
        }
      `,

      variables: {
        startDate,
        endDate
      }
    })

    return response.data.getCitiesReport
  }

  /**
   * Get a weekly top cities report
   * - getWeekTopCities: [TopCityStat]!
   * @returns {Promise<TopCityStat>}
   */
  static async getWeekTopCitiesReport() {
    const response = await graphqlClient.query({
      query: gql`
        query GET_WEEKLY_TOP_CITIES_REPORT {
          getWeekTopCities {
            cityId
            city {
              en
              ar
            }
            ratio
            orderCount
            sellersCount
            buyersCount
          }
        }
      `
    })

    return response.data.getWeekTopCities
  }

  /**
   * Get a monthly top cities report
   * - getMonthTopCities: [TopCityStat]!
   * @returns {Promise<TopCityStat>}
   */
  static async getMonthTopCitiesReport() {
    const response = await graphqlClient.query({
      query: gql`
        query GET_MONTHLY_TOP_CITIES_REPORT {
          getMonthTopCities {
            cityId
            city {
              en
              ar
            }
            ratio
            orderCount
            sellersCount
            buyersCount
          }
        }
      `
    })

    return response.data.getMonthTopCities
  }
}
