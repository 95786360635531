/* eslint-disable */
import { OrderService } from '@/store/order/order-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    locationLoading: false,
    listLoading: false,
    record: null,
    captainLocation: null,
    captains: []
  },

  getters: {
    record: state => state.record,
    captainLocation: state => state.captainLocation,
    findLoading: state => !!state.findLoading,
    locationLoading: state => !!state.locationLoading,
    saveLoading: state => !!state.saveLoading,
    listLoading: state => !!state.listLoading,
    captains: state => state.captains
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },
    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },
    FIND_LOCATION_STARTED(state) {
      state.captainLocation = null
      state.locationLoading = true
    },
    FIND_LOCATION_SUCCESS(state, payload) {
      state.captainLocation = payload
      state.locationLoading = false
    },
    UPDATE_STARTED(state) {
      state.saveLoading = true
    },
    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false
    },
    LIST_CAPTAINS_STARTED(state) {
      state.listLoading = true
    },
    LIST_CAPTAINS_SUCCESS(state, payload) {
      state.listLoading = false
      state.captains = payload
    },
    LIST_CAPTAINS_ERROR(state) {
      state.listLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit, dispatch }, id) {
      try {
        commit('FIND_STARTED')
        const record = await OrderService.find(id)
        commit('FIND_SUCCESS', record)
        await dispatch('doListenOnCaptainLocation', record.captain?.id)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/orders')
      }
    },

    async doCancel(
      { commit, dispatch, rootGetters },
      { id, notes, route = false }
    ) {
      try {
        commit('UPDATE_STARTED')
        await OrderService.cancel(id, notes)
        commit('UPDATE_SUCCESS')
        Message.success(i18n('order.cancel.success'))

        if (route) {
          router.push('/orders')
        } else {
          dispatch(
            `order/list/doFetch`,
            {
              filter: rootGetters[`order/list/filter`],
              pagination: rootGetters[`order/list/pagination`]
            },
            { root: true }
          )
        }
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
        if (route) router.push('/orders')
      }
    },

    async doClose({ commit, dispatch, rootGetters }, { id, route = false }) {
      try {
        commit('UPDATE_STARTED')
        await OrderService.close(id)
        commit('UPDATE_SUCCESS')
        Message.success(i18n('order.close.success'))

        if (route) {
          router.push('/orders')
        } else {
          dispatch(
            `order/list/doFetch`,
            {
              filter: rootGetters[`order/list/filter`],
              pagination: rootGetters[`order/list/pagination`]
            },
            { root: true }
          )
        }
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
        if (route) router.push('/orders')
      }
    },
    async doListenOnCaptainLocation({ commit }, captainId) {
      try {
        commit('FIND_LOCATION_STARTED')
        if (!captainId) {
          commit('FIND_LOCATION_SUCCESS', null)
        }
        const DB = getFirestore()
        onSnapshot(doc(DB, 'captains', captainId), doc => {
          const data = doc.data()
          let location = null
          if (data)
            location = {
              lat: data.currentLat,
              lng: data.currentLng
            }
          commit('FIND_LOCATION_SUCCESS', location)
        })
      } catch (error) {
        // console.error('Location error =>', error)
      }
    },
    async doAssignCaptain({ commit, dispatch }, { orderId, captainId }) {
      try {
        commit('UPDATE_STARTED')
        await OrderService.assign(orderId, captainId)
        commit('UPDATE_SUCCESS')
        Message.success(i18n('order.assign.success'))
        await dispatch('doFind', orderId)
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    },

    async doCancelAssignCaptain({ commit, dispatch }, { orderId, captainId }) {
      try {
        commit('UPDATE_STARTED')
        await OrderService.cancelAssign(orderId, captainId)
        commit('UPDATE_SUCCESS')
        Message.success(i18n('order.cancelAssign.success'))
        await dispatch('doFind', orderId)
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    },

    async doListCaptainsInArea({ commit }, { orderId }) {
      try {
        commit('LIST_CAPTAINS_STARTED')
        const captains = await OrderService.listCaptainsInArea(orderId)
        commit('LIST_CAPTAINS_SUCCESS', captains)
      } catch (error) {
        Errors.handle(error)
        commit('LIST_CAPTAINS_ERROR')
      }
    }
  }
}
