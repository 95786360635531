/* eslint-disable */
import UserService from '@/store/user/user-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'
import {
  collection,
  doc,
  set,
  add,
  getFirestore,
  onSnapshot
} from 'firebase/firestore'

export default {
  namespaced: true,

  state: {
    record: null,
    wallet: null,
    accounts: [],
    kitchen: null,
    sections: null,
    owner: null,
    loading: false,
    walletLoading: false,
    accountsLoading: false,
    kitchenLoading: false,
    statusLoading: false,
    addresses: [],
    addressLoading: false,
    kitchenSaveLoading: false,
    kitchenSaveLoading: false,
    refundLoading: false,
    rateLoading: false,
    rechargeLoading: false,
    withdrawLoading: false
  },

  getters: {
    record: state => state.record,
    wallet: state => state.wallet,
    walletLoading: state => !!state.walletLoading,
    accounts: state => state.accounts,
    accountsLoading: state => !!state.accountsLoading,
    kitchen: state => state.kitchen,
    sections: state => state.sections,
    kitchenLoading: state => !!state.kitchenLoading,
    kitchenSaveLoading: state => !!state.kitchenSaveLoading,
    sectionsSaveLoading: state => !!state.sectionsSaveLoading,
    loading: state => !!state.loading,
    owner: state => state.owner,
    statusLoading: state => !!state.statusLoading,
    addresses: state => state.addresses || [],
    addressLoading: state => !!state.addressLoading,
    refundLoading: state => !!state.refundLoading,
    rateLoading: state => !!state.rateLoading,
    rechargeLoading: state => !!state.rechargeLoading,
    withdrawLoading: state => !!state.withdrawLoading
  },

  mutations: {
    CHANGE_STATUS_STARTED(state) {
      state.statusLoading = true
    },
    CHANGE_STATUS_SUCCESS(state) {
      state.statusLoading = false
    },
    CHANGE_STATUS_ERROR(state) {
      state.statusLoading = false
    },
    FIND_STARTED(state) {
      state.record = null
      state.loading = true
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.loading = false
    },
    FIND_ERROR(state) {
      state.record = null
      state.loading = false
    },
    FIND_WALLET_STARTED(state) {
      state.wallet = null
      state.walletLoading = true
    },
    FIND_WALLET_SUCCESS(state, payload) {
      state.wallet = payload
      state.walletLoading = false
    },
    FIND_WALLET_ERROR(state) {
      state.wallet = null
      state.walletLoading = false
    },
    FIND_ACCOUNTS_STARTED(state) {
      state.accounts = []
      state.accountsLoading = true
    },
    FIND_ACCOUNTS_SUCCESS(state, payload) {
      state.accounts = payload
      state.accountsLoading = false
    },
    FIND_ACCOUNTS_ERROR(state) {
      state.accounts = []
      state.accountsLoading = false
    },
    FIND_KITCHEN_STARTED(state) {
      state.kitchen = null
      state.sections = null
      state.kitchenLoading = true
    },
    FIND_KITCHEN_SUCCESS(state, payload) {
      state.kitchen = payload.kitchen
      state.sections = payload.sections
      state.kitchenLoading = false
    },
    FIND_KITCHEN_ERROR(state) {
      state.kitchen = null
      state.sections = null
      state.kitchenLoading = false
    },
    SAVE_KITCHEN_STARTED(state) {
      state.kitchenSaveLoading = true
    },
    SAVE_KITCHEN_ERROR(state) {
      state.kitchenSaveLoading = false
    },
    SAVE_KITCHEN_SUCCESS(state) {
      state.kitchenSaveLoading = false
    },
    SAVE_SECTIONS_STARTED(state) {
      state.sectionsSaveLoading = true
    },
    SAVE_SECTIONS_ERROR(state) {
      state.sectionsSaveLoading = false
    },
    SAVE_SECTIONS_SUCCESS(state) {
      state.sectionsSaveLoading = false
    },
    FIND_ADDRESSES_STARTED(state) {
      state.addresses = []
      state.addressLoading = true
    },
    FIND_ADDRESSES_SUCCESS(state, payload) {
      state.addresses = payload
      state.addressLoading = false
    },
    FIND_ADDRESSES_ERROR(state) {
      state.addresses = []
      state.addressLoading = false
    },
    REFUND_STARTED(state) {
      state.refundLoading = true
    },
    REFUND_SUCCESS(state) {
      state.refundLoading = false
    },
    REFUND_ERROR(state) {
      state.refundLoading = false
    },
    RATE_STARTED(state) {
      state.rateLoading = true
    },
    RATE_SUCCESS(state) {
      state.rateLoading = false
    },
    RATE_ERROR(state) {
      state.rateLoading = false
    },
    RECHARGE_STARTED(state) {
      state.rechargeLoading = true
    },
    RECHARGE_SUCCESS(state) {
      state.rechargeLoading = false
    },
    RECHARGE_ERROR(state) {
      state.rechargeLoading = false
    },
    WITHDRAW_STARTED(state) {
      state.withdrawLoading = true
    },
    WITHDRAW_SUCCESS(state) {
      state.withdrawLoading = false
    },
    WITHDRAW_ERROR(state) {
      state.withdrawLoading = false
    },
    CLEAR(state) {
      state.record = null
      state.wallet = null
      state.accounts = []
    }
  },

  actions: {
    doReset({ commit }) {
      commit('CLEAR')
    },
    async doFind({ commit, rootGetters }, id) {
      try {
        commit('FIND_STARTED')
        const type = rootGetters[`user/list/type`]
        const record =
          type === 'client'
            ? await UserService.findClient(id)
            : type === 'chef'
            ? await UserService.findChef(id)
            : await UserService.findCaptain(id)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        type == 'client'
          ? router.push('/clients')
          : type == 'chef'
          ? router.push('/chefs')
          : router.push('/captains')
      }
    },
    async doRefund({ commit, dispatch, rootGetters }, { id, amount, notes }) {
      try {
        commit('REFUND_STARTED')
        const type = rootGetters[`user/list/type`]
        type === 'client'
          ? await UserService.rechargeClient(id, amount, notes)
          : type === 'chef'
          ? await UserService.rechargeChef(id, amount, notes)
          : type === 'captain'
          ? await UserService.rechargeCaptain(id, amount, notes)
          : ''
        await dispatch('doFindWallet', id)
        Message.success(i18n('wallets.refundSuccess'))
        commit('REFUND_SUCCESS')
      } catch (error) {
        commit('REFUND_ERROR')
        Errors.handle(error)
      }
    },
    async doRate({ commit }, { id, userType, dashboardRate }) {
      try {
        commit('RATE_STARTED')
        userType === 'client'
          ? await UserService.rateClient(id, dashboardRate)
          : userType === 'chef'
          ? await UserService.rateChef(id, dashboardRate)
          : userType === 'captain'
          ? await UserService.rateCaptain(id, dashboardRate)
          : ''
        Message.success(i18n('common.rateSuccess'))
        commit('RATE_SUCCESS')
      } catch (error) {
        commit('RATE_ERROR')
        Errors.handle(error)
      }
    },
    async doRecharge({ commit, dispatch }, amount) {
      try {
        commit('RECHARGE_STARTED')
        const response = await UserService.rechargeAppWallet(amount)
        window.open(response.paymentPageUrl, '_blank')
        const DB = getFirestore()
        onSnapshot(
          doc(DB, 'transactions', response.transactionId),
          async doc => {
            const data = doc.data()
            if (data && data.status === 'success') {
              await dispatch('doFindWallet', 'app')
              Message.success(i18n('wallets.refundSuccess'))
            }
          }
        )
        commit('RECHARGE_SUCCESS')
      } catch (error) {
        commit('RECHARGE_ERROR')
        Errors.handle(error)
      }
    },
    async doWithdraw({ commit }, { amount, transactionNumber }) {
      try {
        commit('WITHDRAW_STARTED')
        await UserService.withdrawAppWallet(amount, transactionNumber)
        Message.success(i18n('wallets.withdrawSuccess'))
        commit('WITHDRAW_SUCCESS')
      } catch (error) {
        commit('WITHDRAW_ERROR')
        Errors.handle(error)
      }
    },
    async doFindAddresses({ commit }, id) {
      try {
        commit('FIND_ADDRESSES_STARTED')
        const addresses = await UserService.listClientAddresses(id)
        commit('FIND_ADDRESSES_SUCCESS', addresses)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ADDRESSES_ERROR')
        router.push('/clients')
      }
    }, //captain
    async doFindWallet({ commit, rootGetters }, id) {
      try {
        commit('FIND_WALLET_STARTED')
        const type = rootGetters[`user/list/type`]
        const record =
          type === 'client'
            ? await UserService.findClientWallet(id)
            : type === 'chef'
            ? await UserService.findChefWallet(id)
            : type === 'captain'
            ? await UserService.findCaptainWallet(id)
            : type === 'app'
            ? await UserService.findAppWallet()
            : null
        commit('FIND_WALLET_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_WALLET_ERROR')
        type == 'client'
          ? router.push('/clients')
          : type == 'chef'
          ? router.push('/chefs')
          : type == 'chef'
          ? router.push('/captains')
          : router.push('/app-wallet')
      }
    },
    async doFindAccounts({ commit, rootGetters }, id) {
      try {
        commit('FIND_ACCOUNTS_STARTED')
        const type = rootGetters[`user/list/type`]
        const record =
          type === 'captain'
            ? await UserService.findCaptainAccounts(id)
            : type === 'chef'
            ? await UserService.findChefAccounts(id)
            : ''
        commit('FIND_ACCOUNTS_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ACCOUNTS_ERROR')
        type == 'captain'
          ? router.push('/captains')
          : type == 'chef'
          ? router.push('/chefs')
          : router.push('/')
      }
    },
    async doFindKitchen({ commit }, id) {
      try {
        commit('FIND_KITCHEN_STARTED')
        const record = await UserService.findKitchen(id)
        commit('FIND_KITCHEN_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_KITCHEN_ERROR')
        router.push('/chefs')
      }
    },
    async doUpdateKitchen({ commit }, { id, ...kitchenInput }) {
      try {
        commit('SAVE_KITCHEN_STARTED')
        await UserService.editKitchen(id, kitchenInput)
        Message.success(i18n('iam.chefDetails.kitchen.update.success'))
        commit('SAVE_KITCHEN_SUCCESS')
        router.back()
      } catch (error) {
        Errors.handle(error)
        commit('SAVE_KITCHEN_ERROR')
        router.back()
      }
    },
    async doUpdateSections({ commit }, { id, sectionsInput, newSectionInput }) {
      try {
        commit('SAVE_SECTIONS_STARTED')
        if (newSectionInput.length) {
          const db = getFirestore()
          const collectionRef = collection(db, 'chefs')
          newSectionInput.forEach(async section => {
            const newDocRef = doc(collectionRef)
            section.id = newDocRef.id
          })
        }
        const finalArray = [...sectionsInput, ...newSectionInput]

        await UserService.setSections(id, finalArray)
        Message.success(i18n('iam.chefDetails.sections.update.success'))
        commit('SAVE_SECTIONS_SUCCESS')
        router.back()
      } catch (error) {
        Errors.handle(error)
        commit('SAVE_SECTIONS_ERROR')
        router.back()
      }
    }
  }
}
