import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
import { Captain } from '../user/user-schema'

const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlOrder)

export class OrderService {
  static async cancel(orderId, notes) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ORDER_CANCEL($orderId: String!, $notes: String!) {
          cancelOrder(orderId: $orderId, notes: $notes)
        }
      `,
      variables: {
        orderId,
        notes
      }
    })
    if (response.data.cancelOrder && response.data.cancelOrder.status) {
      return response.data.cancelOrder.result
    } else {
      throw response.data.cancelOrder.error
    }
  }

  static async close(orderId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ORDER_CLOSE($orderId: String!) {
          closeOrdeAdmin(orderId: $orderId)
        }
      `,
      variables: {
        orderId
      }
    })
    if (response.data.closeOrdeAdmin && response.data.closeOrdeAdmin.status) {
      return response.data.closeOrdeAdmin.result
    } else {
      throw response.data.closeOrdeAdmin.error
    }
  }

  static async assign(orderId, captainId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ORDER_ASSIGN($orderId: String!, $captainId: String!) {
          assignOrderToCaptain(orderId: $orderId, captainId: $captainId)
        }
      `,
      variables: {
        orderId,
        captainId
      }
    })
    if (
      response.data.assignOrderToCaptain &&
      response.data.assignOrderToCaptain.status
    ) {
      return response.data.assignOrderToCaptain.result
    } else {
      throw response.data.assignOrderToCaptain.error
    }
  }

  static async cancelAssign(orderId, captainId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CANCEL($orderId: String!, $captainId: String!) {
          cancelAssignOrderToCaptain(orderId: $orderId, captainId: $captainId)
        }
      `,
      variables: {
        orderId,
        captainId
      }
    })
    if (
      response.data.cancelAssignOrderToCaptain &&
      response.data.cancelAssignOrderToCaptain.status
    ) {
      return response.data.cancelAssignOrderToCaptain.result
    } else {
      throw response.data.cancelAssignOrderToCaptain.error
    }
  }

  static async find(orderId) {
    const response = await graphqlClient.query({
      query: gql`
        query ORDER_FIND($orderId: String!) {
          findOrderById(orderId: $orderId) {
            id
            orderNo
            client {
              id
              fullName
              code
              phoneNumber
              email
            }
            chef {
              id
              fullName
              code
              phoneNumber
              email
              address {
                id
                name
                lat
                long
                street
                building
              }
            }
            captain {
              id
              code
              fullName
              phoneNumber
              email
              currentLat
              currentLng
            }
            address {
              id
              name
              lat
              long
              street
              building
            }
            notes
            cart {
              id
              chefId
              items {
                chefId
                mealId
                sizeId
                meal {
                  id
                  name {
                    en
                    ar
                  }
                  description {
                    en
                    ar
                  }
                  category {
                    id
                    name {
                      en
                      ar
                    }
                    image
                    order
                  }
                  section {
                    id
                    name {
                      en
                      ar
                    }
                  }
                  price
                  images
                  sizes {
                    id
                    name {
                      en
                      ar
                    }
                    price
                  }
                }
                quantity

                size {
                  id
                  name {
                    en
                    ar
                  }
                  price
                }

                addonsItems {
                  addon {
                    name {
                      ar
                      en
                    }
                    price
                  }
                  quantity
                }
              }
              # createdBy
              # createdAt
              # updatedBy
              # updatedAt
            }
            cartAmount
            taxes
            deliveryFees
            totalAmount
            chefProfit
            appOrderProfit
            appDeliveryProfit
            appTotalProfit
            captainProfit
            status
            paymentMethod
            deliveryTime
            pickUpTime
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,
      variables: {
        orderId
      }
    })
    return response.data.findOrderById
    // if (response.data.findOrderById && response.data.findOrderById.status) {
    //   return response.data.findOrderById.result
    // } else {
    //   throw response.data.findOrderById.error
    // }
  }

  static async listCaptainsInArea(orderId) {
    const response = await graphqlClient.query({
      query: gql`
        query USER_LIST_CAPTAINS(
          $orderId: String!
        ) {
          listCaptainsInOrderArea(orderId: $orderId) ${Captain}
        }
      `,
      variables: {
        orderId
      }
    })
    return response.data.listCaptainsInOrderArea
  }

  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query ORDER_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listOrders(filter: $filter, pagination: $pagination) {
            id
            orderNo
            client {
              id
              fullName
              code
              phoneNumber
            }
            chef {
              id
              code
              fullName
              phoneNumber
            }
            captain {
              id
              code
              fullName
              phoneNumber
            }
            address {
              id
              name
              lat
              long
            }
            notes
            taxes
            cartAmount
            deliveryFees
            totalAmount
            chefProfit
            appOrderProfit
            appDeliveryProfit
            appTotalProfit
            captainProfit
            status
            paymentMethod
            deliveryTime
            pickUpTime
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        filter,
        pagination
      }
    })

    return response.data.listOrders
  }
}
