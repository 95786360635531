/* eslint-disable */
import { ReportService } from '@/store/report/report-service';
import Errors from '@/shared/error/errors';

export default {
  namespaced: true,
  state: {
    rows: [],
    loading: false,
  },
  getters: {
    rows: (state) => state.rows || [],
    loading: (state) => !!state.loading,
  },
  mutations: {
    RESET(state) {
      state.rows = null;
      state.loading = false;
    },
    FIND_STARTED(state) {
      state.rows = null;
      state.loading = true;
    },
    FIND_SUCCESS(state, payload) {
      state.rows = payload;
      state.loading = false;
    },
    FIND_ERROR(state) {
      state.rows = null;
      state.loading = false;
    },
  },
  actions: {
    doNew({ commit }) {
      commit('RESET');
    },
    async doFetch({ commit }) {
      try {
        commit('FIND_STARTED');
        const rows = await ReportService.getMonthTopCitiesReport();
        commit('FIND_SUCCESS', rows);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },
  },
};
