<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu />
    <TopBar />
    <div class="wrapper">
      <div class="wrapper-box">
        <!-- BEGIN: Side Menu -->
        <nav class="side-nav">
          <ul>
            <!-- BEGIN: First Child -->
            <template v-for="(menu, menuKey) in formattedMenu">
              <li
                v-if="menu == 'devider'"
                :key="menu + menuKey"
                class="side-nav__devider my-6"
              ></li>
              <li v-else :key="menu + menuKey">
                <SideMenuTooltip
                  tag="a"
                  :content="menu.title"
                  :href="
                    menu.subMenu
                      ? 'javascript:;'
                      : router.resolve({ name: menu.pageName }).path
                  "
                  class="side-menu"
                  :class="{
                    'side-menu--active': menu.active,
                    'side-menu--open': menu.activeDropdown
                  }"
                  v-if="hasPermissionToRead(menu.permission)"
                  @click="linkTo(menu, router, $event)"
                >
                  <div class="side-menu__icon flex items-center justify-center">
                    <!-- <img
                      v-if="menu.icon == 'ChefIcon'"
                      src="@/assets/images/chef.png"
                      style="
                        width: 2.3rem;
                        filter: invert(90%) sepia(0%) saturate(0%)
                          hue-rotate(9deg) brightness(117%) contrast(100%);
                      "
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      class="w-10"
                      v-if="menu.icon == 'MealsIcon'"
                    >
                      <path
                        fill="currentColor"
                        d="M18.25 3.25a.75.75 0 0 1 .743.648L19 4v16a.75.75 0 0 1-1.493.102L17.5 20v-5h-2.25a.75.75 0 0 1-.743-.648l-.007-.102V7a3.75 3.75 0 0 1 3.75-3.75Zm-6 0a.75.75 0 0 1 .743.648L13 4v4c0 1.953-1.4 3.578-3.25 3.93V20a.75.75 0 0 1-1.493.102L8.25 20v-8.07a4.002 4.002 0 0 1-3.245-3.722L5 8V4a.75.75 0 0 1 1.493-.102L6.5 4v4c0 1.12.736 2.067 1.75 2.386V4a.75.75 0 0 1 1.493-.102L9.75 4v6.385a2.502 2.502 0 0 0 1.743-2.2L11.5 8V4a.75.75 0 0 1 .75-.75ZM17.5 13.5V4.878a2.252 2.252 0 0 0-1.494 1.95L16 7v6.5h1.5V4.878V13.5Z"
                      />
                    </svg>
                    <img
                      v-if="menu.icon == 'ClientsIcon'"
                      src="@/assets/images/chef.png"
                      style="
                        width: 2.3rem;
                        filter: invert(90%) sepia(0%) saturate(0%)
                          hue-rotate(9deg) brightness(117%) contrast(100%);
                      "
                    />
                    <img
                      v-if="menu.icon == 'CaptainsIcon'"
                      src="@/assets/images/captainIcon.png"
                      style="
                        width: 2.3rem;
                        filter: invert(90%) sepia(0%) saturate(0%)
                          hue-rotate(9deg) brightness(117%) contrast(100%);
                      "
                    />
                    <span
                      v-if="menu.icon == 'MotorcycleIcon'"
                      class="material-icons-outlined"
                      :style="darkMode ? 'color: #828d9b' : 'color: #b7b7b7'"
                    >
                      motorcycle
                    </span> -->
                    <Icon :icon="menu.icon" class="w-6 h-6" />
                    <!-- <component :is="menu.icon" /> -->
                  </div>
                  <div class="side-menu__title mr-2">
                    {{ i18n(`menu.${menu.title}`) }}
                    <span
                      class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                      v-if="
                        menu.title == 'Payments' &&
                          chefsRequestsBadge + captainsRequestsBadge > 0
                      "
                      >{{ chefsRequestsBadge + captainsRequestsBadge }}</span
                    >
                    <span
                      class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                      v-if="menu.title == 'Orders' && inProgressOrders > 0"
                      >{{ inProgressOrders }}</span
                    >
                    <!-- <span
                      class="
                        bg-theme-24
                        text-white
                        font-bold
                        rounded-full
                        mx-2
                        px-1.5
                      "
                      v-if="menu.title == 'captains' && captainsBadge > 0"
                      >{{ captainsBadge }}</span
                    > -->
                    <span
                      class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                      v-if="
                        menu.title == 'Complaints' &&
                          ClientComplaintsCount +
                            ChefComplaintsCount +
                            CaptainComplaintsCount >
                            0
                      "
                      >{{
                        ClientComplaintsCount +
                          ChefComplaintsCount +
                          CaptainComplaintsCount
                      }}</span
                    >
                    <div
                      v-if="menu.subMenu"
                      class="side-menu__sub-icon"
                      :class="{ 'transform rotate-180': menu.activeDropdown }"
                    >
                      <ChevronDownIcon />
                    </div>
                  </div>
                </SideMenuTooltip>
                <!-- BEGIN: Second Child -->
                <transition @enter="enter" @leave="leave">
                  <ul v-if="menu.subMenu && menu.activeDropdown">
                    <li
                      v-for="(subMenu, subMenuKey) in menu.subMenu"
                      :key="subMenuKey"
                    >
                      <SideMenuTooltip
                        tag="a"
                        :content="subMenu.title"
                        :href="
                          subMenu.subMenu
                            ? 'javascript:;'
                            : router.resolve({ name: subMenu.pageName }).path
                        "
                        class="side-menu"
                        :class="{ 'side-menu--active': subMenu.active }"
                        @click="linkTo(subMenu, router, $event)"
                      >
                        <div class="side-menu__icon flex items-center">
                          <!-- <ActivityIcon /> -->
                          <img
                            v-if="subMenu.icon == 'ClientIcon'"
                            src="@/assets/images/user1.png"
                            style="
                              width: 1.8rem;
                              filter: invert(90%) sepia(0%) saturate(0%)
                                hue-rotate(9deg) brightness(117%) contrast(100%);
                            "
                          />
                          <img
                            v-if="subMenu.icon == 'CaptainIcon'"
                            src="@/assets/images/wheel1.png"
                            style="
                              width: 1.8rem;
                              filter: invert(90%) sepia(0%) saturate(0%)
                                hue-rotate(9deg) brightness(117%) contrast(100%);
                            "
                          />
                          <span
                            v-if="subMenu.icon == 'ChefIcon'"
                            class="material-icons-outlined ml-1"
                            :style="
                              darkMode ? 'color: #828d9b' : 'color: #b7b7b7'
                            "
                          >
                            restaurant
                          </span>
                          <component :is="subMenu.icon" class="ml-2" />
                        </div>
                        <div class="side-menu__title">
                          {{ i18n(`menu.${subMenu.title}`) }}
                          <span
                            class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                            v-if="
                              subMenu.title == 'ClientComplaints' &&
                                ClientComplaintsCount > 0
                            "
                            >{{ ClientComplaintsCount }}</span
                          >
                          <span
                            class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                            v-if="
                              subMenu.title == 'ChefComplaints' &&
                                ChefComplaintsCount > 0
                            "
                            >{{ ChefComplaintsCount }}</span
                          >
                          <span
                            class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                            v-if="
                              subMenu.title == 'CaptainComplaints' &&
                                CaptainComplaintsCount > 0
                            "
                            >{{ CaptainComplaintsCount }}</span
                          >
                          <div
                            v-if="subMenu.subMenu"
                            class="side-menu__sub-icon"
                            :class="{
                              'transform rotate-180': subMenu.activeDropdown
                            }"
                          >
                            <ChevronDownIcon />
                          </div>
                        </div>
                      </SideMenuTooltip>
                      <!-- BEGIN: Third Child -->
                      <transition @enter="enter" @leave="leave">
                        <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                          <li
                            v-for="(lastSubMenu,
                            lastSubMenuKey) in subMenu.subMenu"
                            :key="lastSubMenuKey"
                          >
                            <SideMenuTooltip
                              tag="a"
                              :content="lastSubMenu.title"
                              :href="
                                lastSubMenu.subMenu
                                  ? 'javascript:;'
                                  : router.resolve({
                                      name: lastSubMenu.pageName
                                    }).path
                              "
                              class="side-menu"
                              :class="{
                                'side-menu--active': lastSubMenu.active
                              }"
                              @click="linkTo(lastSubMenu, router, $event)"
                            >
                              <div class="side-menu__icon">
                                <ZapIcon />
                              </div>
                              <div class="side-menu__title">
                                {{ lastSubMenu.title }}
                              </div>
                            </SideMenuTooltip>
                          </li>
                        </ul>
                      </transition>
                      <!-- END: Third Child -->
                    </li>
                  </ul>
                </transition>
                <!-- END: Second Child -->
              </li>
            </template>
            <!-- END: First Child -->
          </ul>
          <!-- <div class="runProf flex justify-center mt-auto">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/home-food-plus.appspot.com/o/admin%2Fpublic%2Frunprof.svg?alt=media&token=92e71269-efea-4a44-b024-80f6b5c5730a"
              alt=""
            />
          </div> -->
        </nav>
        <!-- END: Side Menu -->
        <!-- BEGIN: Content -->
        <div class="content">
          <router-view />
        </div>
        <!-- END: Content -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { mapActions, mapGetters } from 'vuex'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'
import PermissionChecker from '@/security/permission-checker'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    // DarkModeSwitcher,
    SideMenuTooltip
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .removeClass('login-rtl')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      formattedMenu,
      darkMode,
      router,
      linkTo,
      enter,
      leave
    }
  },
  async created() {
    await this.doFetch()
    this.doRunListenerOnNewChefRequests()
    this.doRunListenerOnNewCaptainRequests()
    this.doRunListenerOnNewComplaints()
    this.doRunListenerOnInProgressOrders()
  },
  computed: {
    ...mapGetters({
      captainsRequestsBadge: 'realtime/captainsRequestsBadge',
      chefsRequestsBadge: 'realtime/chefsRequestsBadge',
      inProgressOrders: 'realtime/inProgressOrders',
      ClientComplaintsCount: 'complaintStore/ClientComplaintsCount',
      ChefComplaintsCount: 'complaintStore/ChefComplaintsCount',
      CaptainComplaintsCount: 'complaintStore/CaptainComplaintsCount',
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    ...mapActions({
      doRunListenerOnNewChefRequests: 'realtime/doRunListenerOnNewChefRequests',
      doRunListenerOnNewCaptainRequests:
        'realtime/doRunListenerOnNewCaptainRequests',
      doRunListenerOnNewComplaints:
        'complaintStore/doRunListenerOnNewComplaints',
      doRunListenerOnInProgressOrders:
        'realtime/doRunListenerOnInProgressOrders',
      doFetch: 'dashboard/doFetch'
    }),
    hasPermissionToRead(permission) {
      return new PermissionChecker(this.currentUser).match(permission)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style lang="scss">
.runProf {
  position: absolute;
  // margin-bottom: auto !important;
  bottom: 5%;
  left: 4%;
  @media only screen and (max-width: 1024px) {
    width: 80px;
    left: 1%;
    // display: none;
  }
}

[dir='rtl'] .runProf {
  position: absolute;
  bottom: 5%;
  left: 91% !important;
  @media only screen and (max-width: 1024px) {
    width: 80px;
    left: 90% !important;
    // display: none;
  }
  @media only screen and (max-width: 768px) {
    width: 80px;
    left: 87% !important;
    // display: none;
  }
}
</style>
