/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClients = GraphqlClient.initApolloClient(config.backendUrlClient)
const graphqlCaptains = GraphqlClient.initApolloClient(config.backendUrlCaptain)
const graphqlChefs = GraphqlClient.initApolloClient(config.backendUrlChef)
const graphqlAdmin = GraphqlClient.initApolloClient(config.backendUrlAdmin)
const graphqlTransactions = GraphqlClient.initApolloClient(
  config.backendUrlTransactions
)

const Transaction = `
{
  id
  type
  amount
  objectType
  objectId
  code
  payerType
  notes {
    en
    ar
  }
  payerId
  objectNo
  trackId
  payeeType
  payeeId
  paymentMethod
  paymentId
  status
  createdBy
  createdAt
  updatedBy
  updatedAt
}
`

export class TransactionService {
  static async listWalletTransactions(walletId, filter, pagination) {
    const response = await graphqlTransactions.query({
      query: gql`
        query TRANSACTION_LIST(
          $walletId: String!
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listWalletTransactions(
            walletId: $walletId
            filter: $filter
            pagination: $pagination
          ) ${Transaction}
        }
      `,
      variables: {
        walletId,
        pagination,
        filter
      }
    })
    return response.data.listWalletTransactions
  }
  static async listInClientTransactions(clientId, pagination, filter) {
    const response = await graphqlClients.query({
      query: gql`
        query TRANSACTION_LIST(
          $clientId: String!
          $pagination: PaginationInput!
          $filter: [Filter]!
        ) {
          listInWalletTransactionsClient(
            clientId: $clientId
            pagination: $pagination
            filter: $filter
          ) ${Transaction}
        }
      `,
      variables: {
        clientId,
        pagination,
        filter
      }
    })
    return response.data.listInWalletTransactionsClient
  }

  static async listOutClientTransactions(clientId, pagination, filter) {
    const response = await graphqlClients.query({
      query: gql`
        query TRANSACTION_LIST(
          $clientId: String!
          $pagination: PaginationInput!
          $filter: [Filter]!
        ) {
          listOutWalletTransactionsClient(
            clientId: $clientId
            pagination: $pagination
            filter: $filter
          ) ${Transaction}
        }
      `,
      variables: {
        clientId,
        pagination,
        filter
      }
    })
    return response.data.listOutWalletTransactionsClient
  }
  static async listInChefTransactions(chefId, pagination, filter) {
    const response = await graphqlChefs.query({
      query: gql`
        query TRANSACTION_LIST(
          $chefId: String!
          $pagination: PaginationInput!
          $filter: [Filter]!
        ) {
          listInWalletTransactionsChef(
            chefId: $chefId
            pagination: $pagination
            filter: $filter
          ) ${Transaction}
        }
      `,
      variables: {
        chefId,
        pagination,
        filter
      }
    })
    return response.data.listInWalletTransactionsChef
  }

  static async listOutChefTransactions(chefId, pagination, filter) {
    const response = await graphqlChefs.query({
      query: gql`
        query TRANSACTION_LIST(
          $chefId: String!
          $pagination: PaginationInput!
          $filter: [Filter]!
        ) {
          listOutWalletTransactionsChef(
            chefId: $chefId
            pagination: $pagination
            filter: $filter
          ) ${Transaction}
        }
      `,
      variables: {
        chefId,
        pagination,
        filter
      }
    })
    return response.data.listOutWalletTransactionsChef
  }
  static async listInCaptainTransactions(captainId, pagination, filter) {
    const response = await graphqlCaptains.query({
      query: gql`
        query TRANSACTION_LIST(
          $captainId: String!
          $pagination: PaginationInput!
          $filter: [Filter]!
        ) {
          listInWalletTransactionsCaptain(
            captainId: $captainId
            pagination: $pagination
            filter: $filter
          ) ${Transaction}
        }
      `,
      variables: {
        captainId,
        pagination,
        filter
      }
    })
    return response.data.listInWalletTransactionsCaptain
  }

  static async listOutCaptainTransactions(captainId, pagination, filter) {
    const response = await graphqlCaptains.query({
      query: gql`
        query TRANSACTION_LIST(
          $captainId: String!
          $pagination: PaginationInput!
          $filter: [Filter]!
        ) {
          listOutWalletTransactionsCaptain(
            captainId: $captainId
            pagination: $pagination
            filter: $filter
          ) ${Transaction}
        }
      `,
      variables: {
        captainId,
        pagination,
        filter
      }
    })
    return response.data.listOutWalletTransactionsCaptain
  }
  static async listInAppTransactions(pagination, filter) {
    const response = await graphqlAdmin.query({
      query: gql`
        query TRANSACTION_LIST(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listInWalletTransactionsApp(
            filter: $filter
            pagination: $pagination
          ) ${Transaction}
        }
      `,
      variables: {
        pagination,
        filter
      }
    })
    return response.data.listInWalletTransactionsApp
  }

  static async listOutAppTransactions(pagination, filter) {
    const response = await graphqlAdmin.query({
      query: gql`
        query TRANSACTION_LIST(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listOutWalletTransactionsApp(
            filter: $filter
            pagination: $pagination
          ) ${Transaction}
        }
      `,
      variables: {
        pagination,
        filter
      }
    })
    return response.data.listOutWalletTransactionsApp
  }
}
