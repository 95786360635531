/* eslint-disable */ import UserService from '@/store/user/user-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'
// import PermissionChecker from '@/security/permission-checker';
// import Permissions from '@/security/permissions';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },

    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    ADD_STARTED(state) {
      state.saveLoading = true
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false
    },

    ADD_ERROR(state) {
      state.saveLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doAccept({ commit, dispatch, rootGetters }, id) {
      try {
        commit('UPDATE_STARTED')
        const type = rootGetters[`user/list/type`]
        if (type === 'chef') {
          await UserService.decideChefAdd([id], true)
          Message.success(i18n('chefs.accept.add-success'))
        }
        if (type === 'captain') {
          await UserService.decideCaptainAdd([id], true)
          Message.success(i18n('captains.accept.add-success'))
        }
        commit('UPDATE_SUCCESS')

        dispatch(
          `user/list/doFetch`,
          {
            filter: rootGetters[`user/list/filter`],
            pagination: rootGetters[`user/list/pagination`],
            type
          },
          { root: true }
        )
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    },

    async doReject({ commit, dispatch, rootGetters }, id) {
      try {
        commit('UPDATE_STARTED')
        const type = rootGetters[`user/list/type`]
        if (type === 'chef') {
          await UserService.decideChefAdd([id], false)
          Message.success(i18n('chefs.reject.add-success'))
        }
        if (type === 'captain') {
          await UserService.decideCaptainAdd([id], false)
          Message.success(i18n('captains.reject.add-success'))
        }
        commit('UPDATE_SUCCESS')

        dispatch(
          `user/list/doFetch`,
          {
            filter: rootGetters[`user/list/filter`],
            pagination: rootGetters[`user/list/pagination`],
            type
          },
          { root: true }
        )
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }
  }
}
