/* eslint-disable */
import { TransactionService } from '@/store/transaction/transaction-service'
import { state, getters, mutations, actions } from '@/store/list-utils'

export default {
  namespaced: true,
  state: {
    ...state,
    walletId: null
  },
  getters: {
    ...getters,
    walletId: state => state.walletId
  },
  mutations: {
    ...mutations,
    UPDATE_WALLET_ID(state, walletId) {
      state.walletId = walletId || state.walletId
    }
  },
  actions: {
    async doChangePaginationPageSize({ commit, getters }, pageSize) {
      await actions.changePageSize({ commit, getters, pageSize })
    },
    doChangePaginationCurrentPage({ commit }, currentPage) {
      actions.changeCurrentPage({ commit, page: currentPage })
    },
    async doFetchNextPage({ commit, getters }) {
      await actions.fetchNext({ commit, getters })
    },
    async doFetchPreviousPage({ commit, getters }) {
      await actions.fetchPrevious({ commit, getters })
    },
    async doFetch(
      { commit, getters },
      { walletId, filter, pagination, keepPagination = true } = {}
    ) {
      commit('UPDATE_WALLET_ID', walletId)
      await actions.fetch({
        commit,
        getters,
        list: TransactionService.listWalletTransactions.bind(
          null,
          getters.walletId
        ),
        filter,
        pagination,
        keepPagination
      })
    }
  }
}
