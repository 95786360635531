/* eslint-disable */
import HelperClass from '@/shared/helperClass'
import { getDatabase, ref, onValue, get } from 'firebase/database'

export default {
  namespaced: true,
  state: {
    loading: false,
    currentDate: null,
    statistics: {
      chefsCount: null,
      captainsCount: null,
      clientsCount: null,
      mealsCount: null,
      ordersAmount: null,
      ordersCount: null,
      chef: null,
      client: null,
      captain: null
    }
  },
  getters: {
    loading: state => state.loading,
    currentDate: state => state.currentDate,
    statistics: state => state.statistics
  },
  mutations: {
    FETCH_STARTED(state) {
      state.loading = true
    },
    FETCH_SUCCESS(state) {
      state.loading = false
    },
    FETCH_ERROR(state) {
      state.loading = false
    },
    SET_STATISTICS(state, payload) {
      state.statistics = {
        chefsCount: payload.chefsCount?.value || 0,
        captainsCount: payload.captainsCount?.value || 0,
        clientsCount: payload.clientsCount?.value || 0,
        mealsCount: payload.mealsCount?.value || 0,
        ordersAmount: payload.ordersAmount?.value || 0,
        ordersCount: payload.ordersCount?.value || 0,
        client: {
          total: payload.totalClientWalletsAmount?.value || 0,
          actual: payload.totalClientWalletsActualAmount?.value || 0
        },
        chef: {
          total: payload.totalChefWalletsAmount?.value || 0,
          actual: payload.totalChefWalletsActualAmount?.value || 0
        },
        captain: {
          total: payload.totalCaptainWalletsAmount?.value || 0,
          actual: payload.totalCaptainWalletsActualAmount?.value || 0
        }
      }
      state.loading = false
    },
    UPDATE_CURRENT_DATE(state, payload) {
      state.currentDate = payload
    }
  },
  actions: {
    async doFetch({ commit }) {
      try {
        commit('FETCH_STARTED')
        const currentDate = HelperClass.getDateNowDetails()
        commit('UPDATE_CURRENT_DATE', currentDate)
        const db = getDatabase()
        const statsRef = ref(db, 'stats')
        onValue(statsRef, snapshot => {
          const data = snapshot.val()
          commit('SET_STATISTICS', data)
        })
        // const realtime = new Promise(resolve => {
        //   let data
        //   onValue(statsRef, snapshot => {
        //     data = snapshot.val()
        //     commit('SET_STATISTICS', data)
        //   })
        //   if (data) resolve()
        // })
        // const timeout = new Promise((resolve, reject) => {
        //   setTimeout(() => {
        //     get(statsRef, snapshot => {
        //       if (snapshot.exists()) {
        //         const data = snapshot.val()
        //         commit('SET_STATISTICS', data)
        //         resolve()
        //       } else {
        //         reject()
        //       }
        //     })
        //   }, 5000)
        // })
        // await Promise.race([realtime, timeout])
      } catch (error) {
        console.error('stats ERROR', error)
        commit('FETCH_ERROR')
      }
    }
  }
}
