/* eslint-disable */
import ListStore from '@/store/meal/meal-list-store';
import FormStore from '@/store/meal/meal-form-store';

export default {
  namespaced: true,

  modules: {
    list: ListStore,
    form: FormStore
  },
};
