/* eslint-disable */
import UserService from '@/store/user/user-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'

export default {
  namespaced: true,

  state: {
    loading: false
  },

  getters: {
    loading: state => !!state.loading
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true
    },

    DESTROY_ALL_SUCCESS(state) {
      state.loading = false
    },

    DESTROY_ALL_ERROR(state) {
      state.loading = false
    },

    DESTROY_STARTED(state) {
      state.loading = true
    },

    DESTROY_SUCCESS(state) {
      state.loading = false
    },

    DESTROY_ERROR(state) {
      state.loading = false
    }
  },

  actions: {
    async doDestroy({ commit, dispatch, rootGetters }, id) {
      try {
        commit('DESTROY_STARTED')
        const type = rootGetters[`user/list/type`]

        type === 'client'
          ? await UserService.removeClients([id])
          : type === 'chef'
          ? await UserService.removeChefs([id])
          : await UserService.removeCaptains([id])

        commit('DESTROY_SUCCESS')
        type === 'client'
          ? Message.success(i18n('clients.destroy.success'))
          : type === 'chef'
          ? Message.success(i18n('chefs.destroy.success'))
          : Message.success(i18n('captains.destroy.success'))

        dispatch(
          `user/list/doFetch`,
          {
            filter: rootGetters[`user/list/filter`],
            pagination: rootGetters[`user/list/pagination`],
            type
          },
          { root: true }
        )
      } catch (error) {
        Errors.handle(error)
        commit('DESTROY_ERROR')
      }
    },

    async doDestroyAll({ commit, dispatch, rootGetters }, ids) {
      try {
        commit('DESTROY_ALL_STARTED')

        const type = rootGetters[`user/list/type`]

        type === 'client'
          ? await UserService.removeClients(ids)
          : type === 'chef'
          ? await UserService.removeChefs(ids)
          : await UserService.removeCaptains(ids)

        commit('DESTROY_ALL_SUCCESS')

        type === 'client'
          ? Message.success(i18n('clients.destroyAll.success'))
          : type === 'chef'
          ? Message.success(i18n('chefs.destroyAll.success'))
          : Message.success(i18n('captains.destroyAll.success'))

        dispatch(
          `user/list/doFetch`,
          {
            filter: rootGetters[`user/list/filter`],
            pagination: rootGetters[`user/list/pagination`],
            type
          },
          { root: true }
        )
      } catch (error) {
        Errors.handle(error)
        commit('DESTROY_ALL_ERROR')
      }
    }
  }
}
