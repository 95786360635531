import router from '@/router'
import Errors from '@/shared/error/errors'
// import crypto from '@/shared/crypto'

const INITIAL_PAGE_SIZE = 50
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  orderBy: 'createdAt',
  sortType: 'desc',
  action: 'next',
  firstPage: true,
  offset: 0
}

const filterToUrl = filter =>
  filter.map(item => `${item.key}_${item.operator}=${item.value}`).join('&')

export const state = {
  rows: [],
  count: 0,
  loading: false,
  filter: [],
  listFn: null,
  currentPage: 1,
  pagination: INITIAL_PAGINATION_INPUT,
  hasNext: false,
  hasPrev: false
}
export const getters = {
  loading: state => state.loading,
  rows: state => state.rows || [],
  hasNext: state => state.hasNext,
  hasPrev: state => state.hasPrev,
  count: state => state.count,
  filter: state => state.filter,
  listFn: state => state.listFn,
  currentPage: state => state.currentPage,
  limit: state => {
    const pageSize = state.pagination?.pageSize
    return pageSize || INITIAL_PAGE_SIZE
  },
  pagination: (state, getters) => {
    return {
      ...state.pagination,
      currentPage: state.currentPage,
      total: getters.count,
      showSizeChanger: true
    }
  }
}
export const mutations = {
  FETCH_STARTED(state, payload) {
    state.loading = true
    state.listFn = payload?.list || state.listFn
    state.filter = payload?.filter || state.filter
    state.pagination =
      payload?.pagination || (payload?.keepPagination ? state.pagination : {})
  },
  FETCH_SUCCESS(
    state,
    { rows, PaginationInput, page, routePagination, filterInput }
  ) {
    // Calculate number of returned data
    const count = rows.length
    // Get the pagination fields
    const { limit, action, sortType, orderBy, offset } = PaginationInput
    // Get Filter Input
    const filter = filterToUrl(filterInput) || undefined
    // const [filter] = filterInput
    // let filterQuery = {}
    // filter &&
    //   (filterQuery = {
    //     filter: filter.key,
    //     oper: filter.operator,
    //     val: filter.value
    //   })
    // construct the query object
    const query = {
      sortType,
      orderBy,
      action,
      page,
      limit: limit - 1,
      filter
    }
    // encrypt the query object
    // const encryptedQuery = crypto.encryption(query, 'secret-c-u').toString()
    // Push the query object to the route
    routePagination &&
      router.push({
        query
      })
    // Calculate if there is next or previous pages
    state.hasNext = limit === count || action === 'prev'
    state.hasPrev = !!offset
    // set loading and data
    state.loading = false
    state.rows = rows.slice(0, limit - 1)
    state.count = count - 1
  },
  FETCH_ERROR(state) {
    state.loading = false
    state.rows = []
    state.count = 0
  },
  PAGINATION_CURRENT_PAGE_CHANGED(state, page) {
    state.currentPage = page || state.currentPage || 1
  },
  PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
    const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT
    state.currentPage = state.currentPage || 1
    state.pagination = {
      ...previousPagination,
      limit: payload || INITIAL_PAGE_SIZE
    }
  }
}
export const actions = {
  async fetch({
    commit,
    getters,
    list,
    filter,
    pagination,
    keepPagination = true,
    routePagination = true
  } = {}) {
    try {
      // Update Getters
      commit('FETCH_STARTED', { filter, pagination, keepPagination, list })
      // Extract the orderBy and limit
      const limit = pagination?.limit + 1 || getters.pagination.limit + 1
      const orderBy = pagination?.orderBy || getters.pagination.orderBy
      const page = pagination?.page || getters.currentPage
      const offset = pagination?.offset || (limit - 1) * (page - 1)
      // construct pagination input
      const PaginationInput = {
        limit,
        orderBy,
        offset,
        action: pagination.action || getters.pagination.action,
        firstPage: !offset,
        sortType: pagination.sortType || getters.pagination.sortType
      }

      // Fetch
      const response = await list(getters.filter, PaginationInput)

      commit('FETCH_SUCCESS', {
        rows: response,
        page: getters.currentPage,
        PaginationInput,
        filterInput: getters.filter,
        routePagination
      })
      return response
    } catch (error) {
      Errors.handle(error)
      commit('FETCH_ERROR')
    }
  },
  async fetchNext({ commit, getters, routePagination = true }) {
    try {
      const rows = getters.rows || []
      if (rows.length) {
        let currentPage = getters.currentPage || 1
        const limit = getters.pagination?.limit
        const offset = limit * currentPage++
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const pagination = {
          ...previousPagination,
          action: 'next',
          offset,
          page: currentPage,
          firstPage: !offset
        }
        const list = getters.listFn
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
        await this.fetch({ commit, getters, list, pagination, routePagination })
      }
    } catch (error) {
      Errors.handle(error)
    }
  },
  async fetchPrevious({ commit, getters, routePagination = true }) {
    try {
      const rows = getters.rows || []
      let currentPage = getters.currentPage || 1
      if (currentPage > 1 && rows.length) {
        const limit = getters.pagination?.limit
        const offset = (--currentPage - 1) * limit
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const pagination = {
          ...previousPagination,
          action: 'prev',
          firstPage: !offset,
          page: currentPage,
          offset
        }
        const list = getters.listFn
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
        await this.fetch({ commit, getters, list, pagination, routePagination })
      }
    } catch (error) {}
  },
  changeCurrentPage({ commit, page }) {
    commit('PAGINATION_CURRENT_PAGE_CHANGED', page)
  },
  async changePageSize({ commit, getters, pageSize }) {
    commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize)
    const filter = getters.filter
    const pagination = getters.pagination
    const list = getters.listFn
    await this.fetch({ commit, getters, filter, pagination, list })
  }
}
