<template>
  <button
    id="tabulator-print"
    class="btn btn-outline-secondary dark:text-white w-1/2 sm:w-auto"
    @click="onPrint"
  >
    <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
    {{ i18n('common.table.print') }}
  </button>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'action-print-button',
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    onPrint() {
      this.$emit('print')
    }
  }
})
</script>

<style scoped></style>
