/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlCategory)

export class CategoryService {
  static async createCategory(name) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE_CATEGORY($name: NameInput!) {
          createCategory(name: $name)
        }
      `,
      variables: {
        name
      }
    })

    if (response.data.createCategory && response.data.createCategory.status) {
      return response.data.createCategory.result
    } else {
      console.error(response.data.createCategory.error)
      throw response.data.createCategory.error
    }
  }

  /**
   * Update category
   * - editCategory(categoryId: String!, updateObject: JSON!): JSON!
   * @param {String} id - Category ID
   * @param {Object} name
   * @param {String} name.en
   * @param {String} name.ar
   * @returns
   */
  static async editCategory(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CATEGORY_UPDATE(
          $categoryId: String!
          $categoryUpdate: CategoryUpdate!
        ) {
          editCategory(categoryId: $categoryId, categoryUpdate: $categoryUpdate)
        }
      `,

      variables: {
        categoryId: id,
        categoryUpdate: data
      }
    })

    if (response.data.editCategory && response.data.editCategory.status) {
      return response.data.editCategory.result
    } else {
      console.error(response.data.editCategory.error)
      throw response.data.editCategory.error
    }
  }

  /**
   * Delete category by ID
   * - removeCategory(categoryId: String!): JSON!
   * @param {String} categoryId
   * @returns
   */
  static async removeCategory(categoryId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CATEGORY_DESTROY($categoryId: String!) {
          removeCategory(categoryId: $categoryId)
        }
      `,

      variables: {
        categoryId
      }
    })

    if (response.data.removeCategory && response.data.removeCategory.status) {
      return response.data.removeCategory.result
    } else {
      console.error(response.data.removeCategory.error)
      throw response.data.removeCategory.error
    }
  }

  /**
   *
   * - setCategoryImage(categoryId: String!, image: String!): JSON!
   * @param {String} categoryId
   * @param {String} image
   */
  // static async setCategoryImage(categoryId, image) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation SET_CATEGORY_IMAGE(
  //         $categoryId: String!,
  //         $image: String!
  //       ) {
  //         setCategoryImage(
  //           categoryId: $categoryId,
  //           image: $image
  //         )
  //       }
  //     `,

  //     variables: {
  //       categoryId,
  //       image
  //     }
  //   })

  //   if (response.data.setCategoryImage && response.data.setCategoryImage.status) {
  //     return response.data.setCategoryImage.result
  //   } else {
  //     console.error(response.data.setCategoryImage.error);
  //     throw response.data.setCategoryImage.error
  //   }
  // }

  /**
   * Arrange categories by order
   * - arrangeCategories(categoriesIds: [String]!): JSON!
   * @param {String[]} categoriesIds
   * @returns {Promise<JSON>}
   */
  static async arrangeCategories(categoriesIds) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CATEGORY_ARRANGE($categoriesIds: [String]!) {
          arrangeCategories(categoriesIds: $categoriesIds)
        }
      `,
      variables: {
        categoriesIds
      }
    })

    if (
      response.data.arrangeCategories &&
      response.data.arrangeCategories.status
    ) {
      return response.data.arrangeCategories.result
    } else {
      console.error(response.data.arrangeCategories.error)
      throw response.data.arrangeCategories.error
    }
  }
  //#endregion

  //#region [ Query APIs ]

  /**
   * Find Category By ID
   * - findCategoryById(categoryId: String!): Category!
   * @param {String} id - Category ID
   * @returns
   */
  static async findCategoryById(id) {
    const response = await graphqlClient.query({
      query: gql`
        query CATEGORY_FIND($categoryId: String!) {
          findCategoryById(categoryId: $categoryId) {
            createdAt
            createdBy
            id
            image
            isRemoved
            name {
              ar
              en
            }
            order
            updatedBy
            updatedAt
          }
        }
      `,
      variables: {
        categoryId: id
      }
    })

    return response.data.findCategoryById
  }

  /**
   * List all categories
   * - categories: [Category]!
   * @returns {Promise<Category[]>}
   */
  static async listCategories(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query CATEGORIES_LIST(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listCategories(filter: $filter, pagination: $pagination) {
            createdAt
            createdBy
            id
            image
            isRemoved
            name {
              en
              ar
            }
            order
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter,
        pagination
      }
    })

    return response.data.listCategories
  }

  static async listAllCategories() {
    const response = await graphqlClient.query({
      query: gql`
        query CATEGORIES_LIST {
          listAllCategories {
            createdAt
            createdBy
            id
            image
            isRemoved
            name {
              en
              ar
            }
            order
            updatedAt
            updatedBy
          }
        }
      `
    })

    return response.data.listAllCategories
  }
  //#endregion
}
