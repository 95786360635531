<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <div class="down" style="z-index: 2 !important"></div>
    <div class="sm:px-10">
      <div class="container block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <div class="m-auto logo-web flex justify-center items-center">
            <lottie-player
              src="/logoAnimation.json"
              speed="1"
              autoplay
              background="transparent"
              style="width: 600px; height: 600px"
            ></lottie-player>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="
              my-auto
              mx-auto
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              xl:pt-20
              sm:w-3/4
              xl:w-2/3
              lg:w-2/4
            "
          >
            <div class="xl:hidden flex justify-center">
              <div class="">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="-intro-x w-1/2"
                  src="@/assets/images/homefoodlogo.png"
                  style="width: 150px"
                />
              </div>
            </div>
            <h2
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-white
              "
            >
              {{ i18n('login.signIn') }}
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              {{ i18n('login.signInMobile') }}
            </div>
            <div class="intro-x mt-8">
              <input
                type="email"
                class="
                  intro-x
                  login__input
                  form-control
                  py-3
                  px-4
                  border-gray-300
                  block
                  dark:bg-dark-9
                "
                :placeholder="i18n('login.emailAddress')"
                v-model="form.email"
              />
              <div
                class="flex items-center login__input"
                style="position: relative"
              >
                <input
                  :type="visible ? 'text' : 'password'"
                  class="
                    intro-x
                    login__input
                    form-control
                    py-3
                    px-4
                    border-gray-300
                    block
                    mt-4
                    dark:bg-dark-9
                  "
                  :placeholder="i18n('login.password')"
                  v-model="form.password"
                  @keyup.enter="doLogin"
                />
                <button
                  @click="visible = !visible"
                  class="toggle-password-btn"
                  :style="isRTL ? 'left: 0px;' : 'right: 0px;'"
                >
                  <eye-off-icon
                    size="1.5x"
                    class="custom-class"
                    v-if="!visible"
                  ></eye-off-icon>
                  <eye-icon size="1.5x" class="custom-class" v-else></eye-icon>
                </button>
              </div>
            </div>
            <div
              class="
                intro-x
                flex
                text-gray-700
                dark:text-gray-600
                text-xs
                sm:text-sm
                mt-4
              "
            >
              <div
                class="flex items-center"
                :class="isRTL ? 'ml-auto' : 'mr-auto'"
              >
                <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2 ml-2"
                  v-model="form.rememberMe"
                />
                <label
                  class="cursor-pointer select-none xl:text-white"
                  for="remember-me"
                  >{{ i18n('login.rememberMe') }}</label
                >
              </div>
              <div
                class="xl:text-white cursor-pointer"
                @click="goToForgotPassword"
              >
                {{ i18n('login.forgotPassword') }}
              </div>
            </div>
            <div
              v-if="error"
              class="
                alert alert-danger alert-dismissible
                show
                flex
                items-center
                mb-2
                mt-2
              "
              role="alert"
              style="z-index: 2 !important"
            >
              <AlertOctagonIcon class="w-6 h-6 mr-2 ml-2" />
              {{ i18n(errorData) }}
            </div>
            <div
              class="
                intro-x
                mt-5
                xl:mt-8
                text-center
                xl:text-left
                grid grid-cols-12
                login__input
              "
              :class="isRTL ? 'xl:text-right' : 'xl:text-left'"
            >
              <button
                class="
                  btn btn-primary
                  text-white
                  py-3
                  px-4
                  w-full
                  align-top
                  col-span-12
                "
                style="border: unset !important"
                :class="isRTL ? 'xl:ml-3' : 'xl:mr-3'"
                :disabled="loading"
                @click="doLogin"
              >
                <span v-if="!loading">{{ i18n('login.login') }}</span>
                <span v-if="loading">{{ i18n('common.loading') }}</span>
                <LoadingIcon
                  icon="three-dots"
                  color="#FFFFFF"
                  style="margin: 0 4px"
                  v-if="loading"
                />
              </button>
            </div>
            <div></div>
            <div
              class="
                intro-x
                mt-4
                text-gray-700
                dark:text-gray-600
                text-center
                xl:text-white
              "
              :class="isRTL ? 'xl:text-right' : 'xl:text-left'"
            >
              <i18nFlags class="mb-10" @click="changeLoginClass" />
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  created() {
    document.title = this.i18n('routes.app')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('routes.app')
    }
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      isRTL: 'layout/isRTL'
    })
  },
  mounted() {
    if (this.isRTL) {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login-rtl')
    } else {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    }
  },
  setup() {
    const error = ref(false)
    const errorData = ref('')
    const form = ref({
      email: ref(''),
      password: ref(''),
      rememberMe: ref(false)
    })
    const visible = ref(false)
    return {
      error,
      errorData,
      form,
      visible
    }
  },
  methods: {
    ...mapActions({
      doSigninWithEmailAndPassword: 'auth/doSigninWithEmailAndPassword'
    }),
    validateEmail(email) {
      return email.match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    },
    goToSignup() {
      this.$router.push('/auth/register')
    },
    goToForgotPassword() {
      this.$router.push('/auth/forgot-password')
    },
    changeLoginClass() {
      if (this.isRTL) {
        cash('body').removeClass('login').addClass('login-rtl')
      } else {
        cash('body').removeClass('login-rtl').addClass('login')
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    async doLogin() {
      if (this.form.email.length == 0 || this.form.password.length == 0) {
        this.error = true
        this.errorData = 'login.errors.emptyFields'
      } else if (!this.validateEmail(this.form.email)) {
        this.error = true
        this.errorData = 'login.errors.wrongEmail'
      } else {
        this.error = false
        await this.doSigninWithEmailAndPassword({
          email: this.form.email,
          password: this.form.password,
          rememberMe: this.form.rememberMe
        })
      }
    }
  }
}
</script>

<style lang="scss">
.dark .btn-outline-secondary:hover {
  --tw-bg-opacity: 0.2;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.down {
  @media only screen and (min-width: 1280px) {
    height: 100vh;
    width: 10px;
    background-color: #172e71;
    animation-name: drawDownLine;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform-origin: 50% 0%;
    position: absolute;
    left: 50%;
  }
}

@keyframes drawDownLine {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleyY(1);
  }
}

.logo-web {
  width: 550px !important;
  text-align: center !important;
  // margin-bottom: 100px !important;
  font-family: 'Times New Roman', sans-serif !important;
}

.logo-mobile {
  text-align: center !important;
  font-family: 'Times New Roman', sans-serif !important;
  margin-bottom: 20px !important;
}

.logo-web-img {
  margin-left: 50px;
}

// [dir='rtl'] .logo-web {
//   margin-right: 50px;
// }

.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}

.toggle-password-btn {
  position: absolute !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  z-index: 100 !important;
  margin-top: 16px !important;
  padding: 10px !important;
}

.toggle-password-btn:focus {
  box-shadow: none !important;
}

.login__input {
  box-shadow: none !important;
}
</style>
