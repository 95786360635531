/* eslint-disable */
import { ReportService } from '@/store/report/report-service';
import Errors from '@/shared/error/errors';

export default {
  namespaced: true,
  state: {
    record: null,
    loading: false,
  },
  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
  },
  mutations: {
    RESET(state) {
      state.record = null;
      state.loading = false;
    },
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },
    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
  },
  actions: {
    doNew({ commit }) {
      commit('RESET');
    },
    async doFind({ commit }, { startDate, endDate }) {
      try {
        commit('FIND_STARTED');
        const record = await ReportService.getSalesReport(startDate, endDate);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },
  },
};
