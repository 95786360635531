/* eslint-disable */
import { CategoryService } from '@/store/category/category-service'
import Errors from '@/shared/error/errors'

export default {
  namespaced: true,
  state: {
    listAllLoading: false,
    categories: []
  },
  getters: {
    listAllLoading: state => state.listAllLoading,
    categories: state => state.categories
  },
  mutations: {
    CATEGORIES_ALL_STARTED(state) {
      state.listAllLoading = true
    },
    CATEGORIES_ALL_SUCCESS(state, categories) {
      state.listAllLoading = false
      state.categories = categories
    },
    CATEGORIES_ALL_ERROR(state) {
      state.listAllLoading = false
    }
  },
  actions: {
    async listAllCategories({ commit }) {
      try {
        commit('CATEGORIES_ALL_STARTED')
        const response = await CategoryService.listAllCategories()
        commit('CATEGORIES_ALL_SUCCESS', response)
      } catch (error) {
        Errors.handle(error)
        commit('CATEGORIES_ALL_ERROR')
      }
    }
  }
}
