import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import i18n from './vueI18n'
import './libs'
// import { crashlytics } from '@/shared/firebase/firebase-init'

// SASS Theme
import './assets/sass/app.scss'
import 'material-icons/iconfont/material-icons.css'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)

globalComponents(app)
utils(app)

// app.config.errorHandler = (error, vm, info) => {
  // // Report the error to Crashlytics
  // crashlytics.recordException(error)
  // // Optionally, log additional information like the Vue component and lifecycle hook where the error occurred
  // console.error('Unhandled Vue error:', error, vm, info)
// }

app.mount('#app')
