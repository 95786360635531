/* eslint-disable */
import AdminService from '@/store/auth/auth-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import { state, getters, mutations, actions } from '@/store/list-utils'

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations,
    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true
    },
    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false
    },
    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      state.loading = false
    }
  },
  actions: {
    async doChangePaginationPageSize({ commit, getters }, pageSize) {
      await actions.changePageSize({ commit, getters, pageSize })
    },
    doChangePaginationCurrentPage({ commit }, currentPage) {
      actions.changeCurrentPage({ commit, page: currentPage })
    },
    async doFetchNextPage({ commit, getters }) {
      await actions.fetchNext({ commit, getters })
    },
    async doFetchPreviousPage({ commit, getters }) {
      await actions.fetchPrevious({ commit, getters })
    },
    async doFetch(
      { commit, getters },
      { filter, pagination, keepPagination = true } = {}
    ) {
      await actions.fetch({
        commit,
        getters,
        list: AdminService.fetchAdmins,
        filter,
        pagination,
        keepPagination
      })
    },
    async doDisableAllSelected({ getters, commit, dispatch }, ids) {
      try {
        commit('CHANGE_STATUS_SELECTED_STARTED')
        await AdminService.disable(ids)
        commit('CHANGE_STATUS_SELECTED_SUCCESS')
        Message.success(i18n('iam.doDisableAllSuccess'))
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      }
    },
    async doEnableAllSelected({ commit, getters, dispatch }, ids) {
      try {
        commit('CHANGE_STATUS_SELECTED_STARTED')
        await AdminService.enable(ids)
        commit('CHANGE_STATUS_SELECTED_SUCCESS')
        Message.success(i18n('iam.doEnableAllSuccess'))
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      }
    }
  }
}
