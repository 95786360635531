/* eslint-disable */
import PromotionService from '@/store/promotion/promotion-service'
import Errors from '@/shared/error/errors'
import { state, getters, mutations, actions } from '@/store/list-utils'

const storeState = {
  ...state
}

const storeGetters = { ...getters }

const storeMutations = {
  ...mutations,
  REMOVE_STARTED(state) {
    state.loading = true
  },
  REMOVE_SUCCESS(state) {
    state.loading = false
  },
  REMOVE_ERROR(state) {
    state.loading = false
  }
}

const storeActions = {
  async doChangePaginationPageSize({ commit, getters }, pageSize) {
    await actions.changePageSize({ commit, getters, pageSize })
  },
  doChangePaginationCurrentPage({ commit }, currentPage) {
    actions.changeCurrentPage({ commit, page: currentPage })
  },
  async doFetchNextPage({ commit, getters }) {
    await actions.fetchNext({ commit, getters })
  },
  async doFetchPreviousPage({ commit, getters }) {
    await actions.fetchPrevious({ commit, getters })
  },
  async doFetch(
    { commit, getters },
    { filter, pagination, keepPagination = true } = {}
  ) {
    await actions.fetch({
      commit,
      getters,
      list: PromotionService.list,
      filter,
      pagination,
      keepPagination
    })
  },
  async doDestroy({ commit, dispatch, getters }, id) {
    try {
      commit('REMOVE_STARTED')
      await PromotionService.remove(id)
      commit('REMOVE_SUCCESS')
      await dispatch('doFetch', { pagination: getters.pagination })
    } catch (error) {
      Errors.handle(error)
      commit('REMOVE_ERROR')
    }
  }
}

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions
}
