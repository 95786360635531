/* eslint-disable */
// import { routerAsync } from '@/app-module';
import { ReplyService } from '@/store/reply/reply-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';
// import router from '@/router';

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {
    loading: (state) => !!state.loading,
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },
    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },
    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },

    DESTROY_STARTED(state) {
      state.loading = true;
    },
    DESTROY_SUCCESS(state) {
      state.loading = false;
    },
    DESTROY_ERROR(state) {
      state.loading = false;
    },
  },

  actions: {
    async doDestroy({ commit, dispatch }, id) {
      try {
        commit('DESTROY_STARTED');
        await ReplyService.remove(id)
        commit('DESTROY_SUCCESS');
        Message.success(i18n('savedReplies.delete.success'))
        
        dispatch(`reply/list/doFetch`, {}, { root: true });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },
  },
};
