export const Client = `{
    id
    fullName
    code
    closedOrdersCount
    cancelledOrdersCount
    phoneNumber
    email
    avatar
    dashboardReview {
      rating
      review
    }
    authenticationUid
    deviceTokens
    lang
    city {
      en
      ar
    }
    isEnabled
    notification
    isRemoved
    city {
      en
      ar
    }
    createdBy
    createdAt
    updatedBy
    updatedAt
  }`

export const Chef = `{
    id
    code
    phoneNumber
    avatar
    lang
    rating
    city {
      en
      ar
    }
    reviewsCount
    closedOrdersCount
    cancelledOrdersCount
    authenticationUid
    email
    dashboardReview {
        rating
        review
      }
    fullName
    deviceTokens
    taxNumber
    isEnabled
    address {
      name
      lat
      long
      street
      building
    }
    freelanceCertificate
    medicalCertificate
    idCard
    promoted
    sections {
      id
      name {
        en
        ar
      }
      meals {
        id
        name {
          en
          ar
        }
        description {
          en
          ar
        }
        categoryId
        sectionId
        price
        images
        video
        category {
          name {
            en
            ar
          }
          id
          image
          order
        }
        orderBefore
        isEnabled
        isRemoved
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
    kitchen {
      kitchenName {
        en
        ar
      }
      logo
      cover
      minOrderAmount
      categories {
        id
        name {
          en
          ar
        }
        image
        order
        isRemoved
      }
    }
    notification
    mealsCount
    sectionsCount
    weeklyHours
    isRemoved
    createdBy
    createdAt
    updatedBy
    updatedAt
  }`

export const Captain = `{
    id
    fullName
    code
    phoneNumber
    email
    city {
      en
      ar
    }
    avatar
    authenticationUid
    deviceTokens
    currentLat
    currentLng
    dashboardReview {
        rating
        review
      }
    closedOrdersCount
    cancelledOrdersCount
    geohash
    pendingOrder {
      id
      orderNo
    }
    deliveringOrder {
      id
      orderNo
    }
    lang
    vehicleId
    vehicle {
      id
      name {
        en
        ar
      }
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
    license
    vehicleLicense
    isEnabled
    notification
    isRemoved
    createdBy
    createdAt
    updatedBy
    updatedAt
  }`

export const kitchen = `{
    sections {
      id
      name {
        en
        ar
      }
    }
    kitchen {
      kitchenName {
        en
        ar
      }
      categories {
        id
        name {
          en
          ar
        }
        image
        order
        isRemoved
      }
      logo
      cover
      timeZone
      categoriesIds
      minOrderAmount
      schedule {
        saturday {
          working
          startTime
          endTime
        }
        sunday {
          working
          startTime
          endTime
        }
        monday {
          working
          startTime
          endTime
        }
        tuesday {
          working
          startTime
          endTime
        }
        wednesday {
          working
          startTime
          endTime
        }
        thursday {
          working
          startTime
          endTime
        }
        friday {
          working
          startTime
          endTime
        }
      }
    }
  }`

export const ClientWallet = `{
    id
    clientId
    client {
      code
      fullName
      phoneNumber
    }
    balance
    actualBalance
    dayAmount
    weekAmount
    monthAmount
    # yearAmount
    createdAt
    createdBy
    updatedAt
    updatedBy
  }`

export const ChefWallet = `{
    id
    chefId
    chef {
      code
      fullName
      phoneNumber
    }
    balance
    actualBalance
    dayAmount
    weekAmount
    monthAmount
    # yearAmount
    createdAt
    createdBy
    updatedAt
    updatedBy
  }`

export const ChefReview = `{
  id
  orderId
  clientId
  chefId
  clientName
  rating
  review
  createdBy
  createdAt
  updatedBy
  updatedAt  
}`

export const CaptainWallet = `{
    id
    captainId
    balance
    captain {
      code
      fullName
      phoneNumber
    }
    actualBalance
    dayAmount
    weekAmount
    monthAmount
    # yearAmount
    createdAt
    createdBy
    updatedAt
    updatedBy
  }`

export const AppWallet = `{
    id
    balance
    actualBalance
    createdAt
    createdBy
    updatedAt
    updatedBy
  }`

export const Address = `{
    id
    name
    lat
    long
    street
    building
    notes
    isDefault
    isRemoved
  }`
