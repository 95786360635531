import userListStore from '@/store/user/user-list-store'
import userViewStore from '@/store/user/user-view-store'
import userDestroyStore from '@/store/user/user-destroy-store'
import userFormStore from '@/store/user/user-form-store'

export default {
  namespaced: true,
  modules: {
    view: userViewStore,
    form: userFormStore,
    list: userListStore,
    destroy: userDestroyStore
  }
}
