/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlMeal)

export class MealService {
  static async listMeals(chefId, filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query MEAL_LIST(
          $chefId: String!
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listChefMeals(
            chefId: $chefId
            filter: $filter
            pagination: $pagination
          ) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            categoryId
            sectionId
            price
            images
            video
            category {
              id
              name {
                en
                ar
              }
              image
              order
            }
            section {
              id
              name {
                en
                ar
              }
            }
            orderBefore
            addons {
              id
              name {
                en
                ar
              }
              price
            }
            chefId
            isEnabled
            isRemoved
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,
      variables: {
        chefId,
        filter,
        pagination
      }
    })

    return response.data.listChefMeals
  }
  static async editMeal(chefId, mealId, mealUpdate) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation MEAL_UPDATE(
          $chefId: String!
          $mealId: String!
          $mealUpdate: MealUpdate!
        ) {
          editMeal(chefId: $chefId, mealId: $mealId, mealUpdate: $mealUpdate)
        }
      `,
      variables: {
        chefId,
        mealId,
        mealUpdate
      }
    })

    if (response.data.editMeal && response.data.editMeal.status) {
      return response.data.editMeal.result
    } else {
      console.error(response.data.editMeal.error)
      throw response.data.editMeal.error
    }
  }
  static async removeMeal(chefId, mealId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation MEAL_DESTROY($chefId: String!, $mealId: String!) {
          removeMeal(chefId: $chefId, mealId: $mealId)
        }
      `,

      variables: {
        chefId,
        mealId
      }
    })

    if (response.data.removeMeal && response.data.removeMeal.status) {
      return response.data.removeMeal.result
    } else {
      console.error(response.data.removeMeal.error)
      throw response.data.removeMeal.error
    }
  }
  static async findMeal(chefId, mealId) {
    const response = await graphqlClient.query({
      query: gql`
        query MEAL_FIND($chefId: String!, $mealId: String!) {
          findMeal(chefId: $chefId, mealId: $mealId) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            categoryId
            sectionId
            price
            images
            video
            category {
              id
              name {
                en
                ar
              }
              image
              order
            }
            section {
              id
              name {
                en
                ar
              }
            }
            orderBefore
            addons {
              id
              name {
                en
                ar
              }
              price
            }
            sizes {
              id
              name {
                en
                ar
              }
              price
            }
            chefId
            isEnabled
            isRemoved
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,
      variables: {
        chefId,
        mealId
      }
    })
    return response.data.findMeal
  }
}
