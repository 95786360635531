// const firebaseConfig = {
//   apiKey: 'AIzaSyBfuKMUnoTBYHwQCcyZV-5k-3vw0r200vo',
//   authDomain: 'admin-homefoodplus.firebaseapp.com',
//   projectId: 'admin-homefoodplus',
//   storageBucket: 'admin-homefoodplus.appspot.com',
//   messagingSenderId: '945513885664',
//   appId: '1:945513885664:web:78e4fb62dce46f5123c585',
//   measurementId: 'G-NVD7Q2X2MQ'
// }

const firebaseConfig = {
  apiKey: 'AIzaSyBfuKMUnoTBYHwQCcyZV-5k-3vw0r200vo',
  authDomain: 'home-food-plus.firebaseapp.com',
  databaseURL: 'https://home-food-plus-default-rtdb.firebaseio.com',
  projectId: 'home-food-plus',
  storageBucket: 'home-food-plus.appspot.com',
  messagingSenderId: '945513885664',
  appId: '1:945513885664:web:78e4fb62dce46f5123c585',
  measurementId: 'G-NVD7Q2X2MQ'
}
// App Engine / Debug https://europe-west1-home-food-plus.cloudfunctions.net

const REGION = 'europe-west1'
const BASE_URL = 'https://home-food-production-ur2j7ljwaa-ey.a.run.app'

const backendUrl = BASE_URL
const backendUrlAdmin = `${BASE_URL}/admin`
const backendUrlClient = `${BASE_URL}/client`
const backendUrlCaptain = `${BASE_URL}/captain`
const backendUrlChef = `${BASE_URL}/chef`
const backendUrlVehicle = `${BASE_URL}/vehicle`
const backendUrlSlider = `${BASE_URL}/slider`
const backendUrlSettings = `${BASE_URL}/settings`
const backendUrlCategory = `${BASE_URL}/category`
const backendUrlNotification = `${BASE_URL}/notification`
const backendUrlMeal = `${BASE_URL}/meal`
const backendUrlOrder = `${BASE_URL}/order`
const backendUrlReport = `${BASE_URL}/reports`
const backendUrlTransactions = `${BASE_URL}/transactions`
const backendUrlExport = `${BASE_URL}/excel`

export default {
  firebaseConfig,
  REGION,
  BASE_URL,
  backendUrl,
  backendUrlAdmin,
  backendUrlClient,
  backendUrlCaptain,
  backendUrlChef,
  backendUrlVehicle,
  backendUrlSlider,
  backendUrlSettings,
  backendUrlCategory,
  backendUrlNotification,
  backendUrlMeal,
  backendUrlOrder,
  backendUrlReport,
  backendUrlExport,
  backendUrlTransactions
}
