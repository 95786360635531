<template>
  <div class="flex sm:mt-0 gap-2 sm:flex-initial flex-1">
    <action-print-button
      v-if="printPermission"
      @print="onPrint"
    ></action-print-button>
    <action-export-button
      v-if="exportPermission"
      :canExportAll="canExportAll"
      @export-xlsx="onExportXlsx"
      @export-all-xlsx="onExportAllXlsx"
      @download-pdf="onDownloadPdf"
    ></action-export-button>
  </div>
</template>

<script>
export default {
  name: 'app-action-toolbar',
  props: {
    printPermission: {
      type: Boolean,
      default: true
    },
    exportPermission: {
      type: Boolean,
      default: true
    },
    canExportAll: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onExportXlsx() {
      this.$emit('onExportXlsx')
    },
    onExportAllXlsx() {
      this.$emit('onExportAllXlsx')
    },
    onDownloadPdf() {
      this.$emit('onDownloadPdf')
    },
    onPrint() {
      this.$emit('onPrint')
    }
  }
}
</script>
