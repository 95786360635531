import { createRouter, createWebHistory } from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'
import Login from '../views/login/Main.vue'
import ChangePassword from '../views/change-password/Main.vue'
import Settings from '../views/settings/settings.vue'
import Profile from '../views/profile/profile.vue'
import ForgotPassword from '../views/forgot-password/forgot-password.vue'
import Permissions from '@/security/permissions'

const Dashboard = () => import('@/views/dashboard/home-page.vue')
const Admins = () => import('@/views/admin/admin-list-page.vue')
const AdminFormPage = () => import('@/views/admin/admin-form-page.vue')
const AdminPassResetPage = () =>
  import('@/views/admin/admin-reset-password.vue')
const AdminViewPage = () => import('@/views/admin/admin-view-page.vue')
const AdminAccessPage = () => import('@/views/admin/admin-access-page.vue')
const Clients = () => import('@/views/client/client-list-page.vue')
const ClientViewPage = () => import('@/views/client/client-view-page.vue')
const Chefs = () => import('@/views/chef/chef-list-page.vue')
const Meals = () => import('@/views/chef/meals-list-page.vue')
const ChefViewPage = () => import('@/views/chef/chef-view-page.vue')
const ChefKitchenPage = () => import('@/views/chef/chef-kitchen-page.vue')
const ChefKitchenEditPage = () =>
  import('@/views/chef/chef-kitchen-edit-page.vue')
const MealEditPage = () => import('@/views/chef/meal-edit-page.vue')
const Captains = () => import('@/views/captain/captain-list-page.vue')
const CaptainViewPage = () => import('@/views/captain/captain-view-page.vue')
const CategoryListPage = () => import('@/views/category/category-list-page.vue')
const CategoryFormPage = () => import('@/views/category/category-form-page.vue')
const Promotions = () => import('@/views/promotions/promotion-list-page.vue')
const Vehicles = () => import('@/views/vehicle/vehicle-list-page.vue')
const VehiclesFormPage = () => import('@/views/vehicle/vehicle-form-page.vue')
const SliderListPage = () => import('@/views/slider/slider-list-page.vue')
const SliderViewPage = () => import('@/views/slider/slider-view-page.vue')
const SliderFormPage = () => import('@/views/slider/slider-form-page.vue')
const Complaint = () => import('@/views/complaint/complaint-page.vue')
const Payments = () => import('@/views/payment/payment-page.vue')
const Wallet = () => import('@/views/wallet/wallet-page.vue')
const AppWallet = () => import('@/views/wallet/app-wallet.vue')
const OrderListPage = () => import('@/views/order/order-list-page.vue')
const OrderViewPage = () => import('@/views/order/order-view-page.vue')
const NotificationListPage = () =>
  import('@/views/notification/notification-list-page.vue')
const NotificationCreate = () =>
  import('@/views/notification/notification-create.vue')
const Reports = () => import('@/views/reports/reports.vue')
const ErrorPage = () => import('@/views/error-page/Main.vue')
const Error403Page = () => import('@/views/error-page/error-403-page.vue')
const Error404Page = () => import('@/views/error-page/error-404-page.vue')
const Error500Page = () => import('@/views/error-page/error-500-page.vue')

const routes = [
  {
    path: '',
    component: SideMenu,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          auth: true
        }
      },
      {
        path: 'admins',
        name: 'admins',
        component: Admins,
        meta: {
          auth: true,
          permission: Permissions.values.adminRead
        }
      },
      {
        path: 'admins/edit/:id',
        name: 'admins-edit',
        component: AdminFormPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.values.adminEdit
        }
      },
      {
        path: 'admins/editPassword/:id',
        name: 'admins-passwordReset',
        component: AdminPassResetPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.values.adminEdit
        }
      },
      {
        path: 'admins/:id',
        name: 'admins-details',
        component: AdminViewPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.values.adminRead
        }
      },
      {
        path: 'admins/new',
        name: 'admins-new',
        component: AdminFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.adminCreate
        }
      },
      {
        name: 'admin-accessPages',
        path: 'admins/access-pages/:id',
        component: AdminAccessPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.values.pagesAccessRead
        }
      },
      {
        path: 'clients',
        name: 'clients',
        component: Clients,
        meta: {
          auth: true,
          permission: Permissions.values.clientRead
        }
      },
      {
        path: 'clients/:id',
        name: 'clients-details',
        props: true,
        component: ClientViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.clientRead
        }
      },
      {
        path: 'captains',
        name: 'captains',
        component: Captains,
        meta: {
          auth: true,
          permission: Permissions.values.captainRead
        }
      },
      {
        path: 'captains/:id',
        name: 'captains-details',
        props: true,
        component: CaptainViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.captainRead
        }
      },
      {
        path: 'chefs',
        name: 'chefs',
        component: Chefs,
        meta: {
          auth: true,
          permission: Permissions.values.chefRead
        }
      },
      {
        path: 'chefs/:id',
        name: 'chefs-details',
        props: true,
        component: ChefViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.chefRead
        }
      },
      {
        path: 'chefs/kitchen/:id',
        name: 'chefs-kitchenPage',
        props: true,
        component: ChefKitchenPage,
        meta: {
          auth: true,
          permission: Permissions.values.chefRead
        }
      },
      {
        path: 'chefs/kitchen/edit/:id',
        name: 'chefs-kitchenEdit',
        props: true,
        component: ChefKitchenEditPage,
        meta: {
          auth: true,
          permission: Permissions.values.chefRead
        }
      },
      {
        path: 'meals',
        name: 'meals',
        component: Meals,
        meta: {
          auth: true,
          permission: Permissions.values.chefRead
        }
      },
      {
        path: 'meals/edit/:chefId/:mealId',
        name: 'meals-edit',
        props: true,
        component: MealEditPage,
        meta: {
          auth: true,
          permission: Permissions.values.chefEdit
        }
      },
      {
        path: 'promotions',
        name: 'promotions',
        component: Promotions,
        meta: {
          auth: true,
          permission: Permissions.values.promotionsRead
        }
      },
      {
        path: 'vehicles',
        name: 'vehicles',
        component: Vehicles,
        meta: {
          auth: true,
          permission: Permissions.values.vehicleRead
        }
      },
      {
        path: 'vehicles/new',
        name: 'vehicles-new',
        component: VehiclesFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.vehicleCreate
        }
      },
      {
        name: 'slider',
        path: 'slider',
        component: SliderListPage,
        meta: {
          auth: true,
          permission: Permissions.values.sliderRead
        }
      },
      {
        name: 'slider-new',
        path: 'slider/new',
        component: SliderFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.sliderCreate
        }
      },
      {
        name: 'slider-edit',
        path: 'slider/edit/:id',
        props: true,
        component: SliderFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.sliderEdit
        }
      },
      {
        name: 'slider-details',
        path: 'slider/:id',
        props: true,
        component: SliderViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.sliderRead
        }
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: {
          auth: true
        }
      },
      {
        name: 'category',
        path: 'category',
        component: CategoryListPage,
        meta: {
          auth: true,
          permission: Permissions.values.categoryRead
        }
      },
      {
        name: 'category-new',
        path: 'category/new',
        component: CategoryFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.categoryCreate
        }
      },
      {
        name: 'category-edit',
        path: 'category/edit/:id',
        props: true,
        component: CategoryFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.categoryEdit
        }
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings,
        meta: {
          auth: true,
          permission: Permissions.values.settingRead
        }
      },
      {
        path: 'complaint/client',
        name: 'clientsComplaints',
        component: Complaint,
        meta: {
          auth: true,
          permission: Permissions.values.complaintRead
        }
      },
      {
        path: 'complaint/chef',
        name: 'chefsComplaints',
        component: Complaint,
        meta: {
          auth: true,
          permission: Permissions.values.complaintRead
        }
      },
      {
        path: 'complaint/captain',
        name: 'captainsComplaints',
        component: Complaint,
        meta: {
          auth: true,
          permission: Permissions.values.complaintRead
        }
      },
      {
        name: 'reports',
        path: 'reports',
        component: Reports,
        meta: {
          auth: true,
          permission: Permissions.values.reportRead
        }
      },
      {
        name: 'orders',
        path: 'orders',
        component: OrderListPage,
        meta: {
          auth: true,
          permission: Permissions.values.orderRead
        }
      },
      {
        name: 'orders-details',
        path: 'orders/:id',
        props: true,
        component: OrderViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.orderRead
        }
      },
      {
        name: 'payment',
        path: 'payment',
        component: Payments,
        meta: {
          auth: true,
          permission: [Permissions.values.requestRead]
        }
      },
      {
        name: 'appWallet',
        path: 'app-wallet',
        component: AppWallet,
        meta: {
          auth: true,
          permission: Permissions.values.appWalletRead
        }
      },
      {
        name: 'wallet',
        path: 'wallet',
        component: Wallet,
        meta: {
          auth: true,
          permission: [Permissions.values.requestRead]
        }
      },
      {
        name: 'notification',
        path: 'notification',
        component: NotificationListPage,
        meta: {
          auth: true,
          permission: Permissions.values.notificationRead
        }
      },
      {
        name: 'notification-new',
        path: 'notification/new',
        component: NotificationCreate,
        meta: {
          auth: true,
          permission: Permissions.values.notificationCreate
        }
      },
      {
        path: 'change-password',
        name: 'changePassword',
        component: ChangePassword,
        meta: {
          auth: true
        }
      }
    ]
  },
  {
    path: '/auth/login',
    name: 'login',
    component: Login,
    meta: { unauth: true }
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: { unauth: true }
  },
  // {
  //   path: '/auth/register',
  //   name: 'register',
  //   component: Register
  // },
  {
    name: 'errorPage',
    path: '/error-page',
    component: ErrorPage
  },
  {
    name: 'error403',
    path: '/403',
    component: Error403Page
  },
  {
    name: 'error404',
    path: '/404',
    component: Error404Page
  },
  {
    name: 'error500',
    path: '/500',
    component: Error500Page
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

// eslint-disable-next-line
import authGuard from '@/router/auth-guard'
router.beforeResolve(async (to, from, next) => {
  await authGuard.beforeRouteEnter(to, from, next)
})

export default router
