/* eslint-disable */
import config from '@/config'
import GraphqlClient from '@/shared/graphql/client'
import gql from 'graphql-tag'
import {
  Address,
  AppWallet,
  Captain,
  CaptainWallet,
  Chef,
  ChefWallet,
  Client,
  ClientWallet,
  kitchen,
  ChefReview
} from './user-schema'

const graphqlClients = GraphqlClient.initApolloClient(config.backendUrlClient)
const graphqlCaptains = GraphqlClient.initApolloClient(config.backendUrlCaptain)
const graphqlChefs = GraphqlClient.initApolloClient(config.backendUrlChef)
const graphqlAdmin = GraphqlClient.initApolloClient(config.backendUrlAdmin)

export default class UserService {
  static async fetchClients(filter, pagination) {
    const response = await graphqlClients.query({
      query: gql`
        query USER_LIST_CLIENTS(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listClients(filter: $filter, pagination: $pagination) ${Client}
        }
      `,
      variables: {
        filter,
        pagination
      }
    })

    return response.data.listClients
  }

  static async findClient(clientId) {
    const response = await graphqlClients.query({
      query: gql`
        query FIND_CLIENT($clientId: String!) {
          findClientById(clientId: $clientId) ${Client}
        }
      `,
      variables: {
        clientId
      }
    })
    return response.data.findClientById
  }

  static async findClientWallet(clientId) {
    const response = await graphqlClients.query({
      query: gql`
        query FIND_CLIENT($clientId: String!) {
          findClientWallet(clientId: $clientId) ${ClientWallet}
        }
      `,
      variables: {
        clientId
      }
    })
    return response.data.findClientWallet
  }
  static async rechargeClient(clientId, amount, notes) {
    const response = await graphqlClients.mutate({
      mutation: gql`
        mutation RECHARGE_WALLET(
          $clientId: String!
          $amount: Float!
          $notes: NameInput!
        ) {
          rechargeClientWalletByAdmin(
            clientId: $clientId
            amount: $amount
            notes: $notes
          )
        }
      `,
      variables: {
        clientId,
        amount,
        notes
      }
    })
    if (
      response.data.rechargeClientWalletByAdmin &&
      response.data.rechargeClientWalletByAdmin.status
    ) {
      return response.data.rechargeClientWalletByAdmin.result
    } else {
      console.error(response.data.rechargeClientWalletByAdmin.error)
      throw response.data.rechargeClientWalletByAdmin.error
    }
  }
  static async rechargeChef(chefId, amount, notes) {
    const response = await graphqlChefs.mutate({
      mutation: gql`
        mutation RECHARGE_WALLET(
          $chefId: String!
          $amount: Float!
          $notes: NameInput!
        ) {
          rechargeChefWalletByAdmin(
            chefId: $chefId
            amount: $amount
            notes: $notes
          )
        }
      `,
      variables: {
        chefId,
        amount,
        notes
      }
    })
    if (
      response.data.rechargeChefWalletByAdmin &&
      response.data.rechargeChefWalletByAdmin.status
    ) {
      return response.data.rechargeChefWalletByAdmin.result
    } else {
      console.error(response.data.rechargeChefWalletByAdmin.error)
      throw response.data.rechargeChefWalletByAdmin.error
    }
  }
  static async rechargeCaptain(captainId, amount, notes) {
    const response = await graphqlCaptains.mutate({
      mutation: gql`
        mutation RECHARGE_WALLET(
          $captainId: String!
          $amount: Float!
          $notes: NameInput!
        ) {
          rechargeCaptainWalletByAdmin(
            captainId: $captainId
            amount: $amount
            notes: $notes
          )
        }
      `,
      variables: {
        captainId,
        amount,
        notes
      }
    })
    if (
      response.data.rechargeCaptainWalletByAdmin &&
      response.data.rechargeCaptainWalletByAdmin.status
    ) {
      return response.data.rechargeCaptainWalletByAdmin.result
    } else {
      console.error(response.data.rechargeCaptainWalletByAdmin.error)
      throw response.data.rechargeCaptainWalletByAdmin.error
    }
  }
  static async rateClient(clientId, reviewInput) {
    const response = await graphqlClients.mutate({
      mutation: gql`
        mutation rate_WALLET(
          $clientId: String!
          $reviewInput: DashboardReviewInput!
        ) {
          setClientDashboardReview(
            clientId: $clientId
            reviewInput: $reviewInput
          )
        }
      `,
      variables: {
        clientId,
        reviewInput
      }
    })
    if (
      response.data.setClientDashboardReview &&
      response.data.setClientDashboardReview.status
    ) {
      return response.data.setClientDashboardReview.result
    } else {
      console.error(response.data.setClientDashboardReview.error)
      throw response.data.setClientDashboardReview.error
    }
  }
  static async rateChef(chefId, reviewInput) {
    const response = await graphqlChefs.mutate({
      mutation: gql`
        mutation rate_chef(
          $chefId: String!
          $reviewInput: DashboardReviewInput!
        ) {
          setChefDashboardReview(chefId: $chefId, reviewInput: $reviewInput)
        }
      `,
      variables: {
        chefId,
        reviewInput
      }
    })
    if (
      response.data.setChefDashboardReview &&
      response.data.setChefDashboardReview.status
    ) {
      return response.data.setChefDashboardReview.result
    } else {
      console.error(response.data.setChefDashboardReview.error)
      throw response.data.setChefDashboardReview.error
    }
  }
  static async rateCaptain(captainId, reviewInput) {
    const response = await graphqlCaptains.mutate({
      mutation: gql`
        mutation rate_WALLET(
          $captainId: String!
          $reviewInput: DashboardReviewInput!
        ) {
          setCaptainDashboardReview(
            captainId: $captainId
            reviewInput: $reviewInput
          )
        }
      `,
      variables: {
        captainId,
        reviewInput
      }
    })
    if (
      response.data.setCaptainDashboardReview &&
      response.data.setCaptainDashboardReview.status
    ) {
      return response.data.setCaptainDashboardReview.result
    } else {
      console.error(response.data.setCaptainDashboardReview.error)
      throw response.data.setCaptainDashboardReview.error
    }
  }
  static async withdrawAppWallet(amount, transactionNo) {
    const response = await graphqlAdmin.mutate({
      mutation: gql`
        mutation WITHDRAW_WALLET($amount: Float!, $transactionNo: String!) {
          withdrawFromAppWallet(amount: $amount, transactionNo: $transactionNo)
        }
      `,
      variables: {
        amount,
        transactionNo
      }
    })
    if (
      response.data.withdrawFromAppWallet &&
      response.data.withdrawFromAppWallet.status
    ) {
      return response.data.withdrawFromAppWallet.result
    } else {
      console.error(response.data.withdrawFromAppWallet.error)
      throw response.data.withdrawFromAppWallet.error
    }
  }
  static async rechargeAppWallet(amount) {
    const response = await graphqlAdmin.mutate({
      mutation: gql`
        mutation RECHARGE_WALLET($amount: Float!) {
          rechargeAppWallet(amount: $amount)
        }
      `,
      variables: {
        amount
      }
    })
    if (
      response.data.rechargeAppWallet &&
      response.data.rechargeAppWallet.status
    ) {
      return response.data.rechargeAppWallet.result
    } else {
      console.error(response.data.rechargeAppWallet.error)
      throw response.data.rechargeAppWallet.error
    }
  }
  static async removeClients(clientsIds) {
    const response = await graphqlClients.mutate({
      mutation: gql`
        mutation CLIENTS_REMOVE($clientsIds: [String]!) {
          removeClients(clientsIds: $clientsIds)
        }
      `,
      variables: {
        clientsIds
      }
    })
    if (response.data.removeClients && response.data.removeClients.status) {
      return response.data.removeClients.result
    } else {
      console.error(response.data.removeClients.error)
      throw response.data.removeClients.error
    }
  }

  static async enableClients(ids) {
    return this._changeClientStatus(ids, true)
  }

  static async disableClients(ids) {
    return this._changeClientStatus(ids, false)
  }

  static async _changeClientStatus(ids, enabled) {
    const response = await graphqlClients.mutate({
      mutation: gql`
        mutation CLIENTS_CHANGE_STATUS(
          $clientsIds: [String!]!
          $status: Boolean!
        ) {
          setClientsStatus(clientsIds: $clientsIds, status: $status)
        }
      `,

      variables: {
        clientsIds: ids,
        status: !!enabled
      }
    })

    if (
      response.data.setClientsStatus &&
      response.data.setClientsStatus.status
    ) {
      return response.data.setClientsStatus.result
    } else {
      console.error(response.data.setClientsStatus.error)
      throw response.data.setClientsStatus.error
    }
  }

  static async listClientAddresses(clientId) {
    const response = await graphqlClients.query({
      query: gql`
        query FIND_CLIENT($clientId: String!) {
          listClientAddresses(clientId: $clientId) ${Address}
        }
      `,
      variables: {
        clientId
      }
    })
    return response.data.listClientAddresses
  }

  static async fetchChefs(filter, pagination) {
    const response = await graphqlChefs.query({
      query: gql`
        query USER_LIST_CHEFS(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listChefs(filter: $filter, pagination: $pagination) ${Chef}
        }
      `,
      variables: {
        filter,
        pagination
      }
    })
    return response.data.listChefs
  }

  static async ListChefsReviews(chefId, filter, pagination) {
    const response = await graphqlChefs.query({
      query: gql`
        query USER_LIST_CHEFS(
          $chefId: String!
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listChefReviews(chefId: $chefId,  filter: $filter, pagination: $pagination) ${ChefReview}
        }
      `,
      variables: {
        filter,
        pagination,
        chefId
      }
    })
    return response.data.listChefReviews
  }

  static async findChef(chefId) {
    const response = await graphqlChefs.query({
      query: gql`
        query FIND_CHEF($chefId: String!) {
          findChefById(chefId: $chefId) ${Chef}
        }
      `,
      variables: {
        chefId
      }
    })
    return response.data.findChefById
  }

  static async findKitchen(chefId) {
    const response = await graphqlChefs.query({
      query: gql`
        query FIND_CHEF($chefId: String!) {
          findChefById(chefId: $chefId) ${kitchen}
        }
      `,
      variables: {
        chefId
      }
    })
    return response.data.findChefById
  }
  static async editKitchen(chefId, kitchenInput) {
    const response = await graphqlChefs.mutate({
      mutation: gql`
        mutation KITCHEN_EDIT($chefId: String!, $kitchenInput: KitchenInput!) {
          setKitchen(chefId: $chefId, kitchenInput: $kitchenInput)
        }
      `,
      variables: {
        chefId,
        kitchenInput
      }
    })
    if (response.data.setKitchen && response.data.setKitchen.status) {
      return response.data.setKitchen.result
    } else {
      console.error(response.data.setKitchen.error)
      throw response.data.setKitchen.error
    }
  }
  static async setSections(chefId, sections) {
    const response = await graphqlChefs.mutate({
      mutation: gql`
        mutation SECTIONS_EDIT($chefId: String!, $sections: [SectionInput]!) {
          setKitchenSections(chefId: $chefId, sections: $sections)
        }
      `,
      variables: {
        chefId,
        sections
      }
    })
    if (
      response.data.setKitchenSections &&
      response.data.setKitchenSections.status
    ) {
      return response.data.setKitchenSections.result
    } else {
      console.error(response.data.setKitchenSections.error)
      throw response.data.setKitchenSections.error
    }
  }
  static async findChefWallet(chefId) {
    const response = await graphqlChefs.query({
      query: gql`
        query FIND_CLIENT($chefId: String!) {
          findChefWallet(chefId: $chefId) ${ChefWallet}
        }
      `,
      variables: {
        chefId
      }
    })
    return response.data.findChefWallet
  }

  static async findChefAccounts(chefId) {
    const response = await graphqlChefs.query({
      query: gql`
        query FIND_CLIENT($chefId: String!) {
          listChefBankAccounts(chefId: $chefId) {
            bankName
            fullName
            iban
            id
            isDefault
            idNumber
          }
        }
      `,
      variables: {
        chefId
      }
    })
    return response.data.listChefBankAccounts
  }

  static async removeChefs(chefsIds) {
    const response = await graphqlChefs.mutate({
      mutation: gql`
        mutation CHEFS_REMOVE($chefsIds: [String]!) {
          removeChefs(chefsIds: $chefsIds)
        }
      `,
      variables: {
        chefsIds
      }
    })
    if (response.data.removeChefs && response.data.removeChefs.status) {
      return response.data.removeChefs.result
    } else {
      console.error(response.data.removeChefs.error)
      throw response.data.removeChefs.error
    }
  }

  static async enableChefs(ids) {
    return this._changeChefStatus(ids, true)
  }

  static async disableChefs(ids) {
    return this._changeChefStatus(ids, false)
  }

  static async _changeChefStatus(ids, enabled) {
    const response = await graphqlChefs.mutate({
      mutation: gql`
        mutation CHEFS_CHANGE_STATUS($chefsIds: [String!]!, $status: Boolean!) {
          setChefsStatus(chefsIds: $chefsIds, status: $status)
        }
      `,

      variables: {
        chefsIds: ids,
        status: !!enabled
      }
    })

    if (response.data.setChefsStatus && response.data.setChefsStatus.status) {
      return response.data.setChefsStatus.result
    } else {
      console.error(response.data.setChefsStatus.error)
      throw response.data.setChefsStatus.error
    }
  }

  static async fetchCaptains(filter, pagination) {
    const response = await graphqlCaptains.query({
      query: gql`
        query USER_LIST_CAPTAINS(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listCaptains(filter: $filter, pagination: $pagination) ${Captain}
        }
      `,
      variables: {
        filter,
        pagination
      }
    })
    return response.data.listCaptains
  }

  static async findCaptain(captainId) {
    const response = await graphqlCaptains.query({
      query: gql`
        query FIND_CAPTAINS($captainId: String!) {
          findCaptainById(captainId: $captainId) ${Captain}
        }
      `,
      variables: {
        captainId
      }
    })
    return response.data.findCaptainById
  }
  static async findCaptainWallet(captainId) {
    const response = await graphqlCaptains.query({
      query: gql`
        query FIND_CLIENT($captainId: String!) {
          findCaptainWallet(captainId: $captainId) ${CaptainWallet}
        }
      `,
      variables: {
        captainId
      }
    })
    return response.data.findCaptainWallet
  }

  static async findCaptainAccounts(captainId) {
    const response = await graphqlCaptains.query({
      query: gql`
        query FIND_CLIENT($captainId: String!) {
          listCaptainBankAccounts(captainId: $captainId) {
            bankName
            fullName
            iban
            id
            isDefault
            idNumber
          }
        }
      `,
      variables: {
        captainId
      }
    })
    return response.data.listCaptainBankAccounts
  }

  static async removeCaptains(captainsIds) {
    const response = await graphqlCaptains.mutate({
      mutation: gql`
        mutation CAPTAINS_REMOVE($captainsIds: [String]!) {
          removeCaptains(captainsIds: $captainsIds)
        }
      `,
      variables: {
        captainsIds
      }
    })
    if (response.data.removeCaptains && response.data.removeCaptains.status) {
      return response.data.removeCaptains.result
    } else {
      console.error(response.data.removeCaptains.error)
      throw response.data.removeCaptains.error
    }
  }

  static async enableCaptains(ids) {
    return this._changeCaptainStatus(ids, true)
  }

  static async disableCaptains(ids) {
    return this._changeCaptainStatus(ids, false)
  }

  static async _changeCaptainStatus(ids, enabled) {
    const response = await graphqlCaptains.mutate({
      mutation: gql`
        mutation CAPTAINS_CHANGE_STATUS(
          $captainsIds: [String!]!
          $status: Boolean!
        ) {
          setCaptainsStatus(captainsIds: $captainsIds, status: $status)
        }
      `,

      variables: {
        captainsIds: ids,
        status: !!enabled
      }
    })

    if (
      response.data.setCaptainsStatus &&
      response.data.setCaptainsStatus.status
    ) {
      return response.data.setCaptainsStatus.result
    } else {
      console.error(response.data.setCaptainsStatus.error)
      throw response.data.setCaptainsStatus.error
    }
  }

  static async decideChefAdd(chefsIds, acceptAdd) {
    const response = await graphqlChefs.mutate({
      mutation: gql`
        mutation CHEF_DECIDE_ADD($chefsIds: [String]!, $status: Boolean!) {
          setChefsStatus(chefsIds: $chefsIds, status: $status)
        }
      `,
      variables: {
        chefsIds,
        status: acceptAdd
      }
    })
    if (response.data.setChefsStatus && response.data.setChefsStatus.status) {
      return response.data.setChefsStatus.result
    } else {
      console.error(response.data.setChefsStatus.error)
      throw response.data.setChefsStatus.error
    }
  }

  static async decideCaptainAdd(captainsIds, acceptAdd) {
    const response = await graphqlCaptains.mutate({
      mutation: gql`
        mutation CAPTAIN_DECIDE_ADD(
          $captainsIds: [String]!
          $status: Boolean!
        ) {
          setCaptainsStatus(captainsIds: $captainsIds, status: $status)
        }
      `,
      variables: {
        captainsIds,
        status: acceptAdd
      }
    })
    if (
      response.data.setCaptainsStatus &&
      response.data.setCaptainsStatus.status
    ) {
      return response.data.setCaptainsStatus.result
    } else {
      console.error(response.data.setCaptainsStatus.error)
      throw response.data.setCaptainsStatus.error
    }
  }

  static async findAppWallet() {
    const response = await graphqlAdmin.query({
      query: gql`
        query FIND_APP_WALLET {
          findAppWallet ${AppWallet}
        }
      `
    })
    return response.data.findAppWallet
  }
}
