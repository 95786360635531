import GraphqlClient from '@/shared/graphql/client'
import gql from 'graphql-tag'
import config from '@/config'

const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlChef)

export default class PromotionService {
  static async list(filter = [], pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST_PROMOTION(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listPromotions(filter: $filter, pagination: $pagination) {
            id
            chef {
              id
              fullName
              code
              phoneNumber
              kitchen {
                kitchenName {
                  en
                  ar
                }
              }
            }
            daysCount
            amount
            status
            startDate
            endDate
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        filter,
        pagination
      }
    })
    return response.data.listPromotions
  }

  static async remove(promotionId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PROMOTION_DESTROY($promotionId: String!) {
          removePromotion(promotionId: $promotionId)
        }
      `,
      variables: {
        promotionId
      }
    })

    if (response.data.removePromotion && response.data.removePromotion.status) {
      return response.data.removePromotion.result
    } else {
      console.error(response.data.removePromotion.error)
      throw response.data.removePromotion.error
    }
  }
}
