<template>
  <div
    id="general"
    role="tabpanel"
    aria-labelledby="general-tab"
    class="tab-pane active"
  >
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <section class="relative">
            <div
              style="width: 100%; height: 50vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>

            <div v-if="model">
              <details open>
                <summary class="col-span-12 text-lg my-4 font-medium">
                  {{ i18n('settings.globalSetting') }}
                </summary>
                <div>
                  <div class="grid grid-cols-12">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-email" class="form-label">
                        {{ i18n('settings.settingsTab.emailAddress') }}
                      </label>
                      <input
                        id="form-email"
                        type="email"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.email"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-address" class="form-label">{{
                        i18n('settings.settingsTab.address')
                      }}</label>
                      <input
                        id="form-address"
                        type="text"
                        class="form-control dark:bg-dark-9"
                        v-model="model.address"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-phoneNumber" class="form-label">{{
                        i18n('settings.settingsTab.phoneNumber')
                      }}</label>
                      <input
                        id="form-phoneNumber"
                        type="tel"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.phoneNumber"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-whatsapp" class="form-label">{{
                        i18n('settings.settingsTab.whatsappNumber')
                      }}</label>
                      <input
                        id="form-whatsapp"
                        type="tel"
                        class="form-control dark:bg-dark-9"
                        v-model="model.whatsapp"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-instagram" class="form-label">
                        <app-i18n
                          code="settings.settingsTab.instagram"
                        ></app-i18n>
                      </label>
                      <input
                        id="form-instagram"
                        type="url"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.instagram"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-snapchat" class="form-label">
                        <app-i18n
                          code="settings.settingsTab.snapchat"
                        ></app-i18n>
                      </label>
                      <input
                        id="form-snapchat"
                        type="url"
                        class="form-control dark:bg-dark-9"
                        v-model="model.snapchat"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-vat" class="form-label">{{
                        i18n('settings.settingsTab.vat')
                      }}</label>
                      <input
                        id="form-vat"
                        type="number"
                        step="0.1"
                        min="0"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.vat"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-priceForKM" class="form-label">{{
                        i18n('settings.settingsTab.priceForKM')
                      }}</label>
                      <input
                        id="form-priceForKM"
                        type="number"
                        step="0.1"
                        min="0"
                        class="form-control dark:bg-dark-9"
                        v-model="model.priceForKM"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-minDeliveryFees" class="form-label">{{
                        i18n('settings.settingsTab.minDeliveryFees')
                      }}</label>
                      <input
                        id="form-minDeliveryFees"
                        type="number"
                        step="0.1"
                        min="0"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.minDeliveryFees"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-maxDeliveryFees" class="form-label">{{
                        i18n('settings.settingsTab.maxDeliveryFees')
                      }}</label>
                      <input
                        id="form-maxDeliveryFees"
                        type="number"
                        step="0.1"
                        min="0"
                        class="form-control dark:bg-dark-9"
                        v-model="model.maxDeliveryFees"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-chefRatio" class="form-label">{{
                        i18n('settings.settingsTab.chefRatio')
                      }}</label>
                      <input
                        id="form-chefRatio"
                        type="number"
                        step="0.1"
                        min="0"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.chefRatio"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-chefRadius" class="form-label">{{
                        i18n('settings.settingsTab.chefRadius')
                      }}</label>
                      <input
                        id="form-chefRadius"
                        type="number"
                        step="0.1"
                        min="0"
                        class="form-control dark:bg-dark-9"
                        v-model="model.chefRadius"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-captainRatio" class="form-label">{{
                        i18n('settings.settingsTab.captainRatio')
                      }}</label>
                      <input
                        id="form-captainRatio"
                        type="number"
                        step="0.1"
                        min="0"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.captainRatio"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-captainRadius" class="form-label">{{
                        i18n('settings.settingsTab.captainRadius')
                      }}</label>
                      <input
                        id="form-captainRadius"
                        type="number"
                        step="0.1"
                        min="0"
                        class="form-control dark:bg-dark-9"
                        v-model="model.captainRadius"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-deliveryTime" class="form-label">{{
                        i18n('settings.settingsTab.deliveryTime')
                      }}</label>
                      <input
                        id="form-deliveryTime"
                        type="number"
                        step="0.1"
                        min="0"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.deliveryTime"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-deliveryNotificationTime" class="form-label">{{
                        i18n('settings.settingsTab.deliveryNotificationTime')
                      }}</label>
                      <input
                        id="form-deliveryNotificationTime"
                        type="number"
                        step="0.1"
                        min="0"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.deliveryNotificationTime"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-promotionPrice" class="form-label">{{
                        i18n('settings.settingsTab.promotionPrice')
                      }}</label>
                      <input
                        id="form-promotionPrice"
                        type="number"
                        step="0.1"
                        min="0"
                        class="form-control dark:bg-dark-9"
                        v-model="model.promotionPrice"
                      />
                    </div>
                  </div>
                </div>
              </details>
              <details open>
                <summary class="col-span-12 text-lg my-4 font-medium">
                  {{ i18n('settings.ClientSetting') }}
                </summary>
                <div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-playStore" class="form-label">{{
                        i18n('settings.settingsTab.playStoreClient')
                      }}</label>
                      <input
                        id="form-playStore"
                        type="url"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.playStoreClient"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-appStore" class="form-label">{{
                        i18n('settings.settingsTab.appStoreClient')
                      }}</label>
                      <input
                        id="form-appStore"
                        type="url"
                        class="form-control dark:bg-dark-9"
                        v-model="model.appStoreClient"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-androidVersion" class="form-label">{{
                        i18n('settings.settingsTab.androidVersionClient')
                      }}</label>
                      <input
                        id="form-androidVersion"
                        type="url"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.androidVersionClient"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-IOSVersion" class="form-label">{{
                        i18n('settings.settingsTab.IOSVersionClient')
                      }}</label>
                      <input
                        id="form-IOSVersion"
                        type="url"
                        class="form-control dark:bg-dark-9"
                        v-model="model.IOSVersionClient"
                      />
                    </div>
                  </div>
                </div>
              </details>
              <details open>
                <summary class="col-span-12 text-lg my-4 font-medium">
                  {{ i18n('settings.ChefSetting') }}
                </summary>
                <div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-playStore" class="form-label">{{
                        i18n('settings.settingsTab.playStoreChef')
                      }}</label>
                      <input
                        id="form-playStore"
                        type="url"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.playStoreChef"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-appStore" class="form-label">{{
                        i18n('settings.settingsTab.appStoreChef')
                      }}</label>
                      <input
                        id="form-appStore"
                        type="url"
                        class="form-control dark:bg-dark-9"
                        v-model="model.appStoreChef"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-androidVersion" class="form-label">{{
                        i18n('settings.settingsTab.androidVersionChef')
                      }}</label>
                      <input
                        id="form-androidVersion"
                        type="url"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.androidVersionChef"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-IOSVersion" class="form-label">{{
                        i18n('settings.settingsTab.IOSVersionChef')
                      }}</label>
                      <input
                        id="form-IOSVersion"
                        type="url"
                        class="form-control dark:bg-dark-9"
                        v-model="model.IOSVersionChef"
                      />
                    </div>
                  </div>
                </div>
              </details>
              <details open>
                <summary class="col-span-12 text-lg my-4 font-medium">
                  {{ i18n('settings.CaptainSetting') }}
                </summary>
                <div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-playStore" class="form-label">{{
                        i18n('settings.settingsTab.playStoreCaptain')
                      }}</label>
                      <input
                        id="form-playStore"
                        type="url"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.playStoreCaptain"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-appStore" class="form-label">{{
                        i18n('settings.settingsTab.appStoreCaptain')
                      }}</label>
                      <input
                        id="form-appStore"
                        type="url"
                        class="form-control dark:bg-dark-9"
                        v-model="model.appStoreCaptain"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-12 mt-3">
                    <div
                      class="col-span-12 lg:col-span-6"
                      :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                    >
                      <label for="form-androidVersion" class="form-label">{{
                        i18n('settings.settingsTab.androidVersionCaptain')
                      }}</label>
                      <input
                        id="form-androidVersion"
                        type="url"
                        class="form-control w-full dark:bg-dark-9"
                        v-model="model.androidVersionCaptain"
                      />
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                      <label for="form-IOSVersion" class="form-label">{{
                        i18n('settings.settingsTab.IOSVersionCaptain')
                      }}</label>
                      <input
                        id="form-IOSVersion"
                        type="url"
                        class="form-control dark:bg-dark-9"
                        v-model="model.IOSVersionCaptain"
                      />
                    </div>
                  </div>
                </div>
              </details>
              <div
                class="flex justify-center items-center mt-10"
                v-if="savePermission"
              >
                <AppButton
                  type="button"
                  class="btn bg-theme-36 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { SettingModel } from '@/store/setting/setting-model'

const { fields } = SettingModel
const formSchema = new FormSchema([
  fields.email,
  fields.phoneNumber,
  fields.whatsapp,
  fields.address,
  fields.instagram,
  fields.snapchat,
  fields.playStoreClient,
  fields.appStoreClient,
  fields.playStoreChef,
  fields.appStoreChef,
  fields.playStoreCaptain,
  fields.appStoreCaptain,
  fields.chefRatio,
  fields.chefRadius,
  fields.captainRatio,
  fields.captainRadius,
  fields.deliveryTime,
  fields.deliveryNotificationTime,
  fields.minDeliveryFees,
  fields.maxDeliveryFees,
  fields.priceForKM,
  fields.promotionPrice,
  fields.vat,
  fields.androidVersionClient,
  fields.IOSVersionClient,
  fields.androidVersionChef,
  fields.IOSVersionChef,
  fields.androidVersionCaptain,
  fields.IOSVersionCaptain
])

export default {
  name: 'settings-general-tab',

  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      errorMessage: ''
    }
  },
  props: ['savePermission'],
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'setting/general/record',
      findLoading: 'setting/general/findLoading',
      saveLoading: 'setting/general/saveLoading'
    })
  },
  async mounted() {
    await this.doFind()
    this.model = formSchema.initialValues(this.record || {})
  },
  methods: {
    ...mapActions({
      doNew: 'setting/general/doNew',
      doFind: 'setting/general/doFind',
      doUpdate: 'setting/general/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (const key in this.model) {
        const value = this.model[key]
        if (value.length == 0) {
          this.errorMessage = 'settings.settingsTab.errors.emptyFields'
          return false
        }
      }
      const regexNum = /^\d+(\.\d+)?([eE][+-]?\d+)?$/
      if (!regexNum.test(this.model.androidVersionClient)) {
        this.errorMessage = 'settings.settingsTab.errors.appVersion'
        return false
      }
      if (!regexNum.test(this.model.IOSVersionClient)) {
        this.errorMessage = 'settings.settingsTab.errors.appVersion'
        return false
      }
      if (!regexNum.test(this.model.androidVersionChef)) {
        this.errorMessage = 'settings.settingsTab.errors.appVersion'
        return false
      }
      if (!regexNum.test(this.model.IOSVersionChef)) {
        this.errorMessage = 'settings.settingsTab.errors.appVersion'
        return false
      }
      if (!regexNum.test(this.model.androidVersionCaptain)) {
        this.errorMessage = 'settings.settingsTab.errors.appVersion'
        return false
      }
      if (!regexNum.test(this.model.IOSVersionCaptain)) {
        this.errorMessage = 'settings.settingsTab.errors.appVersion'
        return false
      }
      if (!regexNum.test(this.model.androidVersionClient)) {
        this.errorMessage = 'settings.settingsTab.errors.appVersion'
        return false
      }
      return true
    },
    doCancel() {
      this.$router.push('/')
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      const {
        vat,
        chefRatio,
        chefRadius,
        captainRadius,
        deliveryTime,
        captainRatio,
        minDeliveryFees,
        maxDeliveryFees,
        promotionPrice,
        priceForKM,
        ...values
      } = formSchema.cast(this.model)
      await this.doUpdate({
        vat: parseFloat(vat),
        chefRatio: parseFloat(chefRatio),
        chefRadius: parseInt(chefRadius),
        deliveryTime: parseInt(deliveryTime),
        captainRadius: parseInt(captainRadius),
        promotionPrice: parseFloat(promotionPrice),
        captainRatio: parseFloat(captainRatio),
        minDeliveryFees: parseFloat(minDeliveryFees),
        maxDeliveryFees: parseFloat(maxDeliveryFees),
        priceForKM: parseFloat(priceForKM),
        ...values
      })
    }
  }
}
</script>
