import { createStore } from 'vuex'
import main from '@/store/main'
import sideMenu from '@/store/side-menu'
import auth from '@/store/auth/auth-store'
import layout from '@/store/layout-store'
import user from '@/store/user/user-store'
import admin from '@/store/admin/admin-store'
import realtime from '@/store/realtime-store'
import vehicle from '@/store/vehicle/vehicle-store'
import slider from '@/store/slider/slider-store'
import setting from '@/store/setting/setting-store'
import notification from '@/store/notification/notification-store'
import complaintStore from '@/store/complaint/complaint-store'
import category from '@/store/category/category-store'
import reply from '@/store/reply/reply-store'
import request from '@/store/request/request-store'
import transaction from '@/store/transaction/transaction-store'
import review from '@/store/review/review-store'

import meal from '@/store/meal/meal-store'
import order from '@/store/order/order-store'
import report from '@/store/report/report-store'
import dashboard from '@/store/dashboard/dashboard-store'
import promotion from '@/store/promotion/promotion-store'
import wallet from '@/store/wallet/wallet-store'
import exportStore from '@/store/export'

const store = createStore({
  modules: {
    main,
    sideMenu,
    layout,
    auth,
    user,
    admin,
    realtime,
    vehicle,
    slider,
    reply,
    setting,
    category,
    complaintStore,
    notification,
    request,
    transaction,
    wallet,
    meal,
    order,
    report,
    dashboard,
    promotion,
    exportStore,
    review
  }
})

export function useStore() {
  return store
}

export default store
