/* eslint-disable */
import walletListStore from '@/store/wallet/wallet-list-store';

export default {
  namespaced: true,

  modules: {
    list: walletListStore
  },
};
