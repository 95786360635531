/* eslint-disable */
import UserService from '@/store/user/user-service'
import { state, getters, mutations, actions } from '@/store/list-utils'

export default {
  namespaced: true,
  state: {
    ...state,
    chefId: null
  },
  getters: {
    ...getters,
    chefId: state => state.chefId
  },
  mutations: {
    ...mutations,
    UPDATE_CHEF_ID(state, chefId) {
      state.chefId = chefId || state.chefId
    }
  },
  actions: {
    async doChangePaginationPageSize({ commit, getters }, pageSize) {
      await actions.changePageSize({ commit, getters, pageSize })
    },
    doChangePaginationCurrentPage({ commit }, currentPage) {
      actions.changeCurrentPage({ commit, page: currentPage })
    },
    async doFetchNextPage({ commit, getters }) {
      await actions.fetchNext({ commit, getters })
    },
    async doFetchPreviousPage({ commit, getters }) {
      await actions.fetchPrevious({ commit, getters })
    },
    async doFetch(
      { commit, getters },
      { chefId, filter, pagination, keepPagination = true } = {}
    ) {
      console.log(UserService.ListChefsReviews, getters.chefId)
      commit('UPDATE_CHEF_ID', chefId)
      await actions.fetch({
        commit,
        getters,
        list: UserService.ListChefsReviews.bind(null, getters.chefId),
        filter,
        pagination,
        keepPagination
      })
    }
  }
}
