import config from '@/config'
import Errors from '@/shared/error/errors'

const url = config.backendUrlExport

const state = {
  loading: false
}

const getters = {
  loading: state => !!state.loading
}

const mutations = {
  EXPORT_STARTED(state) {
    state.loading = true
  },
  EXPORT_SUCCESS(state) {
    state.loading = false
  }
}

const actions = {
  async doExport({ commit }, { resource, filter }) {
    try {
      commit('EXPORT_STARTED')
      const filterParams = filter ? `&filter=${JSON.stringify(filter)}` : ''
      const file = await (
        await fetch(`${url}?resource=${resource}${filterParams}`)
      ).blob()
      const fileUrl = URL.createObjectURL(file)
      const fileLink = document.createElement('a')
      fileLink.href = fileUrl
      fileLink.setAttribute('download', `${resource}.xlsx`)
      document.body.appendChild(fileLink)
      fileLink.click()
      document.body.removeChild(fileLink)
      URL.revokeObjectURL(fileUrl)
      commit('EXPORT_SUCCESS')
    } catch (error) {
      Errors.handle(error)
      commit('EXPORT_SUCCESS')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
