import adminListStore from '@/store/admin/admin-list-store'
import adminFormStore from '@/store/admin/admin-form-store'
import adminDestroyStore from '@/store/admin/admin-destroy-store'

export default {
  namespaced: true,
  modules: {
    list: adminListStore,
    form: adminFormStore,
    destroy: adminDestroyStore
  }
}
