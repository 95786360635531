/* eslint-disable */
import UserService from '@/store/user/user-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import { state, getters, mutations, actions } from '@/store/list-utils'

export default {
  namespaced: true,

  state: {
    ...state,
    clients: [],
    chefs: [],
    captains: [],
    type: null
  },

  getters: {
    ...getters,
    clients: state => state.clients || [],
    chefs: state => state.chefs || [],
    captains: state => state.captains || [],
    latestUsers: state => ({
      client: state.clients,
      chef: state.chefs,
      captain: state.captains
    }),
    type: state => state.type
  },

  mutations: {
    ...mutations,
    LATEST_ADD_CLIENTS(state, payload) {
      state.clients = payload
    },
    LATEST_ADD_CHEFS(state, payload) {
      state.chefs = payload
    },
    LATEST_ADD_CAPTAINS(state, payload) {
      state.captains = payload
    },
    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true
    },
    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false
    },
    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      state.loading = false
    },
    UPDATE_USER_TYPE(state, payload) {
      state.type = payload || state.type
    }
  },
  actions: {
    async doSetUserType({ commit }, type) {
      commit('UPDATE_USER_TYPE', type)
    },
    async doChangePaginationPageSize({ commit, getters }, pageSize) {
      await actions.changePageSize({ commit, getters, pageSize })
    },
    doChangePaginationCurrentPage({ commit }, currentPage) {
      actions.changeCurrentPage({ commit, page: currentPage })
    },
    async doFetchNextPage({ commit, getters }) {
      await actions.fetchNext({ commit, getters })
    },
    async doFetchPreviousPage({ commit, getters }) {
      await actions.fetchPrevious({ commit, getters })
    },
    async doFetch(
      { commit, getters },
      { filter, pagination, type, keepPagination = true } = {}
    ) {
      commit('UPDATE_USER_TYPE', type)
      const userServiceMap = {
        client: UserService.fetchClients,
        chef: UserService.fetchChefs,
        captain: UserService.fetchCaptains
      }
      const selectedUserService = userServiceMap[type]
      const response =
        selectedUserService &&
        (await actions.fetch({
          commit,
          getters,
          list: selectedUserService,
          filter,
          pagination,
          keepPagination
        }))
      commit(`LATEST_ADD_${type.toUpperCase()}S`, response)
    },
    async doDisableAllSelected({ getters, commit, dispatch }, ids) {
      try {
        commit('CHANGE_STATUS_SELECTED_STARTED')
        const type = getters.type
        type === 'client'
          ? await UserService.disableClients(ids)
          : type === 'chef'
          ? await UserService.disableChefs(ids)
          : await UserService.disableCaptains(ids)
        commit('CHANGE_STATUS_SELECTED_SUCCESS')
        Message.success(i18n('iam.doDisableAllSuccess'))
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type
        })
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type: getters.type
        })
      }
    },
    async doEnableAllSelected({ commit, getters, dispatch }, ids) {
      try {
        commit('CHANGE_STATUS_SELECTED_STARTED')
        const type = getters.type
        type === 'client'
          ? await UserService.enableClients(ids)
          : type === 'chef'
          ? await UserService.enableChefs(ids)
          : await UserService.enableCaptains(ids)
        commit('CHANGE_STATUS_SELECTED_SUCCESS')
        Message.success(i18n('iam.doEnableAllSuccess'))
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type
        })
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type: getters.type
        })
      }
    }
  }
}
