/* eslint-disable */
import HelperClass from '@/shared/helperClass'
import _get from 'lodash/get'
import en from '@/locales/en.json'
import ar from '@/locales/ar.json'
import vueI18n from '@/vueI18n'
import { getFirestore, doc, updateDoc } from 'firebase/firestore'
import store from '@/store'

let currentLanguageCode = null

const languages = {
  en: {
    id: 'en',
    label: 'English',
    flag: '',
    dictionary: en
  },
  ar: {
    id: 'ar',
    label: 'العربية',
    flag: '',
    dictionary: ar
  }
}

async function init() {
  try {
    const currentUser = HelperClass.fetchLocalCurrentUser()
    const currentUserLanguage = currentUser ? currentUser.lang : null
    const languageInStorage = localStorage.getItem('language')

    currentLanguageCode = currentUserLanguage || languageInStorage || 'ar'

    if (currentUserLanguage !== currentLanguageCode) {
      currentUser.lang = currentLanguageCode
      HelperClass.saveLocalCurrentUser(currentUser)
      const docRef = doc(getFirestore(), 'admins', currentUser.id)
      updateDoc(docRef, {
        lang: currentLanguageCode
      })
    }

    if (languageInStorage !== currentLanguageCode) {
      localStorage.setItem('language', currentLanguageCode)
    }
    setLanguageCode(currentLanguageCode, true)
  } catch (err) {}
}

function format(message, args) {
  if (!message) {
    return null
  }

  try {
    return message.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match
    })
  } catch (error) {
    console.error(message, error)
    throw error
  }
}

function setLanguageCode(arg, updatedCurrentUser = false) {
  try {
    if (!languages && !languages[arg]) {
      throw new Error(`Invalid language ${arg}.`)
    }

    currentLanguageCode = arg
    vueI18n.global.locale = arg
    localStorage.setItem('language', arg)
    store.dispatch('layout/changeCurrentLanguageCode', arg)

    if (!updatedCurrentUser) {
      const currentUser = HelperClass.fetchLocalCurrentUser()

      if (currentUser && currentUser.lang !== currentLanguageCode) {
        currentUser.lang = currentLanguageCode
        HelperClass.saveLocalCurrentUser(currentUser)
        const docRef = doc(getFirestore(), 'admins', currentUser.id)
        updateDoc(docRef, {
          lang: currentLanguageCode
        })
      }
    }
  } catch (error) {}
}

function getLanguageCode() {
  if (!currentLanguageCode) {
    init()
  }
  return currentLanguageCode
}

function getLanguage() {
  return languages[getLanguageCode()]
}

function getLanguages() {
  return Object.keys(languages).map(language => {
    return languages[language]
  })
}

function i18nExists(key) {
  const message = _get(getLanguage().dictionary, key)
  return !!message
}

function i18n(key, ...args) {
  const message = vueI18n.global.t(key, args)

  if (!message) {
    return key
  }

  return format(message, args)
}

function isRTL() {
  return getLanguageCode() === 'ar'
}

export {
  setLanguageCode,
  getLanguageCode,
  getLanguages,
  i18nExists,
  i18n,
  isRTL
}
