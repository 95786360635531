import config from '@/config'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getDatabase } from 'firebase/database'
// import { getAnalytics } from 'firebase/analytics'
// import { getCrashlytics } from 'firebase/crashlytics'

// let crashlytics
const firebaseInit = () => {
  const app = initializeApp(config.firebaseConfig)
  getAuth(app)
  getFirestore(app)
  getStorage(app)
  getDatabase(app)
  // getAnalytics(app)
  // crashlytics = getCrashlytics(app)
}
// export { crashlytics }
export default firebaseInit
