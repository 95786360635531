<template>
  <!-- BEGIN: Top Bar -->
  <div
    class="top-bar-boxed border-b border-theme-2 -mt-7 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 md:pt-0 mb-12"
  >
    <div class="h-full flex items-center">
      <!-- BEGIN: Logo -->
      <a href="" class="-intro-x hidden md:flex">
        <img src="@/assets/images/homefood-logo.png" class="w-16 p-1" />
      </a>
      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <div
        class="-intro-x breadcrumb"
        :class="{
          'ml-auto': isRTL,
          'mr-auto': !isRTL
        }"
      >
        <router-link to="/"
          ><app-i18n code="breadcrumbs.application"
        /></router-link>
        <span
          v-for="breadcrumb in breadcrumbs"
          :key="breadcrumb"
          class="flex items-center"
        >
          <ChevronRightIcon class="breadcrumb__icon" v-if="!isRTL" />
          <ChevronLeftIcon class="breadcrumb__icon" v-else />
          <span
            class="breadcrumb--active"
            style="cursor: pointer;"
            @click="$router.push({ name: breadcrumb })"
            ><app-i18n :code="`breadcrumb.${breadcrumb}`"
          /></span>
        </span>
      </div>
      <!-- END: Breadcrumb -->
      <div>
        <i18nFlags class="i18nFlags mx-4" />
      </div>
      <!-- BEGIN: Account Menu -->
      <div class="dropdown">
        <button class="dropdown-toggle" aria-expanded="false">
          <div
            class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 mr-4 ml-4"
            role="button"
            aria-expanded="false"
            @click="showAccountDropDown = true"
          >
            <img
              alt="Icewall Tailwind HTML Admin Template"
              :src="
                currentUser.avatar
                  ? currentUser.avatar
                  : '/images/default-avatar.svg'
              "
            />
          </div>
        </button>
        <div class="dropdown-menu" v-if="showAccountDropDown">
          <div
            class="dropdown-menu__content profileDropDown box bg-theme-11 dark:bg-dark-6 text-white"
          >
            <div class="p-4 border-b border-theme-12 dark:border-dark-3">
              <div class="font-medium">{{ currentUser?.userName }}</div>
            </div>
            <div class="p-2">
              <div
                class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer"
                @click="goToProfile"
              >
                <UserIcon class="w-4 h-4" :class="isRTL ? 'ml-2' : 'mr-2'" />
                {{ i18n('topbar.profile.profile') }}
              </div>
            </div>
            <div class="p-2 border-t border-theme-12 dark:border-dark-3">
              <div
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer dropdown-toggle"
                @click="doLogout"
              >
                <ToggleRightIcon
                  class="w-4 h-4"
                  :class="isRTL ? 'ml-2' : 'mr-2'"
                />
                {{ i18n('topbar.profile.logout') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Account Menu -->
    </div>
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      currentUser: 'auth/currentUser'
    }),
    isMobile() {
      return screen.width <= 425
    },
    breadcrumbs() {
      const routes = this.$route.name.split('-').filter(name => name !== '')
      let routeName = ''
      return routes.map((route, idx) => {
        routeName += `${idx ? '-' : ''}${route}`
        return routeName
      })
    },
    pageTitle() {
      var currentPage = this.$route.name
      if (currentPage.includes('dashboard')) return 'Dashboard'
      if (currentPage.includes('admins')) return 'Admins'
      if (currentPage.includes('Complaints')) return 'Complaints'
      if (currentPage.includes('admin')) return 'Admins'
      if (currentPage.includes('client')) return 'clients'
      if (currentPage.includes('captain')) return 'captains'
      if (currentPage.includes('chef')) return 'Chefs'
      if (currentPage.includes('category')) return 'Categories'
      if (currentPage.includes('slider')) return 'Slider'
      if (currentPage.includes('orders')) return 'Orders'
      if (currentPage.includes('orderView')) return 'Orders'
      if (currentPage.includes('vehicles')) return 'Vehicles'
      if (currentPage.includes('settings')) return 'Settings'
      if (currentPage.includes('payment')) return 'Payments'
      if (currentPage.includes('wallet')) return 'Wallet'
      if (currentPage.includes('payments')) return 'Payments'
      if (currentPage.includes('profile')) return 'Profile'
      if (currentPage.includes('promotions')) return 'Promotions'
      if (currentPage.includes('reports')) return 'Reports'
      if (currentPage.includes('notification')) return 'Notifications'
      if (currentPage.includes('transaction')) return 'Transactions'
      if (currentPage.includes('change-password')) return 'Change Password'
      if (currentPage.includes('walletView')) return 'Wallet'
      return ''
    }
  },
  setup() {
    const searchDropdown = ref(false)
    const showAccountDropDown = ref(false)
    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    return {
      showAccountDropDown,
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown
    }
  },
  methods: {
    ...mapActions({
      doSignout: 'auth/doSignout'
    }),
    goToProfile() {
      this.$router.push('/profile')
    },
    doLogout() {
      this.showAccountDropDown = false
      this.doSignout()
    },
    goToUsers() {
      this.$router.push('/users')
    },
    goToChefs() {
      this.$router.push('/chef')
    },
    goToWallet() {
      this.$router.push('/wallet')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');
.i18nFlags {
  @media only screen and (max-width: 425px) {
    display: none;
  }
}
.searchDropdown {
  width: 200px !important;
  position: absolute !important;
  right: 0 !important;
}
[dir='rtl'] .searchDropdown {
  right: unset !important;
  left: 0 !important;
}

.notificationDropDown {
  width: 300px !important;
  position: absolute !important;
  right: 0 !important;
}
[dir='rtl'] .notificationDropDown {
  right: unset !important;
  left: 0 !important;
}

.profileDropDown {
  width: 200px !important;
  position: absolute !important;
  right: 0 !important;
}
[dir='rtl'] .profileDropDown {
  right: unset !important;
  left: -20px !important;
}
</style>
