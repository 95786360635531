/* eslint-disable */ import { SliderService } from '@/store/slider/slider-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'
import { collection, doc, getFirestore } from 'firebase/firestore'
import { FileUploader } from '@/shared/uploader/file-uploader'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },
    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    CREATE_STARTED(state) {
      state.saveLoading = true
    },
    CREATE_SUCCESS(state) {
      state.saveLoading = false
    },
    CREATE_ERROR(state) {
      state.saveLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },
    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await SliderService.find(id)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/slider')
      }
    },

    async doCreate({ commit }, { values, imageEn, imageAr }) {
      try {
        commit('CREATE_STARTED')
        const id = doc(collection(getFirestore(), 'ids')).id

        // [1] Upload images to storage of firebase
        const path = `sliders/${id}`
        // const { publicUrl } = await FileUploader.upload(path, image, id)
        const imgEN = await FileUploader.upload(path, imageEn, 'slider-en')
        const imgAR = await FileUploader.upload(path, imageAr, 'slider-ar')

        // [2] Create Slider
        await SliderService.create({
          ...values,
          id,
          imageEn: imgEN.publicUrl,
          imageAr: imgAR.publicUrl
        })
        commit('CREATE_SUCCESS')

        Message.success(i18n('slider.create.success'))
        router.push('/slider')
      } catch (error) {
        Errors.handle(error)
        commit('CREATE_ERROR')
      }
    },

    async doUpdate({ commit }, { id, values, imageEn, imageAr }) {
      try {
        commit('UPDATE_STARTED')
        // [1] Upload images to storage of firebase
        const path = `sliders/${id}`
        // const { publicUrl } = await FileUploader.upload(path, image, id)
        const imgEN =
          typeof imageEn === 'object'
            ? await FileUploader.upload(path, imageEn, 'slider-en')
            : imageEn
        const imgAR =
          typeof imageAr === 'object'
            ? await FileUploader.upload(path, imageAr, 'slider-ar')
            : imageAr

        // [2] Update Slider
        // values['image'] = publicUrl
        values['imageEn'] = imgEN?.publicUrl || imgEN
        values['imageAr'] = imgAR?.publicUrl || imgAR
        await SliderService.update(id, values)
        commit('UPDATE_SUCCESS')

        Message.success(i18n('slider.update.success'))
        router.push('/slider')
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }
  }
}
