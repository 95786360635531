/* eslint-disable */
import PermissionChecker from '@/security/permission-checker';

export class SettingsPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    // this.readGeneral = permissionChecker.match('viewGeneralSettings');
    this.readGeneral = permissionChecker.match('viewSettings');
    this.readPolicy = permissionChecker.match('viewPolicySettings');
    this.createGeneral = permissionChecker.match('createGeneralSettings');
    this.createPolicy = permissionChecker.match('createPolicySetting');
  }
}
