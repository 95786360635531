/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlChef = GraphqlClient.initApolloClient(config.backendUrlChef)
const graphqlCaptain = GraphqlClient.initApolloClient(config.backendUrlCaptain)

export class RequestService {
  //#region [ Mutation APIs ]
  /**
   * Accept Request
   * @param {String} id
   * @param {DateTime} dueDate
   * @param {String} notes
   * @returns
   */
  static async accept(id, dueDate, notes, type) {
    return type === 'chef'
      ? this._changeChefStatus(id, true, dueDate, notes)
      : this._changeCaptainStatus(id, true, dueDate, notes)
  }

  /**
   * Reject Request
   * @param {String} id
   * @param {String} notes
   * @returns
   */
  static async reject(id, notes, type) {
    return type === 'chef'
      ? this._changeChefStatus(id, false, null, notes)
      : this._changeCaptainStatus(id, false, null, notes)
  }

  /**
   * Accept / Reject the request
   * - decideWithdrawRequest(requestId: String!, acceptRequest: Boolean!, transactionTimeStamp: DateTime): JSON!
   * @param {String} id
   * @param {Boolean} accepted
   * @param {DateTime} dueDate
   * @param {String} notes
   * @returns
   */
  static async _changeChefStatus(id, accepted, dueDate, notes) {
    const response = await graphqlChef.mutate({
      mutation: gql`
        mutation REQUEST_DECIDE_WITHDRAW(
          $requestId: String!
          $acceptRequest: Boolean!
          $transactionTimeStamp: DateTime
          $notes: String
        ) {
          decideWithdrawRequestChef(
            requestId: $requestId
            acceptRequest: $acceptRequest
            transactionTimeStamp: $transactionTimeStamp
            notes: $notes
          )
        }
      `,
      variables: {
        requestId: id,
        acceptRequest: !!accepted,
        transactionTimeStamp: dueDate,
        notes: notes
      }
    })
    if (
      response.data.decideWithdrawRequestChef &&
      response.data.decideWithdrawRequestChef.status
    ) {
      return response.data.decideWithdrawRequestChef.result
    } else {
      console.error(response.data.decideWithdrawRequestChef.error)
      throw response.data.decideWithdrawRequestChef.error
    }
  }
  static async _changeCaptainStatus(id, accepted, dueDate, notes) {
    const response = await graphqlCaptain.mutate({
      mutation: gql`
        mutation REQUEST_DECIDE_WITHDRAW(
          $requestId: String!
          $acceptRequest: Boolean!
          $transactionTimeStamp: DateTime
          $notes: String
        ) {
          decideWithdrawRequestCaptain(
            requestId: $requestId
            acceptRequest: $acceptRequest
            transactionTimeStamp: $transactionTimeStamp
            notes: $notes
          )
        }
      `,
      variables: {
        requestId: id,
        acceptRequest: !!accepted,
        transactionTimeStamp: dueDate,
        notes: notes
      }
    })
    if (
      response.data.decideWithdrawRequestCaptain &&
      response.data.decideWithdrawRequestCaptain.status
    ) {
      return response.data.decideWithdrawRequestCaptain.result
    } else {
      console.error(response.data.decideWithdrawRequestCaptain.error)
      throw response.data.decideWithdrawRequestCaptain.error
    }
  }

  /**
   * Confirm the transfer request and close this request
   * - confirmWithdrawRequest(requestId: String!, transactionId: String!): JSON!
   * @param {String} id
   * @param {String} transactionId
   * @returns
   */
  static async closeChefRequest(id, transactionId, transactionUrl) {
    const response = await graphqlChef.mutate({
      mutation: gql`
        mutation REQUEST_CONFIRM(
          $requestId: String!
          $transactionId: String!
          $transactionUrl: String!
        ) {
          confirmWithdrawRequestChef(
            requestId: $requestId
            transactionId: $transactionId
            transactionUrl: $transactionUrl
          )
        }
      `,
      variables: {
        requestId: id,
        transactionId: transactionId,
        transactionUrl: transactionUrl
      }
    })
    if (
      response.data.confirmWithdrawRequestChef &&
      response.data.confirmWithdrawRequestChef.status
    ) {
      return response.data.confirmWithdrawRequestChef.result
    } else {
      console.error(response.data.confirmWithdrawRequestChef.error)
      throw response.data.confirmWithdrawRequestChef.error
    }
  }
  static async closeCaptainRequest(id, transactionId, transactionUrl) {
    const response = await graphqlCaptain.mutate({
      mutation: gql`
        mutation REQUEST_CONFIRM(
          $requestId: String!
          $transactionId: String!
          $transactionUrl: String!
        ) {
          confirmWithdrawRequestCaptain(
            requestId: $requestId
            transactionId: $transactionId
            transactionUrl: $transactionUrl
          )
        }
      `,
      variables: {
        requestId: id,
        transactionId: transactionId,
        transactionUrl: transactionUrl
      }
    })
    if (
      response.data.confirmWithdrawRequestCaptain &&
      response.data.confirmWithdrawRequestCaptain.status
    ) {
      return response.data.confirmWithdrawRequestCaptain.result
    } else {
      console.error(response.data.confirmWithdrawRequestCaptain.error)
      throw response.data.confirmWithdrawRequestCaptain.error
    }
  }

  /**
   * Retrieve requests with pagination
   * - listRequests(filter: [Filter]!, pagination: PaginationInput!): [Request]!
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith'} filter.operator
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns {Promise<Request[]>}
   */
  static async listChefRequests(filter, pagination) {
    const response = await graphqlChef.query({
      query: gql`
        query REQUEST_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listChefsRequests(filter: $filter, pagination: $pagination) {
            id
            type
            description
            amount
            transId
            ownerId
            status
            chef {
              fullName
              phoneNumber
              code
              kitchen {
                kitchenName {
                  en
                  ar
                }
              }
            }
            userType
            bankAccountId
            bankAccount {
              id
              bankName
              iban
              fullName
              isDefault
              idNumber
            }
            transactionTimeStamp
            transactionId
            transactionUrl
            notes
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        filter,
        pagination
      }
    })

    return response.data.listChefsRequests
  }
  static async listCaptainsRequests(filter, pagination) {
    const response = await graphqlCaptain.query({
      query: gql`
        query REQUEST_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listCaptainsRequests(filter: $filter, pagination: $pagination) {
            id
            type
            description
            captain {
              fullName
              phoneNumber
              code
            }
            amount
            transId
            ownerId
            status
            userType
            bankAccountId
            bankAccount {
              id
              bankName
              iban
              fullName
              isDefault
              idNumber
            }
            transactionTimeStamp
            transactionId
            transactionUrl
            notes
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        filter,
        pagination
      }
    })

    return response.data.listCaptainsRequests
  }
  //#endregion
}
