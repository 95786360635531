import Permissions from '@/security/permissions'

const state = () => {
  return {
    menu: [
      {
        icon: 'solar:home-linear',
        pageName: 'dashboard',
        title: 'Dashboard'
      },
      {
        icon: 'eos-icons:admin-outlined',
        pageName: 'admins',
        title: 'Admins',
        permission: Permissions.values.adminRead
      },
      {
        icon: 'tabler:users',
        pageName: 'clients',
        title: 'clients',
        permission: Permissions.values.clientRead
      },
      {
        icon: 'game-icons:captain-hat-profile',
        pageName: 'captains',
        title: 'captains',
        permission: Permissions.values.captainRead
      },
      {
        icon: 'solar:chef-hat-outline',
        pageName: 'chefs',
        title: 'Chefs',
        permission: Permissions.values.chefRead
      },
      {
        icon: 'mdi:food-outline',
        pageName: 'meals',
        title: 'Meals',
        permission: Permissions.values.chefRead
      },
      {
        icon: 'icon-park-solid:layers',
        pageName: 'category',
        title: 'Categories',
        permission: Permissions.values.categoryRead
      },
      {
        icon: 'material-symbols:orders-outline',
        pageName: 'orders',
        title: 'Orders',
        permission: Permissions.values.orderRead
      },
      {
        icon: 'iconoir:wallet',
        pageName: 'wallet',
        title: 'Wallet',
        permission: Permissions.values.requestRead
      },
      {
        icon: 'akar-icons:wallet',
        pageName: 'appWallet',
        title: 'AppWallet',
        permission: Permissions.values.appWalletRead
      },
      {
        icon: 'streamline:payment-10',
        pageName: 'payment',
        title: 'Payments',
        permission: Permissions.values.requestRead
      },
      {
        icon: 'mingcute:notification-line',
        pageName: 'notification',
        title: 'Notifications',
        permission: Permissions.values.notificationRead
      },
      {
        icon: 'carbon:report',
        pageName: 'reports',
        title: 'Reports',
        permission: Permissions.values.reportRead
      },
      {
        icon: 'teenyicons:gift-outline',
        pageName: 'promotions',
        title: 'Promotions',
        permission: Permissions.values.promotionsRead
      },
      {
        icon: 'mdi:all-terrain-vehicle',
        pageName: 'vehicles',
        title: 'Vehicles',
        permission: Permissions.values.vehicleRead
      },
      {
        icon: 'solar:slider-vertical-line-duotone',
        pageName: 'slider',
        title: 'Slider',
        permission: Permissions.values.sliderRead
      },
      {
        icon: 'bi:chat',
        pageName: 'complaint',
        title: 'Complaints',
        permission: Permissions.values.complaintRead,
        subMenu: [
          {
            icon: 'ClientIcon',
            pageName: 'clientsComplaints',
            title: 'ClientComplaints'
          },
          {
            icon: 'ChefIcon',
            pageName: 'chefsComplaints',
            title: 'ChefComplaints'
          },
          {
            icon: 'CaptainIcon',
            pageName: 'captainsComplaints',
            title: 'CaptainComplaints'
          }
        ]
      },
      {
        icon: 'lets-icons:setting-line',
        pageName: 'settings',
        title: 'Settings',
        permission: Permissions.values.settingRead
      },
      'devider'
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
