<template>
  <div>
    <div
      class="
        intro-y
        flex flex-wrap
        sm:flex-row sm:flex-nowrap
        items-center
        mt-3
        py-3
        px-0
        sm:px-3
      "
      :style="`justify-content: ${showSizeChanger ? 'center' : position}`"
    >
      <div
        class="flex items-center gap-3"
        :class="showSizeChanger ? 'pagination' : ''"
      >
        <button
          class="
            btn
            btn-secondary
            text-black
            sm:w-auto
            py-1
            text-xs
            sm:text-sm
            cursor-pointer
          "
          :disabled="isFirstPage || disabled || loading"
          @click="$emit('prev-change')"
          style="background-color: #edf2f7"
        >
          {{ i18n('common.previous') }}
        </button>

        <span class="block whitespace-nowrap text-xs sm:text-sm"
          >{{ i18n('common.page') }}
          <input
            class="w-8 outline-none text-center box"
            @keydown.enter.exact.prevent="$emit('page-change', pageNum)"
            type="number"
            v-model="pageNum"
        /></span>

        <button
          class="btn bg-theme-36 text-white sm:w-auto py-1 text-xs sm:text-sm cursor-pointer"
          :disabled="isLastPage || disabled || loading"
          @click="$emit('next-page')"
        >
          {{ i18n('common.next') }}
        </button>
      </div>

      <div class="flex items-center gap-3 mt-3 sm:mt-0">
        <app-i18n
          code="common.recordsPerPage"
          :args="[':']"
          class="text-xs sm:text-sm"
          v-if="showSizeChanger"
        ></app-i18n>
        <select
          class="w-20 form-select box"
          v-model="limit"
          @change="$emit('size-change', limit)"
          v-if="showSizeChanger"
        >
          <option v-for="limit in limits" :key="limit" :value="limit">
            {{ limit }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    rowsNumber: {
      type: Number
    },
    disabled: {
      type: Boolean
    },
    showSizeChanger: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: 'center'
    },
    hasNext: {
      type: Boolean
    },
    hasPrev: {
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      size: this.pageSize,
      pageNum: this.page,
      defaultLimits: [5, 10, 15, 20, 30, 40, 50]
    }
  },
  watch: {
    page(val) {
      this.pageNum = val
    }
  },
  computed: {
    limit: {
      get() {
        return this.size
      },
      set(newValue) {
        this.size = newValue
      }
    },
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    limits() {
      const arr = [...new Set([...this.defaultLimits, this.pageSize])]
      return arr.sort((a, b) => {
        return a - b
      })
    },
    isFirstPage() {
      return !this.hasPrev
    },
    isLastPage() {
      return !this.hasNext
    }
    // isLastPage() {
    //   return !this.hasNext
    // }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style lang="scss">
[dir='rtl'] .pagination {
  margin-right: auto !important;
  margin-left: unset !important;
}
[dir='rtl'] .pagination {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
