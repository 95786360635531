import GraphqlClient from '@/shared/graphql/client'
import gql from 'graphql-tag'
import config from '@/config'

const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlVehicle)

export default class VehicleService {
  static async list() {
    const response = await graphqlClient.query({
      query: gql`
        query LIST_VEHICLES {
          listVehicles {
            id
            name {
              en
              ar
            }
            createdAt
            createdBy
          }
        }
      `
    })
    return response.data.listVehicles
  }

  static async find(vehicleId) {
    const response = await graphqlClient.query({
      query: gql`
        query VEHICLE_FIND($vehicleId: String!) {
          findVehicleById(vehicleId: $vehicleId) {
            name {
              en
              ar
            }
            id
            createdAt
            createdBy
          }
        }
      `,
      variables: {
        vehicleId
      }
    })
    return response.data.findVehicleById
  }

  static async create(vehicleInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation VEHICLE_CREATE($vehicleInput: NameInput!) {
          createVehicle(vehicleInput: $vehicleInput)
        }
      `,
      variables: {
        vehicleInput
      }
    })
    return response.data.createVehicle
  }

  static async remove(vehicleId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation VEHICLE_REMOVE($vehicleId: String!) {
          removeVehicle(vehicleId: $vehicleId)
        }
      `,
      variables: {
        vehicleId
      }
    })
    return response.data.removeVehicle
  }
}
