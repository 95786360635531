/* eslint-disable */
import { RequestService } from '@/store/request/request-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import { FileUploader } from '@/shared/uploader/file-uploader'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },
    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },
    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },
    // async doFind({ commit }, id) {
    //   try {
    //     commit('FIND_STARTED');
    //     const record = await RequestService.find(id);
    //     commit('FIND_SUCCESS', record);
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FIND_ERROR');
    //     router.push('/requests');
    //   }
    // },

    async doAccept(
      { commit, dispatch, rootGetters },
      { id, dueDate, notes, type }
    ) {
      try {
        commit('UPDATE_STARTED')
        await RequestService.accept(id, dueDate, notes, type)
        commit('UPDATE_SUCCESS')
        Message.success(i18n('request.accept.success'))

        dispatch(
          `request/list/doFetch`,
          {
            filter: rootGetters[`request/list/filter`],
            type,
            pagination: rootGetters[`request/list/pagination`]
          },
          { root: true }
        )
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    },

    async doReject({ commit, dispatch, rootGetters }, { id, notes, type }) {
      try {
        commit('UPDATE_STARTED')
        await RequestService.reject(id, notes, type)
        commit('UPDATE_SUCCESS')
        Message.success(i18n('request.reject.success'))

        dispatch(
          `request/list/doFetch`,
          {
            filter: rootGetters[`request/list/filter`],
            type,
            pagination: rootGetters[`request/list/pagination`]
          },
          { root: true }
        )
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    },

    async doClose(
      { commit, dispatch, rootGetters },
      { id, transactionId, file, type }
    ) {
      try {
        commit('UPDATE_STARTED')
        // [1] Upload pdf file to storage of firebase
        const path = `requests/${id}`
        const { publicUrl } = await FileUploader.upload(path, file)
        // [2] Confirm the request
        type === 'chef'
          ? await RequestService.closeChefRequest(id, transactionId, publicUrl)
          : await RequestService.closeCaptainRequest(
              id,
              transactionId,
              publicUrl
            )
        commit('UPDATE_SUCCESS')
        Message.success(i18n('request.close.success'))

        dispatch(
          `request/list/doFetch`,
          {
            filter: rootGetters[`request/list/filter`],
            type,
            pagination: rootGetters[`request/list/pagination`]
          },
          { root: true }
        )
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }
  }
}
