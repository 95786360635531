/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlSlider)

export class SliderService {
//#region [ Mutation APIs ]

  /**
   * Create new slider
   * - addSlider(sliderInput: SliderInput!): JSON!
   * @param {Object} data 
   * @param {String} data.id
   * @param {String} data.imageEn
   * @param {String} data.imageAr
   * @param {Object} data.title 
   * @param {String} data.title.en 
   * @param {String} data.title.ar 
   * @param {Object} data.description 
   * @param {String} data.description.en 
   * @param {String} data.description.ar 
   * @returns {Promise}
   */
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE_SLIDER($sliderInput: SliderInput!) {
          addSlider(sliderInput: $sliderInput)
        }
      `,

      variables: {
        sliderInput: data
      }
    })

    // return response.data.addSlider
    if (response.data.addSlider && response.data.addSlider.status) {
      return response.data.addSlider.result
    } else {
      console.error(response.data.addSlider.error);
      throw response.data.addSlider.error
    }
  }

  /**
   * Update slider
   * - updateSlider(sliderId: String!, sliderUpdate: SliderUpdate!): JSON!
   * @param {String} id - Slider ID 
   * @param {Object} data 
   * @param {String} data.imageEn
   * @param {String} data.imageAr
   * @param {Object} data.title 
   * @param {String} data.title.en 
   * @param {String} data.title.ar 
   * @param {Object} data.description 
   * @param {String} data.description.en 
   * @param {String} data.description.ar 
   * @returns {Promise}
   */
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SLIDER_UPDATE($sliderId: String!, $sliderUpdate: SliderUpdate!) {
          updateSlider(sliderId: $sliderId, sliderUpdate: $sliderUpdate)
        }
      `,

      variables: {
        sliderId: id,
        sliderUpdate: data
      }
    })

    // return response.data.updateSlider
    if (response.data.updateSlider && response.data.updateSlider.status) {
      return response.data.updateSlider.result
    } else {
      console.error(response.data.updateSlider.error);
      throw response.data.updateSlider.error
    }
  }  

  /**
   * Delete slider by ID
   * - removeSlider(sliderId: String!): JSON!
   * @param {String} sliderId 
   * @returns {Promise}
   */
  static async remove(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SLIDER_DESTROY($sliderId: String!) {
          removeSlider(sliderId: $sliderId)
        }
      `,

      variables: {
        sliderId: id
      }
    })
    // return response.data.removeSlider
    if (response.data.removeSlider && response.data.removeSlider.status) {
      return response.data.removeSlider.result
    } else {
      console.error(response.data.removeSlider.error);
      throw response.data.removeSlider.error
    }
  }
//#endregion

//#region [ Query APIs ]
  /**
   * Find Slider By ID
   * - findSliderById(sliderId: String!): Slider!
   * @param {String} id - Slider ID
   * @returns {Promise<Slider>}
   */
  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query SLIDER_FIND($sliderId: String!) {
          findSliderById(sliderId: $sliderId) {
            id
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            imageEn
            imageAr
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        sliderId: id
      }
    })

    return response.data.findSliderById
  }

  /**
   * List all sliders
   * - listSliders: [Slider]!
   * @returns {Promise<Slider[]>}
   */
  static async list() {
    const response = await graphqlClient.query({
      query: gql`
        query SLIDER_LIST {
          listSliders {
            id
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            imageEn
            imageAr
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `
    })

    return response.data.listSliders
  }
//#endregion

}
