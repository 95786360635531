/* eslint-disable */

// import Roles from '@/security/roles';
// const roles = Roles.values;
import PagesAccess from '@/security/pages-access';
const pagesAccess = PagesAccess.values;

/**
 * List of Permissions and the Roles allowed of using them.
 */
class Permissions {
  static get values() {
    return {
      pagesAccessRead: pagesAccess.setAccessPermissions,
      adminRead: pagesAccess.viewAdmins,
      adminCreate: pagesAccess.createAdmin,
      adminEdit: pagesAccess.editAdmin,
      adminDestroy: pagesAccess.deleteAdmin,
      adminChangeStatus: pagesAccess.changeAdminStatus,
      adminPrint: pagesAccess.printAdminsTable,
      adminExport: pagesAccess.exportAdminsTable,

      captainRead: pagesAccess.viewCaptains,
      // captainDestroy: pagesAccess.deleteCaptain,
      captainChangeStatus: pagesAccess.changeCaptainStatus,
      captainPrint: pagesAccess.printCaptainsTable,
      captainExport: pagesAccess.exportCaptainsTable,

      clientRead: pagesAccess.viewClients,
      // clientDestroy: pagesAccess.deleteClient,
      clientChangeStatus: pagesAccess.changeClientStatus,
      clientPrint: pagesAccess.printClientsTable,
      clientExport: pagesAccess.exportClientsTable,

      chefRead: pagesAccess.viewChefs,
      chefEdit: pagesAccess.editChef,
      // chefDestroy: pagesAccess.deleteChef,
      chefChangeStatus: pagesAccess.changeChefStatus,
      chefPrint: pagesAccess.printChefsTable,
      chefExport: pagesAccess.exportChefsTable,

      categoryRead: pagesAccess.viewCategories,
      categoryCreate: pagesAccess.createCategory,
      categoryEdit: pagesAccess.editCategory,
      categoryDestroy: pagesAccess.deleteCategory,
      categoryPrint: pagesAccess.printCategoriesTable,
      categoryExport: pagesAccess.exportCategoriesTable,
      
      requestRead: pagesAccess.viewRequests,
      // requestEdit: pagesAccess.editRequest,
      requestTakeAction: pagesAccess.takeRequestAction,
      requestPrint: pagesAccess.printRequestsTable,
      requestExport: pagesAccess.exportRequestsTable,
      
      notificationRead: pagesAccess.viewNotifications,
      notificationCreate: pagesAccess.createNotification,
      notificationSend: pagesAccess.sendNotification,

      sliderRead: pagesAccess.viewSliders,
      sliderCreate: pagesAccess.createSlider,
      sliderEdit: pagesAccess.editSlider,
      sliderDestroy: pagesAccess.deleteSlider,

      orderRead: pagesAccess.viewOrders,
      orderEdit: pagesAccess.editOrder,
      orderTakeAction: pagesAccess.takeOrderAction,
      orderDestroy: pagesAccess.deleteOrder,
      
      vehicleRead: pagesAccess.viewVehicles,
      vehicleCreate: pagesAccess.createVehicle,
      // vehicleEdit: pagesAccess.editVehicle,
      vehicleDestroy: pagesAccess.deleteVehicle,
      vehiclePrint: pagesAccess.printVehiclesTable,
      vehicleExport: pagesAccess.exportVehiclesTable,

      appWalletRead: pagesAccess.viewAppWallet,
      
      promotionsRead: pagesAccess.viewPromotions,

      complaintRead: pagesAccess.viewComplaints,

      reportRead: pagesAccess.viewReports ,
      salesReportRead: pagesAccess.viewSalesReports,
      
      settingRead: pagesAccess.viewSettings,
      // generalSettingRead: pagesAccess.viewGeneralSettings,
      generalSettingCreate: pagesAccess.createGeneralSettings,
      policyRead: pagesAccess.viewPolicySettings,
      policyCreate: pagesAccess.createPolicySetting,
    };
  }

  static get asArray() {
    return Object.keys(this.values).map(key => {
      return this.values[key];
    });
  }
}

export default Permissions;
