/* eslint-disable */
import { WalletService } from '@/store/wallet/wallet-service'
import { state, getters, mutations, actions } from '@/store/list-utils'

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations
  },
  actions: {
    async doChangePaginationPageSize({ commit, getters }, pageSize) {
      await actions.changePageSize({ commit, getters, pageSize })
    },
    doChangePaginationCurrentPage({ commit }, currentPage) {
      actions.changeCurrentPage({ commit, page: currentPage })
    },
    async doFetchNextPage({ commit, getters }) {
      await actions.fetchNext({ commit, getters })
    },
    async doFetchPreviousPage({ commit, getters }) {
      await actions.fetchPrevious({ commit, getters })
    },
    async doFetch(
      { commit, getters },
      { filter, type, pagination, keepPagination = true } = {}
    ) {
      const walletServiceMap = {
        client: WalletService.listClientsWallet,
        chef: WalletService.listChefsWallet,
        captain: WalletService.listCaptainsWallet
      }
      const selectedWalletService = walletServiceMap[type]
      selectedWalletService &&
        (await actions.fetch({
          commit,
          getters,
          list: selectedWalletService,
          filter,
          pagination,
          keepPagination
        }))
    }
  }
}
