/* eslint-disable */
import AdminService from '@/store/auth/auth-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'

export default {
  namespaced: true,

  state: {
    loading: false
  },

  getters: {
    loading: state => !!state.loading
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true
    },

    DESTROY_ALL_SUCCESS(state) {
      state.loading = false
    },

    DESTROY_ALL_ERROR(state) {
      state.loading = false
    },

    DESTROY_STARTED(state) {
      state.loading = true
    },

    DESTROY_SUCCESS(state) {
      state.loading = false
    },

    DESTROY_ERROR(state) {
      state.loading = false
    }
  },

  actions: {
    async doDestroy({ commit, dispatch, rootGetters }, id) {
      try {
        commit('DESTROY_STARTED')

        await AdminService.removeAdmins([id])

        commit('DESTROY_SUCCESS')
        Message.success(i18n('admin.destroy.success'))

        dispatch(
          `admin/list/doFetch`,
          {
            filter: rootGetters[`admin/list/filter`],
            pagination: rootGetters[`admin/list/pagination`]
          },
          { root: true }
        )
      } catch (error) {
        Errors.handle(error)
        commit('DESTROY_ERROR')
      }
    },

    async doDestroyAll({ commit, dispatch, rootGetters }, ids) {
      try {
        commit('DESTROY_ALL_STARTED')
        await AdminService.removeAdmins(ids)

        commit('DESTROY_ALL_SUCCESS')

        Message.success(i18n('admin.destroyAll.success'))

        dispatch(
          `admin/list/doFetch`,
          {
            filter: rootGetters[`admin/list/filter`],
            pagination: rootGetters[`admin/list/pagination`]
          },
          { root: true }
        )
      } catch (error) {
        Errors.handle(error)
        commit('DESTROY_ALL_ERROR')
      }
    }
  }
}
