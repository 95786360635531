<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <div class="down" style="z-index: 2 !important"></div>
    <div class="sm:px-10">
      <div class="container block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <div class="m-auto logo-web flex justify-center items-center">
            <lottie-player
              src="/logoAnimation.json"
              speed="1"
              autoplay
              background="transparent"
              style="width: 600px; height: 600px"
            ></lottie-player>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="
              my-auto
              mx-auto
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:px-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              xl:pt-20
              sm:w-3/4
              lg:w-2/4
            "
          >
            <div class="xl:hidden flex justify-center">
              <div class="my-5">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="-intro-x w-1/2"
                  src="@/assets/images/homefoodlogo.png"
                  style="width: 150px"
                />
              </div>
            </div>
            <h2
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-white
              "
              :class="isRTL ? ' xl:text-right' : ' xl:text-left'"
            >
              {{ i18n('forgotPassword.title') }}
            </h2>
            <div class="intro-x mt-2 xl:text-white">
              {{ i18n('forgotPassword.desc1') }}<br />
              {{ i18n('forgotPassword.desc2') }}
            </div>
            <div class="intro-x mt-8">
              <input
                type="text"
                class="
                  intro-x
                  login__input
                  form-control
                  py-3
                  px-4
                  border-gray-300
                  block
                  dark:bg-dark-9
                "
                :placeholder="i18n('forgotPassword.emailAddress')"
                v-model="form.email"
              />
            </div>
            <div
              v-if="error"
              class="
                alert alert-danger alert-dismissible
                show
                flex
                items-center
                mb-2
                mt-2
              "
              role="alert"
              style="z-index: 2 !important"
            >
              <AlertOctagonIcon class="w-6 h-6 mr-2 ml-2" />
              {{ i18n(errorData) }}
            </div>
            <div
              class="
                intro-x
                mt-5
                xl:mt-8
                text-center
                grid grid-cols-12
                login__input
                gap-2
              "
              :class="isRTL ? ' xl:text-right' : ' xl:text-left'"
            >
              <button
                class="
                  btn btn-primary
                  py-3
                  px-4
                  align-top
                  xl:col-span-6
                  col-span-12
                "
                @click="resetPassword"
                :disabled="loading"
              >
                <span v-if="!loading">{{
                  i18n('forgotPassword.requestResetLink')
                }}</span>
                <span v-if="loading">{{ i18n('common.loading') }}</span>
                <LoadingIcon
                  icon="three-dots"
                  color="#FFFFFF"
                  style="margin: 0 4px"
                  v-if="loading"
                />
              </button>
              <button
                class="
                  btn btn-outline-secondary
                  py-3
                  px-4
                  xl:mt-0
                  align-top
                  xl:text-white xl:col-span-6
                  col-span-12
                "
                @click="goToLogin"
              >
                {{ i18n('forgotPassword.login') }}
              </button>
              <i18nFlags class="mt-10" @click="changeLoginClass" />
            </div>
            <SuccessNotification message="Password reset e-mail Sent" />
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SuccessNotification from '@/components/notifications/success-notification.vue'
import { useStore } from '@/store'
import Toastify from 'toastify-js'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.forgotPassword')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.forgotPassword')
    }
  },
  components: {
    // DarkModeSwitcher,
    SuccessNotification
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loadingPasswordResetEmail',
      isRTL: 'layout/isRTL'
    })
    // isRTL() {
    //   return this.$i18n.locale == 'ar'
    // }
  },
  mounted() {
    if (this.isRTL) {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login-rtl')
    } else {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    }
  },
  setup() {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const error = ref(false)
    const errorData = ref('')
    const form = ref({
      email: ref('')
    })
    const notificationToggle = () => {
      Toastify({
        node: cash('#success-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: -1,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    return {
      error,
      errorData,
      form,
      darkMode,
      notificationToggle
    }
  },
  methods: {
    ...mapActions({
      doSendPasswordResetEmail: 'auth/doSendPasswordResetEmail'
    }),
    validateEmail(email) {
      return email.match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    changeLoginClass() {
      if (this.isRTL) {
        cash('body').removeClass('login').addClass('login-rtl')
      } else {
        cash('body').removeClass('login-rtl').addClass('login')
      }
    },
    goToSignup() {
      this.$router.push('/auth/register')
    },
    goToLogin() {
      this.$router.push('/auth/login')
    },
    async resetPassword() {
      if (this.form.email.length == 0) {
        this.error = true
        this.errorData = 'forgotPassword.errors.emptyFields'
      } else if (!this.validateEmail(this.form.email)) {
        this.error = true
        this.errorData = 'forgotPassword.errors.wrongEmail'
      } else {
        this.error = false
        await this.doSendPasswordResetEmail(this.form.email)
      }
    }
  }
})
</script>

<style lang='scss'>
.dark .btn-outline-secondary:hover {
  --tw-bg-opacity: 0.2;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}
.down {
  @media only screen and (min-width: 1280px) {
    height: 100vh;
    width: 10px;
    background-color: #172e71;
    animation-name: drawDownLine;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform-origin: 50% 0%;
    position: absolute;
    left: 50%;
  }
}

@keyframes drawDownLine {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleyY(1);
  }
}
</style>
