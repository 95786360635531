/* eslint-disable */
import { i18n } from '@/locales/i18n'
import { GenericModel } from '@/shared/model/generic-model'
import IdField from '@/shared/fields/id-field'
import StringField from '@/shared/fields/string-field'
import IntegerField from '@/shared/fields/integer-field'
import JsonField from '@/shared/fields/json-field'
import DateTimeField from '@/shared/fields/date-time-field'

function label(name) {
  return i18n(`settings.fields.${name}`)
}

const fields = {
  id: new IdField('id', label('id')),
  email: new StringField('email', label('email')),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber')),
  whatsapp: new StringField('whatsapp', label('whatsapp')),
  instagram: new StringField('instagram', label('instagram')),
  snapchat: new StringField('snapchat', label('snapchat')),
  address: new StringField('address', label('address')),
  playStoreClient: new StringField('playStoreClient', label('playStoreClient')),
  appStoreClient: new StringField('appStoreClient', label('appStoreClient')),
  playStoreChef: new StringField('playStoreChef', label('playStoreChef')),
  appStoreChef: new StringField('appStoreChef', label('appStoreChef')),
  playStoreCaptain: new StringField(
    'playStoreCaptain',
    label('playStoreCaptain')
  ),
  appStoreCaptain: new StringField('appStoreCaptain', label('appStoreCaptain')),
  androidVersionClient: new StringField(
    'androidVersionClient',
    label('androidVersionClient')
  ),
  IOSVersionClient: new StringField(
    'IOSVersionClient',
    label('IOSVersionClient')
  ),
  androidVersionChef: new StringField(
    'androidVersionChef',
    label('androidVersionChef')
  ),
  IOSVersionChef: new StringField('IOSVersionChef', label('IOSVersionChef')),
  androidVersionCaptain: new StringField(
    'androidVersionCaptain',
    label('androidVersionCaptain')
  ),
  IOSVersionCaptain: new StringField(
    'IOSVersionCaptain',
    label('IOSVersionCaptain')
  ),
  chefRatio: new IntegerField('chefRatio', label('chefRatio')),
  chefRadius: new IntegerField('chefRadius', label('chefRadius')),
  captainRadius: new IntegerField('captainRadius', label('captainRadius')),
  deliveryTime: new IntegerField('deliveryTime', label('deliveryTime')),
  deliveryNotificationTime: new IntegerField(
    'deliveryNotificationTime',
    label('deliveryNotificationTime')
  ),
  captainRatio: new IntegerField('captainRatio', label('captainRatio')),
  promotionPrice: new IntegerField('promotionPrice', label('promotionPrice')),
  minDeliveryFees: new IntegerField(
    'minDeliveryFees',
    label('minDeliveryFees')
  ),
  maxDeliveryFees: new IntegerField(
    'maxDeliveryFees',
    label('maxDeliveryFees')
  ),
  priceForKM: new IntegerField('priceForKM', label('priceForKM')),
  vat: new IntegerField('vat', label('taxPercentage')),
  value: new JsonField('value', label('value'), ['en', 'ar']),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy'))
}

export class SettingModel extends GenericModel {
  static get fields() {
    return fields
  }
}
