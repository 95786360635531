/* eslint-disable */
import _values from 'lodash/values'
import { i18n } from '@/locales/i18n'
// let pagesJson = null

/**
 * List of Roles available for the Users.
 */
class PagesAccess {
  static get values() {
    return {
      setAccessPermissions: 'setAccessPermissions',
      viewAdmins: 'viewAdmins',
      createAdmin: 'createAdmin',
      editAdmin: 'editAdmin',
      deleteAdmin: 'deleteAdmin',
      changeAdminStatus: 'changeAdminStatus',
      printAdminsTable: 'printAdminsTable',
      exportAdminsTable: 'exportAdminsTable',
      viewCaptains: 'viewCaptains',
      // deleteCaptain : "deleteCaptain",
      changeCaptainStatus: 'changeCaptainStatus',
      printCaptainsTable: 'printCaptainsTable',
      exportCaptainsTable: 'exportCaptainsTable',
      viewClients: 'viewClients',
      // deleteClient : "deleteClient",
      changeClientstatus: 'changeClientStatus',
      printClientsTable: 'printClientsTable',
      exportClientsTable: 'exportClientsTable',
      viewChefs: 'viewChefs',
      editChef: 'editChef',
      // deleteChef : "deleteChef",
      changeChefstatus: 'changeChefStatus',
      printChefsTable: 'printChefsTable',
      exportChefsTable: 'exportChefsTable',
      viewCategories: 'viewCategories',
      createCategory: 'createCategory',
      editCategory: 'editCategory',
      deleteCategory: 'deleteCategory',
      printCategoriesTable: 'printCategoriesTable',
      exportCategoriesTable: 'exportCategoriesTable',
      viewSliders: 'viewSliders',
      createSlider: 'createSlider',
      editSlider: 'editSlider',
      deleteSlider: 'deleteSlider',
      viewOrders: 'viewOrders',
      editOrder: 'editOrder',
      takeOrderAction: 'takeOrderAction',
      deleteOrder: 'deleteOrder',
      viewVehicles: 'viewVehicles',
      createVehicle: 'createVehicle',
      // editVehicle : "editVehicle",
      deleteVehicle: 'deleteVehicle',
      printVehiclesTable: 'printVehiclesTable',
      exportVehiclesTable: 'exportVehiclesTable',
      viewAppWallet: 'viewAppWallet',
      viewPromotions: 'viewPromotions',
      viewComplaints: 'viewComplaints',
      viewRequests: 'viewRequests',
      // editRequest: "editRequest",
      takeRequestAction: 'takeRequestAction',
      printRequestsTable: 'printRequestsTable',
      exportRequestsTable: 'exportRequestsTable',
      viewReports: 'viewReports',
      viewSalesReports: 'viewSalesReports',
      viewNotifications: 'viewNotifications',
      createNotification: 'createNotification',
      sendNotification: 'sendNotification',
      viewSettings: 'viewSettings',
      // viewGeneralSettings: "viewGeneralSettings",
      createGeneralSettings: 'createGeneralSettings',
      viewPolicySettings: 'viewPolicySettings',
      createPolicySetting: 'createPolicySetting'
    }
  }
  static labelOf(pageId) {
    if (!this.values[pageId]) {
      return pageId
    }
    return i18n(`pagesAccess.${pageId}`)
  }
  static get selectOptions() {
    return _values(this.values).map(value => ({
      id: value,
      value: value,
      // title: this.descriptionOf(value),
      label: this.labelOf(value),
      selected: false
    }))
  }
}

export default PagesAccess
