import vehicleListStore from '@/store/vehicle/vehicle-list-store'
import vehicleFormStore from '@/store/vehicle/vehicle-form-store'

export default {
  namespaced: true,
  modules: {
    list: vehicleListStore,
    form: vehicleFormStore
  }
}
