<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('profile.title') }}
      </h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div
            class="
              w-20
              h-20
              sm:w-24 sm:h-24
              flex-none
              lg:w-32 lg:h-32
              image-fit
              relative
              m-auto
              cursor-pointer
            "
          >
            <div>
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                :src="
                  model.avatar ? model.avatar : '/images/default-avatar.svg'
                "
              />
              <div
                class="
                  absolute
                  mb-1
                  mr-1
                  flex
                  items-center
                  justify-center
                  bottom-0
                  right-0
                  bg-theme-17
                  rounded-full
                  p-2
                "
              >
                <CameraIcon class="w-4 h-4 text-white" />
              </div>
            </div>

            <input
              type="file"
              id="profileImage"
              name="upload"
              accept="image/png, image/jpeg, image/jpg"
              style="position: absolute; top: 0%; opacity: 0 !important"
              class="
                cursor-pointer
                w-20
                h-20
                sm:w-24 sm:h-24
                flex-none
                lg:w-32 lg:h-32
                image-fit
                relative
                m-auto
                cursor-pointer
              "
              @input="emitEvent($event)"
            />
          </div>
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:bg-dark-9"
                v-model="model.userName"
                :placeholder="i18n('profile.form.username')"
              />
            </div>
          </div>
          <!-- <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:bg-dark-9"
                v-model="form.email"
                :placeholder="i18n('profile.form.emailAddress')"
              />
            </div>
          </div> -->
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12" style="dir: ltr">
              <vue-tel-input
                v-model="model.phoneNumber"
                mode="international"
                validCharactersOnly
                autoDefaultCountry
                :inputOptions="isRTL ? optionsAr : options"
                :dropdownOptions="telDropOptions"
              ></vue-tel-input>
            </div>
          </div>
          <div
            class="
              flex flex-col
              sm:flex-row
              justify-center
              items-center
              gap-2
              mt-5
              sm:mt-10
            "
          >
            <AppButton
              type="button"
              class="btn btn-primary text-white cursor-pointer"
              :class="!saveLoading && !uploadLoading ? 'w-full sm:w-24' : ''"
              :disabled="saveLoading || uploadLoading"
              :loading="saveLoading || uploadLoading"
              @click="doSubmit"
            >
              <strong>{{ i18n('common.save') }}</strong>
              <template v-slot:loading>
                <app-i18n code="common.loading"></app-i18n>
                <LoadingIcon
                  icon="three-dots"
                  color="#FFFFFF"
                  style="margin: 0 4px"
                />
              </template>
            </AppButton>
            <button
              type="button"
              class="btn bg-gray-200 text-black w-full sm:w-40"
              :disabled="saveLoading || uploadLoading"
              @click="goToChangePassword"
            >
              {{ i18n('profile.changePassword') }}
            </button>
            <button
              type="button"
              class="btn btn-secondary text-black w-full sm:w-24"
              :disabled="saveLoading || uploadLoading"
              @click="doCancel"
            >
              {{ i18n('common.cancel') }}
            </button>
          </div>
          <!-- <div class="mt-3">
            <MultipleFileUpload />
          </div> -->
          <!-- END -->
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
    <ErrorNotification :message="errorMessage" />
    <SuccessNotification message="profile.form.success" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { FileUploader } from '@/shared/uploader/file-uploader'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { UserModel } from '@/store/user/user-model'

const { fields } = UserModel
const formSchema = new FormSchema([
  fields.userName,
  fields.phoneNumber,
  fields.avatar
])

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.profile')
    this.model = formSchema.initialValues(this.currentUser)
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.profile')
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      saveLoading: 'auth/loadingUpdateProfile'
    }),
    fields() {
      return fields
    }
  },
  data() {
    return {
      image: null,
      imageUrl: '',
      uploadLoading: false,
      errorMessage: null,
      model: null,
      rules: formSchema.rules(),
      options: { placeholder: 'Enter Your Phone Number', showDialCode: false },
      optionsAr: { placeholder: 'ادخل رقم تليفونك', showDialCode: false },
      telDropOptions: {
        showFlags: true
      }
    }
  },
  methods: {
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile'
    }),
    emitEvent(event) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (e) => {
        const image = {
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          lastModifiedDate: event.target.files[0].lastModifiedDate,
          base64: reader.result
        }
        const name = event.target.files[0].name
        if (
          !name.endsWith('png') &&
          !name.endsWith('jpg') &&
          !name.endsWith('jpeg')
        ) {
          Message.error(this.i18n('errors.onlyImages'))
          return
        }
        this.image = image
        const file = event.target.files[0]
        this.imageUrl = URL.createObjectURL(file)
        this.model.avatar = this.imageUrl
      }
    },
    async doSubmit() {
      try {
        if (this.image) {
          this.uploadLoading = true
          const profileImage = document.getElementById('profileImage').files[0]
          const path = `admin/avatars/profile/${this.currentUser.id}`
          const { publicUrl } = await FileUploader.upload(
            path,
            profileImage,
            'avatar'
          )
          this.model.avatar = publicUrl
        }

        const { userName, phoneNumber, avatar } = formSchema.cast(this.model)
        await this.doUpdateProfile({
          userName,
          phoneNumber: phoneNumber.replaceAll(' ', ''),
          avatar
        })
        this.uploadLoading = false
      } catch (error) {
        console.error(error)
      }
    },
    goToChangePassword() {
      this.$router.push('/change-password')
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (var key in this.model) {
        var value = this.model[key]
        if (value.length == 0) {
          this.errorMessage = 'profile.form.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/')
    }
  }
}
</script>
