<template>
  <router-view />
  <ErrorNotify message="--text--"></ErrorNotify>
  <SuccessNotify message="--text--"></SuccessNotify>
</template>

<script>
import { mapActions } from 'vuex'
import { getLanguageCode } from './locales/i18n'

export default {
  async created() {
    await this.doInit()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    const language = getLanguageCode() || localStorage.getItem('language')
    if (language === 'ar') {
      document.body.setAttribute('dir', 'rtl')
      document.documentElement.setAttribute('lang', 'ar')
    } else {
      document.body.setAttribute('dir', 'ltr')
      document.documentElement.setAttribute('lang', 'en')
    }
  },
  methods: {
    ...mapActions({
      doInit: 'auth/doInit',
      resize: 'layout/resize'
    }),
    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body[dir='rtl'] {
  font-family: 'Noto Kufi Arabic', sans-serif !important;
}
body[dir='ltr'] {
  font-family: 'Poppins', sans-serif !important;
}

body[dir='rtl'] .side-nav > ul > li > .side-menu.side-menu--active:before {
  margin-left: -26px;
  margin-right: unset;
  right: unset;
  left: 0px;
  transform: rotate(180deg);
}

.vue-tel-input {
  direction: ltr;
}
.dropzone {
  height: fit-content !important;
}
.table td,
.table th {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

tr.removedRow td {
  background-color: #ffdfdf !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
