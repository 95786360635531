/* eslint-disable */ 
import orderListStore from '@/store/order/order-list-store';
import orderFormStore from '@/store/order/order-form-store';

export default {
  namespaced: true,

  modules: {
    list: orderListStore,
    form: orderFormStore
  },
};
