/* eslint-disable */ 
import reportSalesStore from '@/store/report/report-sales-store';
import reportCitiesStore from '@/store/report/report-cities-store';
import reportMonthlyTopCitiesStore from '@/store/report/report-monthly-top-cities-store';
import reportWeeklyTopCitiesStore from '@/store/report/report-weekly-top-cities-store';

export default {
  namespaced: true,

  modules: {
    sales: reportSalesStore,
    cities: reportCitiesStore,
    monthlyTopCities: reportMonthlyTopCitiesStore,
    weeklyTopCities: reportWeeklyTopCitiesStore,
  },
};
