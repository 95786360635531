/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlChef)

export class ReviewService {
  /**
   * Delete Review by ID
   * - removeReview(ReviewId: String!): JSON!
   * @param {String} reviewId
   * @param {String} chefId

   * @returns
   */
  static async removeReview(chefId, reviewId) {
    console.log(chefId, reviewId)
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REVIEW_DESTROY($chefId: String!, $reviewId: String!) {
          removeChefReviews(chefId: $chefId, reviewId: $reviewId)
        }
      `,

      variables: {
        chefId,
        reviewId
      }
    })

    if (response.data.removeCategory && response.data.removeCategory.status) {
      return response.data.removeCategory.result
    } else {
      console.error(response.data.removeCategory.error)
      throw response.data.removeCategory.error
    }
  }

  //#endregion
}
