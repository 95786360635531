<template>
  <div id="date_time" class="date-class" style="date-style">
    {{ showDate }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'app-show-date-time',
  props: ['dateTime', 'date-class', 'date-style'],
  // props: {
  //   dateTime: {
  //     type: Date,
  //     required: true,
  //   },
  //   'date-class': {
  //     type: String,
  //     required: false,
  //   },
  //   'date-style': {
  //     type: String,
  //     required: false,
  //   },
  // },
  async created() {
    // Set the date we're counting down to

    // Update the date time every 1 minute
    // let x = setInterval(function() {
    //   // If the count down is over, write some text
    //   if (distance < 0) {
    //     // this.doFindNextDate()
    //     clearInterval(x)
    //     document.getElementById('date-time').innerHTML = 'Invalid Date'
    //     document.getElementById('date-time').classList.add('next')
    //   }
    // }, 3000)

    // Update the date time every 1 minute
    setInterval(this.updateTime(), 1000)

    // this.updateTime()
  },
  async mounted() {},
  data() {
    return {
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      mins: 0,
      secs: 0,
      displayDate: undefined
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    }),
    showDate() {
      return this.displayDate
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    updateTime() {
      // let now = new Date().getTime()
      // let date = new Date(this.dateTime).getTime()
      // let distance = date - now

      // Time calculations for years, months, days, hours, minutes and seconds
      // let days = Math.floor(distance / (1000 * 60 * 60 * 24))
      // let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      // let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      // let seconds = Math.floor((distance % (1000 * 60)) / 1000)

      const date = this.dateTime // Set the date we're counting down to
      const now = new Date() // Get today's date and time
      const distance = Math.abs(now - date) / 1000 // Find the distance between now and the date time
      const diffYears = now.getFullYear() - date.getFullYear() // get total years between two dates
      const diffMonths = diffYears * 12 - now.getMonth() + date.getMonth() // get total months between two dates
      const diffDays = Math.floor(distance / 86400) // get total days between two dates
      const diffHours = Math.floor(distance / 3600) % 24 // get hours
      const diffMinutes = Math.floor(distance / 60) % 60 // get minutes
      // Output the result in an element with id='demo'
      if (
        diffYears == 0 &&
        diffMonths == 0 &&
        diffDays == 0 &&
        diffHours == 0 &&
        diffMinutes < 60
      ) {
        this.displayDate = moment
          .duration(-diffMinutes, 'minutes')
          .locale(this.language)
          .humanize(true)
      } else if (
        diffYears == 0 &&
        diffMonths == 0 &&
        diffDays == 0 &&
        diffHours < 24
      ) {
        const midnight = new Date().setHours(0, 0, 0)

        if (now >= midnight && date < midnight) {
          this.displayDate =
            this.i18n('common.yesterday') +
            this.i18n('common.at') +
            moment(date).locale(this.language).format('hh:mm A')
        } else {
          this.displayDate = moment
            .duration(-diffHours, 'hours')
            .locale(this.language)
            .humanize(true)
        }
      } else if (diffYears == 0 && diffMonths == 0 && diffDays < 7) {
        this.displayDate =
          moment(date).locale(this.language).format('ddd') +
          this.i18n('common.at') +
          moment(date).locale(this.language).format('hh:mm A')
      } else if (diffYears == 0 && diffDays >= 7) {
        this.displayDate =
          moment(date).locale(this.language).format('MMM D') +
          this.i18n('common.at') +
          moment(date).locale(this.language).format('hh:mm A')
      } else if (diffYears > 0) {
        this.displayDate =
          moment(date).locale(this.language).format('D MMM, YYYY') +
          this.i18n('common.at') +
          moment(date).locale(this.language).format('hh:mm A')
      }
      // document.getElementById('date_time').innerHTML = this.displayDate
      // setTimeout(this.updateTime(), 1000);
    }
  },
  watch: {
    dateTime() {
      this.updateTime()
    }
  }
}
</script>

<style lang='scss' scoped>
#date_time {
  margin-top: 6px !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal !important;
  color: #6a6a6a !important;
}
</style>
