/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlSettings)

export class SettingService {
  static async updateGeneralSettings(settingsInput) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SETTINGS_GENERAL_UPDATE($settingsInput: SettingsInput!) {
          updateSettings(settingsInput: $settingsInput)
        }
      `,

      variables: {
        settingsInput
      }
    })

    if (response.data.updateSettings && response.data.updateSettings.status) {
      return response.data.updateSettings.result
    } else {
      console.error(response.data.updateSettings.error)
      throw response.data.updateSettings.error
    }
  }

  static async findGeneralSettings() {
    const response = await graphqlClient.query({
      query: gql`
        query SETTINGS_GENERAL_FIND {
          findSettings {
            email
            phoneNumber
            whatsapp
            address
            promotionPrice
            instagram
            snapchat
            playStoreClient
            appStoreClient
            playStoreChef
            appStoreChef
            playStoreCaptain
            appStoreCaptain
            chefRatio
            chefRadius
            captainRadius
            deliveryTime
            deliveryNotificationTime
            captainRatio
            minDeliveryFees
            maxDeliveryFees
            priceForKM
            vat
            androidVersionClient
            IOSVersionClient
            androidVersionChef
            IOSVersionChef
            androidVersionCaptain
            IOSVersionCaptain
          }
        }
      `
    })

    return response.data.findSettings
  }

  static async updateClientTermsAndConditions(termsAndConditions) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CLIENT_TERMS_AND_CONDITIONS_UPDATE(
          $termsAndConditions: NameInput!
        ) {
          updateClientTermsAndConditions(
            termsAndConditions: $termsAndConditions
          )
        }
      `,

      variables: {
        termsAndConditions
      }
    })

    if (
      response.data.updateClientTermsAndConditions &&
      response.data.updateClientTermsAndConditions.status
    ) {
      return response.data.updateClientTermsAndConditions.result
    } else {
      console.error(response.data.updateClientTermsAndConditions.error)
      throw response.data.updateClientTermsAndConditions.error
    }
  }

  static async updateChefTermsAndConditions(termsAndConditions) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CHEF_TERMS_AND_CONDITIONS_UPDATE(
          $termsAndConditions: NameInput!
        ) {
          updateChefTermsAndConditions(termsAndConditions: $termsAndConditions)
        }
      `,

      variables: {
        termsAndConditions
      }
    })

    if (
      response.data.updateChefTermsAndConditions &&
      response.data.updateChefTermsAndConditions.status
    ) {
      return response.data.updateChefTermsAndConditions.result
    } else {
      console.error(response.data.updateChefTermsAndConditions.error)
      throw response.data.updateChefTermsAndConditions.error
    }
  }

  static async updateCaptainTermsAndConditions(termsAndConditions) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CAPTAIN_TERMS_AND_CONDITIONS_UPDATE(
          $termsAndConditions: NameInput!
        ) {
          updateCaptainTermsAndConditions(
            termsAndConditions: $termsAndConditions
          )
        }
      `,

      variables: {
        termsAndConditions
      }
    })

    if (
      response.data.updateCaptainTermsAndConditions &&
      response.data.updateCaptainTermsAndConditions.status
    ) {
      return response.data.updateCaptainTermsAndConditions.result
    } else {
      console.error(response.data.updateCaptainTermsAndConditions.error)
      throw response.data.updateCaptainTermsAndConditions.error
    }
  }

  static async findClientTermsAndConditions() {
    const response = await graphqlClient.query({
      query: gql`
        query CLIENT_TERMS_AND_CONDITIONS_FIND {
          findClientTermsAndConditions {
            id
            value {
              en
              ar
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `
    })

    return response.data.findClientTermsAndConditions
  }

  static async findChefTermsAndConditions() {
    const response = await graphqlClient.query({
      query: gql`
        query CHEF_TERMS_AND_CONDITIONS_FIND {
          findChefTermsAndConditions {
            id
            value {
              en
              ar
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `
    })

    return response.data.findChefTermsAndConditions
  }

  static async findCaptainTermsAndConditions() {
    const response = await graphqlClient.query({
      query: gql`
        query CAPTAIN_TERMS_AND_CONDITIONS_FIND {
          findCaptainTermsAndConditions {
            id
            value {
              en
              ar
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `
    })

    return response.data.findCaptainTermsAndConditions
  }

  static async updateClientPrivacyPolicy(privacyPolicy) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CLIENT_PRIVACY_POLICY_UPDATE($privacyPolicy: NameInput!) {
          updateClientPrivacyPolicy(privacyPolicy: $privacyPolicy)
        }
      `,

      variables: {
        privacyPolicy
      }
    })

    if (
      response.data.updateClientPrivacyPolicy &&
      response.data.updateClientPrivacyPolicy.status
    ) {
      return response.data.updateClientPrivacyPolicy.result
    } else {
      console.error(response.data.updateClientPrivacyPolicy.error)
      throw response.data.updateClientPrivacyPolicy.error
    }
  }

  static async updateChefPrivacyPolicy(privacyPolicy) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CHEF_PRIVACY_POLICY_UPDATE($privacyPolicy: NameInput!) {
          updateChefPrivacyPolicy(privacyPolicy: $privacyPolicy)
        }
      `,

      variables: {
        privacyPolicy
      }
    })

    if (
      response.data.updateChefPrivacyPolicy &&
      response.data.updateChefPrivacyPolicy.status
    ) {
      return response.data.updateChefPrivacyPolicy.result
    } else {
      console.error(response.data.updateChefPrivacyPolicy.error)
      throw response.data.updateChefPrivacyPolicy.error
    }
  }

  static async updateCaptainPrivacyPolicy(privacyPolicy) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CAPTAIN_PRIVACY_POLICY_UPDATE($privacyPolicy: NameInput!) {
          updateCaptainPrivacyPolicy(privacyPolicy: $privacyPolicy)
        }
      `,

      variables: {
        privacyPolicy
      }
    })

    if (
      response.data.updateCaptainPrivacyPolicy &&
      response.data.updateCaptainPrivacyPolicy.status
    ) {
      return response.data.updateCaptainPrivacyPolicy.result
    } else {
      console.error(response.data.updateCaptainPrivacyPolicy.error)
      throw response.data.updateCaptainPrivacyPolicy.error
    }
  }

  static async findClientPrivacyPolicy() {
    const response = await graphqlClient.query({
      query: gql`
        query CLIENT_PRIVACY_POLICY_FIND {
          findClientPrivacyPolicy {
            id
            value {
              en
              ar
            }
          }
        }
      `
    })

    return response.data.findClientPrivacyPolicy
  }

  static async findChefPrivacyPolicy() {
    const response = await graphqlClient.query({
      query: gql`
        query CHEF_PRIVACY_POLICY_FIND {
          findChefPrivacyPolicy {
            id
            value {
              en
              ar
            }
          }
        }
      `
    })

    return response.data.findChefPrivacyPolicy
  }

  static async findCaptainPrivacyPolicy() {
    const response = await graphqlClient.query({
      query: gql`
        query CAPTAIN_PRIVACY_POLICY_FIND {
          findCaptainPrivacyPolicy {
            id
            value {
              en
              ar
            }
          }
        }
      `
    })

    return response.data.findCaptainPrivacyPolicy
  }
}
