/* eslint-disable */
import ListStore from '@/store/transaction/transaction-list-store';

export default {
  namespaced: true,

  modules: {
    list: ListStore
  },
};
