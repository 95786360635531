/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlSettings)

export class ReplyService {
//#region [ Mutation APIs ]
  /**
   * Create new reply
   * - createSavedReply(savedReply: SavedReplyInput!): JSON!
   * @param {Object} data 
   * @param {String} data.title
   * @param {String} data.message
   * @returns {Promise<JSON>}
   */
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE_REPLY($savedReply: SavedReplyInput!) {
          createSavedReply(savedReply: $savedReply)
        }
      `,

      variables: {
        savedReply: data
      }
    })

    // return response.data.addReply
    if (response.data.createSavedReply && response.data.createSavedReply.status) {
      return response.data.createSavedReply.result
    } else {
      console.error(response.data.createSavedReply.error);
      throw response.data.createSavedReply.error
    }
  }

  /**
   * Update reply
   * - editSavedReply(savedReplyId: String!, savedReplyUpdate: SavedReplyUpdate!): JSON!
   * @param {String} id 
   * @param {Object} data 
   * @param {String} data.title
   * @param {String} data.message
   * @returns {Promise<JSON>}
   */
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REPLY_UPDATE($savedReplyId: String!, $savedReplyUpdate: SavedReplyUpdate!) {
          editSavedReply(savedReplyId: $savedReplyId, savedReplyUpdate: $savedReplyUpdate)
        }
      `,

      variables: {
        savedReplyId: id,
        savedReplyUpdate: data
      }
    })

    // return response.data.editSavedReply
    if (response.data.editSavedReply && response.data.editSavedReply.status) {
      return response.data.editSavedReply.result
    } else {
      console.error(response.data.editSavedReply.error);
      throw response.data.editSavedReply.error
    }
  }  

  /**
   * Delete reply by ID
   * - removeSavedReply(savedReplyId: String!): JSON!
   * @param {String} id 
   * @returns {Promise<JSON>}
   */
  static async remove(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REPLY_DESTROY($savedReplyId: String!) {
          removeSavedReply(savedReplyId: $savedReplyId)
        }
      `,

      variables: {
        savedReplyId: id
      }
    })

    // return response.data.removeSavedReply
    if (response.data.removeSavedReply && response.data.removeSavedReply.status) {
      return response.data.removeSavedReply.result
    } else {
      console.error(response.data.removeSavedReply.error);
      throw response.data.removeSavedReply.error
    }
  }
//#endregion

//#region [ Query APIs ]
  /**
   * List all replies
   * - listSavedReplies: [SavedReply]!
   * @returns {Promise<SavedReply[]>}
   */
  static async list() {
    const response = await graphqlClient.query({
      query: gql`
        query REPLY_LIST {
          listSavedReplies {
            id
            title
            message
          }
        }
      `
    })

    return response.data.listSavedReplies
  }
//#endregion

}
