/* eslint-disable */
import { ReplyService } from '@/store/reply/reply-service';
import Errors from '@/shared/error/errors';

export default {
  namespaced: true,
  state: {
    rows: [],
    count: 0,
    loading: false,
  },
  getters: {
    loading: (state) => state.loading,
    rows: (state) => state.rows || [],
    count: (state) => state.count,
    hasRows: (_, getters) => getters.count > 0,
  },
  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
    },
    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    }
  },
  actions: {
    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },
    async doFetch({ commit }) {
      try {
        commit('FETCH_STARTED')
        const response = await ReplyService.list()
        commit('FETCH_SUCCESS', {
          rows: response,
          count: response.length,
        })
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
  },
};
