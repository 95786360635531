import { getDatabase, onValue, ref } from 'firebase/database'

const state = {
  loading: false,
  chefsRequestsBadge: 0,
  captainsRequestsBadge: 0,
  inProgressOrders: 0
}

const getters = {
  loading: state => !!state.loading,
  chefsRequestsBadge: state => state.chefsRequestsBadge,
  captainsRequestsBadge: state => state.captainsRequestsBadge,
  inProgressOrders: state => state.inProgressOrders
}

const mutations = {
  LISTENER_STARTED(state) {
    state.loading = true
  },
  LISTENER_SUCCESS(state) {
    state.loading = false
  },
  CHEF_REQUESTS_LISTENER_SUCCESS(state, payload) {
    state.chefsRequestsBadge = payload
    state.loading = false
  },
  CAPTAIN_REQUESTS_LISTENER_SUCCESS(state, payload) {
    state.captainsRequestsBadge = payload
    state.loading = false
  },
  INPROGRESS_ORDERS_LISTENER_SUCCESS(state, payload) {
    state.inProgressOrders = payload
    state.loading = false
  }
}

const actions = {
  async doRunListenerOnNewChefRequests({ commit }) {
    try {
      commit('LISTENER_STARTED')
      const db = getDatabase()
      const statsRef = ref(db, 'badges/chefRequests')
      onValue(statsRef, snapshot => {
        const data = snapshot.val()
        const value = data && data.value ? data.value : 0
        commit('CHEF_REQUESTS_LISTENER_SUCCESS', value)
      })
    } catch (error) {
      console.error('ChefsBadge error =', error)
      commit('CHEF_REQUESTS_LISTENER_SUCCESS', 0)
    }
  },
  async doRunListenerOnNewCaptainRequests({ commit }) {
    try {
      commit('LISTENER_STARTED')
      const db = getDatabase()
      const statsRef = ref(db, 'badges/captainRequests')
      onValue(statsRef, snapshot => {
        const data = snapshot.val()
        const value = data && data.value ? data.value : 0
        commit('CAPTAIN_REQUESTS_LISTENER_SUCCESS', value)
      })
    } catch (error) {
      console.error('CaptainsBadge error =', error)
      commit('CAPTAIN_REQUESTS_LISTENER_SUCCESS', 0)
    }
  },
  async doRunListenerOnInProgressOrders({ commit }) {
    try {
      commit('LISTENER_STARTED')
      const db = getDatabase()
      const statsRef = ref(db, 'badges/inProgressOrders')
      onValue(statsRef, snapshot => {
        const data = snapshot.val()
        const value = data && data.value ? data.value : 0
        commit('INPROGRESS_ORDERS_LISTENER_SUCCESS', value)
      })
    } catch (error) {
      console.error('ChefsBadge error =', error)
      commit('INPROGRESS_ORDERS_LISTENER_SUCCESS', 0)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
