import VehicleService from '@/store/vehicle/vehicle-service'
import Errors from '@/shared/error/errors'

const state = {
  rows: [],
  loading: false,
  count: 0
}

const getters = {
  rows: state => state.rows || [],
  loading: state => state.loading,
  count: state => state.count
}

const mutations = {
  FETCH_STARTED(state) {
    state.loading = true
  },
  FETCH_SUCCESS(state, payload) {
    state.loading = false
    state.rows = payload.rows
    state.count = payload.count
  },
  FETCH_ERROR(state) {
    state.loading = false
    state.rows = []
    state.count = 0
  },
  REMOVE_STARTED(state) {
    state.loading = true
  },
  REMOVE_SUCCESS(state) {
    state.loading = false
  },
  REMOVE_ERROR(state) {
    state.loading = false
  }
}

const actions = {
  async doFetch({ commit }) {
    try {
      commit('FETCH_STARTED')
      const response = await VehicleService.list()
      commit('FETCH_SUCCESS', {
        rows: response,
        count: response.length
      })
    } catch (error) {
      Errors.handle(error)
      commit('FETCH_ERROR')
    }
  },
  async doDestroy({ commit, dispatch }, id) {
    try {
      commit('REMOVE_STARTED')
      await VehicleService.remove(id)
      commit('REMOVE_SUCCESS')
      dispatch('doFetch')
    } catch (error) {
      Errors.handle(error)
      commit('REMOVE_ERROR')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
