import { getApps } from 'firebase/app'
import { getAuth, getIdToken } from 'firebase/auth'

export class AuthToken {
  static async get() {
    const currentUser = getAuth().currentUser
    if (getApps().length && currentUser) {
      return await getIdToken(currentUser, true)
    }
    return null
  }
}
