/* eslint-disable */
import { ReviewService } from '@/store/review/review-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'

export default {
  namespaced: true,
  state: {
    loading: false
  },
  getters: {
    loading: state => !!state.loading
  },
  mutations: {
    DESTROY_STARTED(state) {
      state.loading = true
    },
    DESTROY_SUCCESS(state) {
      state.loading = false
    },
    DESTROY_ERROR(state) {
      state.loading = false
    }
  },
  actions: {
    async doDestroy({ commit, dispatch }, { chefId, id }) {
      try {
        commit('DESTROY_STARTED')
        await ReviewService.removeReview(chefId, id)
        commit('DESTROY_SUCCESS')
        Message.success(i18n('review.destroy.success'))
        dispatch(`review/list/doFetch`, { chefId }, { root: true })
      } catch (error) {
        Errors.handle(error)
        commit('DESTROY_ERROR')
      }
    }
  }
}
