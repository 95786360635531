/* eslint-disable */
import { i18n } from '@/locales/i18n'
// import Roles from '@/security/roles';

import IdField from '@/shared/fields/id-field'
import StringField from '@/shared/fields/string-field'
import StringArrayField from '@/shared/fields/string-array-field'
import BooleanField from '@/shared/fields/boolean-field'
import DateTimeField from '@/shared/fields/date-time-field'
import EnumeratorField from '@/shared/fields/enumerator-field'
import IntegerField from '@/shared/fields/integer-field'
import { GenericModel } from '@/shared/model/generic-model'

function label(name) {
  return i18n(`iam.fields.${name}`)
}

const fields = {
  id: new IdField('id', label('id')),
  authenticationUid: new StringField(
    'authenticationUid',
    label('authenticationUid')
  ),
  userName: new StringField('userName', label('userName')),
  email: new StringField('email', label('email'), {
    required: true,
    max: 255
  }),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {
    matches: /^[0-9]/,
    max: 24
  }),
  status: new EnumeratorField('status', label('status'), [
    {
      id: 'enabled',
      label: i18n('iam.enabled')
    },
    {
      id: 'disabled',
      label: i18n('iam.disabled')
    },
    {
      id: 'removed',
      label: i18n('iam.removed')
    }
  ]),
  role: new EnumeratorField('role', label('role'), [
    {
      id: 'user',
      label: i18n('iam.roles.user')
    },
    {
      id: 'admin',
      label: i18n('iam.roles.admin')
    }
  ]),
  lang: new StringField('lang', label('lang')),
  avatar: new StringField('avatar', label('avatar')),
  pagesAccess: new StringArrayField('pagesAccess', label('pagesAccess')),
  deviceTokens: new StringArrayField('deviceTokens', label('deviceTokens')),
  notification: new BooleanField('notification', label('notification')),
  listedProductsCount: new IntegerField(
    'listedProductsCount',
    label('listedProductsCount')
  ),
  password: new StringField('password', label('password'), { required: true }),
  rememberMe: new BooleanField('rememberMe', label('rememberMe')),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy'))
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
}

export class UserModel extends GenericModel {
  static get fields() {
    return fields
  }
}
