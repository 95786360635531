/* eslint-disable */
import {
  collection,
  doc,
  query,
  where,
  orderBy,
  onSnapshot,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import FirebaseRepository from '@/shared/firebase/firebase-repository'

const state = {
  complaints: [],
  messages: [],
  showChatBox: false,
  isTyping: false,
  typingList: [],
  ClientComplaintsCount: 0,
  ChefComplaintsCount: 0,
  CaptainComplaintsCount: 0,
  complaintCollection: null,
  user: null,
  chatOpened: false,
  activeTab: 'pending'
}

const getters = {
  complaints: state => state.complaints || [],
  messages: state => state.messages || [],
  showChatBox: state => state.showChatBox,
  isTyping: state => state.isTyping,
  typingList: state => state.typingList || [],
  ClientComplaintsCount: state => state.ClientComplaintsCount || 0,
  ChefComplaintsCount: state => state.ChefComplaintsCount || 0,
  CaptainComplaintsCount: state => state.CaptainComplaintsCount || 0,
  complaintCollection: state => state.complaintCollection,
  user: state => state.user,
  activeTab: state => state.activeTab,
  chatOpened: state => state.chatOpened
}

const mutations = {
  SET_NUMBER_OF_CLIENT_COMPLAINTS(state, payload) {
    state.ClientComplaintsCount = payload
  },
  SET_NUMBER_OF_CHEF_COMPLAINTS(state, payload) {
    state.ChefComplaintsCount = payload
  },
  SET_NUMBER_OF_CAPTAIN_COMPLAINTS(state, payload) {
    state.CaptainComplaintsCount = payload
  },
  SET_MESSAGE_CREATOR(state, { messageId, creator }) {
    state.messages = state.messages.map(message =>
      message.id === messageId ? { ...message, creator } : message
    )
  },
  SET_COMPLAINT_CREATOR(state, { complaintId, creator }) {
    state.complaints = state.complaints.map(complaint =>
      complaint.id === complaintId ? { ...complaint, creator } : complaint
    )
  },
  APPEND_TO_MESSAGES(state, payload) {
    state.messages.push(payload)
  },
  APPEND_TO_COMPLAINTS(state, payload) {
    state.complaints.push(payload)
  },
  RESET_MESSAGES(state) {
    state.messages = []
    state.showChatBox = false
    state.isTyping = false
  },
  RESET_COMPLAINTS(state, status) {
    state.complaints = []
    state.showChatBox = false
    state.isTyping = false
    state.activeTab = status
  },
  FETCH_COMPLAINTS_SUCCESS(state, payload) {
    state.complaints = payload
  },
  FETCH_MESSAGES_SUCCESS(state, payload) {
    state.messages = payload
    state.showChatBox = true
  },
  UPDATE_TYPING_STATUS(state, payload) {
    state.isTyping = payload
  },
  UPDATE_TYPING_LIST(state, payload) {
    state.typingList = payload
  },
  UPDATE_CHAT_OPEN(state, payload) {
    state.chatOpened = payload
  },
  SET_COMPLAINT_COLLECTION(state, collection) {
    state.complaintCollection = collection
    state.user = collection.split('Complaints')[0]
  }
}

const actions = {
  resetComplaints({ commit }) {
    commit('RESET_COMPLAINTS')
  },
  resetMessages({ commit }) {
    commit('RESET_MESSAGES')
  },
  async doSetComplaintCollection({ commit, dispatch }, collection) {
    commit('SET_COMPLAINT_COLLECTION', collection)
    await dispatch('fetchComplaints')
  },
  doRunListenerOnNewComplaints({ commit }) {
    try {
      const DB = getFirestore()
      const clientQuery = query(
        collection(DB, 'clientsComplaints'),
        where('status', '==', 'pending')
      )
      const chefQuery = query(
        collection(DB, 'chefsComplaints'),
        where('status', '==', 'pending')
      )
      const captainQuery = query(
        collection(DB, 'captainsComplaints'),
        where('status', '==', 'pending')
      )
      onSnapshot(clientQuery, querySnapshot => {
        commit('SET_NUMBER_OF_CLIENT_COMPLAINTS', querySnapshot.size)
      })
      onSnapshot(chefQuery, querySnapshot => {
        commit('SET_NUMBER_OF_CHEF_COMPLAINTS', querySnapshot.size)
      })
      onSnapshot(captainQuery, querySnapshot => {
        commit('SET_NUMBER_OF_CAPTAIN_COMPLAINTS', querySnapshot.size)
      })
    } catch (error) {
      throw error
    }
  },
  doListenOnTyping({ commit, getters }, complaintId) {
    const DB = getFirestore()
    onSnapshot(doc(DB, getters.complaintCollection, complaintId), doc => {
      const complaint = doc.data()
      commit('UPDATE_TYPING_STATUS', !!complaint.userTyping)
    })
  },
  doListenOnOpenedChat({ commit, getters }, complaintId) {
    const DB = getFirestore()
    // unsubscribe()
    const unsubscribe = onSnapshot(doc(DB, getters.complaintCollection, complaintId), doc => {
      const complaint = doc.data()
      commit('UPDATE_CHAT_OPEN', !!complaint.isUserChatOpen)
    })
  },
  async fetchComplaints({ commit, dispatch, getters }, status = 'pending') {
    try {
      commit('RESET_COMPLAINTS', status)
      const DB = getFirestore()
      const QUERY = query(
        collection(DB, getters.complaintCollection),
        where('status', '==', status),
        orderBy('createdAt', 'desc')
      )
      onSnapshot(QUERY, async querySnapshot => {
        const complaints = FirebaseRepository.mapCollection(querySnapshot)
        commit('FETCH_COMPLAINTS_SUCCESS', complaints)
        commit(
          'UPDATE_TYPING_LIST',
          complaints
            .filter(complaint => complaint.userTyping)
            .map(complaint => complaint.id)
        )
        await dispatch('fetchComplaintSender')
      })
    } catch (error) {
      throw error
    }
  },
  async fetchMessages({ commit, dispatch, getters, rootGetters }, complaintId) {
    try {
      commit('RESET_MESSAGES')
      const currentUser = rootGetters[`auth/currentUser`] || { id: null }
      const complaintCollection = getters.complaintCollection
      const DB = getFirestore()
      const QUERY = query(
        collection(DB, `${complaintCollection}/${complaintId}/messages`),
        orderBy('sentAt', 'asc')
      )
      onSnapshot(QUERY, querySnapshot => {
        const messages = FirebaseRepository.mapCollection(querySnapshot)
        commit('FETCH_MESSAGES_SUCCESS', messages)
        dispatch('doListenOnTyping', complaintId)
        const messagesIds = messages
          .filter(
            message => message.senderId != currentUser.id && !message.read
          )
          .map(message => message.id)
        dispatch('doSetIsReading', { complaintId, messagesIds })
      })
    } catch (error) {
      throw error
    }
  },
  async fetchComplaintSender({ getters, commit }) {
    for (const complaint of getters.complaints) {
      // getters.complaints.forEach(async complaint => {
      if (!complaint.creator) {
        const userType = getters.user
        const creator = await FirebaseRepository.findDocument(
          userType,
          complaint.createdBy
        )
        complaint.creator = creator || { id: complaint.createdBy }
        commit('SET_COMPLAINT_CREATOR', {
          complaintId: complaint.id,
          creator: creator
        })
      }
    }
  },
  async fetchMessageSender({ getters, commit }) {
    for (const message of getters.messages) {
      // getters.messages.forEach(async message => {
      if (!message.creator) {
        const creator = await FirebaseRepository.findDocument(
          getters.user,
          message.senderId
        )
        // message.creator = creator || { id: message.senderId }
        commit('SET_MESSAGE_CREATOR', {
          messageId: message.id,
          creator: creator
        })
      }
    }
  },

  async doSendMessage({ rootGetters, dispatch, getters }, payload) {
    const id = doc(collection(getFirestore(), 'ids')).id
    const currentUser = rootGetters[`auth/currentUser`] || { id: null }
    const message = {
      id: id,
      message: payload.message || null,
      attachment: payload.attachment || null,
      type: payload.type || null,
      read: false,
      senderId: currentUser.id,
      sentAt: serverTimestamp()
    }
    const DB = getFirestore()
    await setDoc(
      doc(
        DB,
        `${getters.complaintCollection}/${payload.complaintId}/messages/${id}`
      ),
      message
    )
    await updateDoc(
      doc(DB, `${getters.complaintCollection}/${payload.complaintId}`),
      {
        status: 'open'
      }
    )
    !getters.chatOpened &&
      (await dispatch(
        `notification/form/doSendToUser`,
        {
          notification: payload.notification,
          userId: payload.userId,
          userType: getters.user.slice(0, -1),
          silent: true
        },
        { root: true }
      ))
  },
  async closeComplaint({ commit, getters }, complaint) {
    const DB = getFirestore()
    await updateDoc(doc(DB, `${getters.complaintCollection}/${complaint.id}`), {
      status: 'closed'
    })
    commit('RESET_MESSAGES')
  },
  doSetIsReading({ getters }, { complaintId, messagesIds }) {
    try {
      const DB = getFirestore()
      messagesIds.forEach(messageId => {
        updateDoc(
          doc(
            DB,
            `${getters.complaintCollection}/${complaintId}/messages/${messageId}`
          ),
          {
            read: true
          }
        )
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
