/* eslint-disable */
import { MealService } from '@/store/meal/meal-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'
import { FileUploader } from '@/shared/uploader/file-uploader'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },

    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    ADD_STARTED(state) {
      state.saveLoading = true
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false
    },

    ADD_ERROR(state) {
      state.saveLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit }, { chefId, mealId }) {
      try {
        commit('FIND_STARTED')
        const record = await MealService.findMeal(chefId, mealId)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/chefs')
      }
    },

    async doUpdate({ commit }, { chefId, mealId, ...mealUpdate }) {
      try {
        commit('UPDATE_STARTED')
        await MealService.editMeal(chefId, mealId, mealUpdate)
        commit('UPDATE_SUCCESS')

        Message.success(i18n('iam.chefDetails.meals.update.success'))
        router.back()
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }
  }
}
