/* eslint-disable */
import { i18n } from '@/locales/i18n'
import vueI18n from '@/vueI18n'
import Toastify from 'toastify-js'

export default class Message {
  static success(payload) {
    const language = vueI18n.global.locale
    const element = cash('#success-notify-content')
      .clone()
      .removeClass('hidden')[0]
    element.innerHTML = element.innerHTML.replace('--text--', payload)

    Toastify({
      node: element,
      text: payload,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: language == 'ar' ? 'left' : 'right',
      stopOnFocus: true,
      onClick: () => {}
    }).showToast()
  }

  static error(payload) {
    let message = payload

    if (!message) {
      message = i18n('errors.defaultErrorMessage')
    }

    const language = vueI18n.global.locale
    const element = cash('#error-notify-content')
      .clone()
      .removeClass('hidden')[0]
    element.innerHTML = element.innerHTML.replace('--text--', payload)

    Toastify({
      node: element,
      text: payload,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: language == 'ar' ? 'left' : 'right',
      stopOnFocus: true,
      onClick: () => {}
    }).showToast()
  }

  static warning(payload) {
    let message = payload

    if (!message) {
      message = i18n('errors.defaultErrorMessage')
    }
    Notify.create({
      message: payload,
      type: 'warning',
      color: 'white',
      textColor: 'warning',
      timeout: 3000,
      position: 'center',
      multiLine: true,
      actions: [{ label: 'Dismiss', color: 'cyan', handler: () => {} }]
    })
  }
}
