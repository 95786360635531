/* eslint-disable */
import { MealService } from '@/store/meal/meal-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import { state, getters, mutations, actions } from '@/store/list-utils'

export default {
  namespaced: true,
  state: {
    ...state,
    chefId: null
  },
  getters: {
    ...getters,
    chefId: state => state.chefId
  },
  mutations: {
    ...mutations,
    UPDATE_CHEF_ID(state, chefId) {
      state.chefId = chefId || state.chefId
    },
    REMOVE_STARTED(state) {
      state.loading = true
    },
    REMOVE_SUCCESS(state) {
      state.loading = false
    },
    REMOVE_ERROR(state) {
      state.loading = false
    }
  },
  actions: {
    async doChangePaginationPageSize({ commit, getters }, pageSize) {
      await actions.changePageSize({ commit, getters, pageSize })
    },
    doChangePaginationCurrentPage({ commit }, currentPage) {
      actions.changeCurrentPage({ commit, page: currentPage })
    },
    async doFetchNextPage({ commit, getters }) {
      await actions.fetchNext({ commit, getters })
    },
    async doFetchPreviousPage({ commit, getters }) {
      await actions.fetchPrevious({ commit, getters })
    },
    async doFetch(
      { commit, getters },
      { chefId, pagination, keepPagination = true, filter = [] } = {}
    ) {
      commit('UPDATE_CHEF_ID', chefId)
      await actions.fetch({
        commit,
        getters,
        list: MealService.listMeals.bind(null, getters.chefId),
        filter,
        pagination,
        keepPagination
      })
    },
    async doDestroy({ commit, dispatch, getters }, { chefId, mealId }) {
      try {
        commit('REMOVE_STARTED')
        await MealService.removeMeal(chefId, mealId)
        commit('REMOVE_SUCCESS')
        Message.success(i18n('iam.chefDetails.meals.destroy.success'))
        await dispatch('doFetch', { pagination: getters.pagination })
      } catch (error) {
        Errors.handle(error)
        commit('REMOVE_ERROR')
      }
    }
  }
}
