import ReviewListStore from '@/store/review/review-list-store'
import reviewDestroyStore from './review-destroy-store'

export default {
  namespaced: true,
  modules: {
    list: ReviewListStore,
    destroy: reviewDestroyStore
  }
}
