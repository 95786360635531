/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
import { CaptainWallet, ChefWallet, ClientWallet } from '../user/user-schema'
const graphqlChef = GraphqlClient.initApolloClient(config.backendUrlChef)
const graphqlCaptain = GraphqlClient.initApolloClient(config.backendUrlCaptain)
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlClient)

export class WalletService {
  static async listClientsWallet(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query WALLET_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listClientsWallets(filter: $filter, pagination: $pagination) ${ClientWallet}
        }
      `,
      variables: {
        filter,
        pagination
      }
    })

    return response.data.listClientsWallets
  }
  static async listChefsWallet(filter, pagination) {
    const response = await graphqlChef.query({
      query: gql`
        query WALLET_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listChefsWallets(filter: $filter, pagination: $pagination) ${ChefWallet}
        }
      `,
      variables: {
        filter,
        pagination
      }
    })

    return response.data.listChefsWallets
  }
  static async listCaptainsWallet(filter, pagination) {
    const response = await graphqlCaptain.query({
      query: gql`
        query WALLET_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listCaptainsWallets(filter: $filter, pagination: $pagination) ${CaptainWallet}
        }
      `,
      variables: {
        filter,
        pagination
      }
    })

    return response.data.listCaptainsWallets
  }
  //#endregion
}
